import estiImg from "../assets/undraw_mobile_app_re_catg.svg";
import sellDraw from "../assets/undraw_buy_house_re_8xq7.svg";
import buyDraw from "../assets/undraw_destination_re_sr74.svg";
import { SAlogo } from "../utils/utils.components";

/**
 * @name headerCardList
 * @description List des faq
 */
export const headerCardList = [
  {
    title: "Concernant l'estimation",
    image: estiImg,
    content: [
      {
        question:
          "Suis-je obligé de renseigner mes coordonnées pour estimer des biens ?",
        response: <p>Non, l’estimation est libre d’accès.</p>,
      },
      {
        question:
          "L’adresse estimée est une maison - ou un immeuble d’habitation - cependant, le résultat obtenu est une estimation des deux, maison et appartement",
        response: (
          <p>
            À certaines adresses nous n’avons pas constaté de vente au cours des
            dernières années et par conséquent nous ne pouvons définir le type
            d’habitation.
          </p>
        ),
      },
      {
        question:
          "Pourquoi certaines ventes sont-elles signalées par un triangle rouge ?",
        response: (
          <p>
            Il s’agit de ventes considérées comme exceptionnelles par leurs prix
            du m², anormalement élevés ou anormalement bas par rapport aux prix
            du m² à proximité.
          </p>
        ),
      },
      {
        question:
          "J’ai effectué une estimation avancée, en bas du résultat une signalisation me propose de contacter un professionnel local",
        response: (
          <p>
            Si le prix du bien estimé est supérieur au prix le plus haut ou
            inférieur au prix le plus bas à l’adresse, Flatway vous conseille de
            contacter un professionnel local pour une autre estimation.
          </p>
        ),
      },
      {
        question: "Mon bien vendu n’est pas dans la liste des ventes affichées",
        response: (
          <>
            <label>Nous n’affichons pas :</label>
            <ul className={"list-decimal ml-5 list-inside"}>
              <li>
                certaines ventes dont l’écart de prix est très important par
                rapport au prix du m² à l’adresse
              </li>
              <li>les biens vendus il y a plus de 5 ans</li>
            </ul>
          </>
        ),
      },
      {
        question:
          "Y a-t-il un lien entre le prix de vente d’un bien consulté et l’estimation donnée de ce bien ?",
        response: (
          <p>
            Aucun, le vendeur a défini librement son prix net vendeur et Flatway
            vous fournit une estimation indépendante du prix de vente.
          </p>
        ),
      },
      {
        question:
          "Les numéros de voies ne sont pas affichés dans la liste des biens vendus",
        response: (
          <p>
            Pour préserver l’anonymat des biens en vente nous n’affichons pas le
            N° de la voie des biens vendus.
          </p>
        ),
      },
    ],
  },
  {
    title: "Concernant le vendeur",
    image: sellDraw,
    content: [
      {
        question:
          "Mon bien est situé dans la région parisienne et je souhaite confier la vente à un professionnel qui se trouve dans autre région",
        response: (
          <p>
            Non, vous ne pouvez pas confier la vente de votre bien à un
            professionnel qui se trouve dans une autre région. Les acheteurs
            potentiels doivent pouvoir consulter les documents du bien dans les
            locaux du professionnel afin d’éviter les envois des documents par
            mails et de rompre la chaîne de sécurité.
          </p>
        ),
      },
      {
        question:
          "Certains professionnels n’ont renseigné qu’un seul type d’honoraires",
        response: (
          <p>
            Les professionnels sont libres de choisir un ou deux types
            d’honoraires. La majorité d’entre eux donnent le choix aux vendeurs
            et renseignent les deux types, à taux réduits et à taux standards.
            Néanmoins, certains professionnels préfèrent gérer eux-mêmes les
            visites et d’autres privilégient les ventes sans accompagnement.
          </p>
        ),
      },
      {
        question:
          "Je souhaite confier la vente à un professionnel qui ne figure pas dans la liste",
        response: (
          <p>
            Si le professionnel de votre choix n’est pas dans la liste, il
            suffit de lui demander de télécharger l’application FlatwayPro.
          </p>
        ),
      },
      {
        question: "Aucun service dans ma ville",
        response: (
          <p>
            Pour le moment, nous n’avons pas une couverture totale du
            territoire, nous avançons ville par ville et région par région.
          </p>
        ),
      },
      {
        question: "Aucun professionnel dans ma ville",
        response: (
          <p>
            Si le service existe dans votre région mais que vous ne trouvez pas
            de professionnels dans votre ville, demander, avant de mettre votre
            bien en vente, au professionnel de votre choix de télécharger
            FlatwayPro.
          </p>
        ),
      },
      {
        question:
          "Si le prix d’achat négocié est inférieur au prix annoncé, est-ce que les honoraires diminuent aussi ?",
        response: (
          <p>
            Oui, Les honoraires du professionnel seront calculés au prorata du
            prix négocié ; si le prix baisse (augmente) les honoraires baissent
            (augmentent).
          </p>
        ),
      },
      {
        question:
          "Dans quel ordre sont classés les professionnels dans la liste de recherche ?",
        response: (
          <>
            <label>
              La liste des professionnels actifs dans le secteur est affichée en
              deux catégories distinctes :
            </label>
            <ul className={"list-disc ml-5 list-inside"}>
              <li>
                Les professionnels ayant au moins un bien en vente sur Flatway
              </li>
              <li>
                Les professionnels n’ayant aucune annonce de bien en vente sur
                Flatway
              </li>
            </ul>
            <p>
              La première catégorie est affichée en premier et l’ensemble des
              résultats de recherche de chaque catégorie est classé
              aléatoirement.
            </p>
          </>
        ),
      },
      {
        question: "Comment définir mon prix net vendeur ?",
        response: (
          <p>
            Utiliser notre estimation, demander à un professionnel de votre
            choix une autre estimation et décider de votre prix net vendeur.
          </p>
        ),
      },
      {
        question: "A quel moment dois-je faire les diagnostics obligatoires ?",
        response: (
          <p>
            Avant d’enregistrer votre bien à la vente. La surface exacte et le
            diagnostic de performance énergétique (DPE) sont essentiels pour
            enregistrer la vente.
          </p>
        ),
      },
      {
        question:
          "Est-ce que je peux enregistrer la vente de mon bien sur le site web www.flatway.fr ?",
        response: (
          <p>
            Non, l’enregistrement se fait uniquement depuis l’Application
            Flatway.
          </p>
        ),
      },
      {
        question:
          "Quel type de mande de vente, pour quelle durée et comment résilier ?",
        response: (
          <ul className={"list-decimal ml-5 list-inside"}>
            <li>
              le mandat proposé est sans exclusivité, vous ne pouvez pas signer
              un mandat exclusif
            </li>
            <li>
              le mandat de vente est consenti pour une durée initiale
              irrévocable de trois mois. Si le mandat n’a pas été dénoncé dans
              un délai de 14 jours après la signature ou à l’expiration de cette
              période initiale, il sera prorogé pour une durée maximale de 12
              mois supplémentaire au terme de laquelle il prendra
              automatiquement fin
            </li>
            <li>
              le vendeur quel que soit le motif, peut résilier par lettre
              recommandée, à tout moment, le mandat de vente moyennant un
              préavis de 15 jours
            </li>
          </ul>
        ),
      },
      {
        question:
          "Comment procède le professionnel choisi pour vendre mon bien ?",
        response: (
          <p>
            Le professionnel utilise la plateforme Flatway et les supports de
            publicité habituels pour commercialiser le bien.
          </p>
        ),
      },
      {
        question: "Comment modifier mon prix de vente ?",
        response: (
          <>
            <label>
              A partir de votre espace, cliquez sur modifier mon annonce et
              modifiez votre prix de vente net vendeur. Aucune modification
              n’est nécessaire au mandat de vente
            </label>
            <ul className={"list-disc ml-5 list-inside"}>
              <li>
                si votre bien est en vente chez plusieurs professionnels sur
                l’application Flatway, le changement de prix net vendeur chez un
                professionnel, entraîne le changement de prix net vendeur pour
                les autres également. Le prix de vente, honoraires inclus, est
                automatiquement recalculé, au prorata du nouveau prix net
                vendeur
              </li>
              <li>
                la modification de prix net vendeur ne peut se faire qu’après au
                moins 3 semaines de publication et maximum 5 changements sont
                autorisés
              </li>
            </ul>
          </>
        ),
      },
      {
        question:
          "Est-ce que je peux confier la vente de mon bien à plusieurs professionnels ?",
        response: (
          <p>
            Oui, jusqu’à 3 professionnels différents, à condition de conserver
            le type d’honoraires choisi pour l’annonce initiale.
          </p>
        ),
      },
      {
        question:
          "Comment choisir un nouveau professionnel pour vendre mon bien ?",
        response: (
          <ul className={"list-decimal ml-5 list-inside"}>
            <li>
              choisir dans la liste un nouveau professionnel. Le prix net
              vendeur et les photos restent inchangés - le prix de vente net
              vendeur doit être identique pour tous les professionnels ;
              l’annonce apparaît identique à la première, exception faite du
              prix de vente final qui varie donc selon le taux d’honoraires de
              chaque professionnel
            </li>
            <li>
              signer le mandat de vente sans exclusivité avec le nouveau
              professionnel
            </li>
          </ul>
        ),
      },
      {
        question: "Est-ce que je peux supprimer mon annonce ?",
        response: (
          <p>
            À tout moment et en respectant les délais, légaux vous pouvez mettre
            fin à la vente et supprimer votre annonce. Si le bien est en vente
            chez plusieurs professionnels, le vendeur, en respectant les délais
            légaux, peut supprimer les annonces chez les professionnels auprès
            desquels il a résilié le mandat de vente.
          </p>
        ),
      },
    ],
  },
  {
    title: "Concernant l’acheteur",
    image: buyDraw,
    content: [
      {
        question: "Quelle est l’origine des annonces sur Flatway ?",
        response: (
          <p>
            Les annonces sont issues du choix des vendeurs qui, en fonction des
            honoraires choisissent un ou plusieurs professionnels pour vendre
            leurs biens; certains vendeurs ont choisi d’assurer eux-mêmes les
            visites et de payer moins d’honoraires, d’autres ont fait le choix
            de confier les visites au professionnel en charge de la vente.
          </p>
        ),
      },
      {
        question: "Quel est l’ordre d’affichage des annonces sur Flatway ?",
        response: (
          <p>
            Les annonces sont classées par date de publication, les annonces les
            plus récentes étant affichées en premier. Afin de ne pas favoriser
            une annonce plus qu’une autre, les annonces publiées à la même date
            sont aléatoirement affichées.
          </p>
        ),
      },
      {
        question:
          "Comment savoir qui, du vendeur ou du professionnel, doit assurer les visites ?",
        response: (
          <p>
            Les biens en vente avec des honoraires à taux réduits, visites
            assurées par les vendeurs, sont signalés par le symbole <SAlogo />.
            Le professionnel est informé en temps réel des étapes des visites,
            il peut contacter à tout moment le vendeur et l’acheteur.
          </p>
        ),
      },
      {
        question:
          "Quelle est la différence entre visite assurée par le vendeur et visite assurée par le professionnel ?",
        response: (
          <p>
            <span className={"flex"}>
              Les visites des biens vendus avec des honoraires à taux réduits
              sont assurées par les vendeurs ; ventes signalées par le symbole{" "}
              <SAlogo />.
            </span>{" "}
            Le professionnel assure les visites des biens vendus avec des
            d’honoraires à taux standards ; dans les deux cas, le professionnel
            est informé en temps réel des étapes des visites, il peut contacter
            à tout moment le vendeur et l’acheteur.
          </p>
        ),
      },
      {
        question:
          "Pourquoi Flatway vérifie l’identité de l’acheteur lorsque les visites sont organisées par les vendeurs ?",
        response: (
          <>
            <label>
              Vérifier l'identité des utilisateurs acheteurs nous permet :
            </label>
            <ul className={"list-decimal ml-4"}>
              <li>
                Rassurer le vendeur, qui assure seul la visite, et instaurer un
                climat de confiance entre le vendeur et l’acheteur
              </li>
              <li>
                Éviter les problèmes de sécurité tels que les agressions ou les
                vols
              </li>
              <li>Repérer les comportements trompeurs ou malveillants</li>
            </ul>
          </>
        ),
      },
      {
        question:
          "La pièce d’identité est-elle obligatoire pour visiter un bien ?",
        response: (
          <p>
            Uniquement pour les biens dont la visite est assurée par les
            vendeurs.
          </p>
        ),
      },
      {
        question:
          "Le vendeur peut-il exiger une attestation de capacité d’emprunt ?",
        response: (
          <p>
            Oui, le vendeur peut demander à l’acheteur, via le professionnel en
            charge de la vente, une attestation de capacité d&#39;emprunt.
          </p>
        ),
      },
      {
        question: "Combien de fois puis-je visiter le même bien ?",
        response: (
          <p>
            2 fois, néanmoins l’utilisateur peut toujours contacter le
            professionnel pour une autre visite.
          </p>
        ),
      },
    ],
  },
];
