import React, { useRef } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import gsap from "gsap";
import logoIMG from "../../assets/logoflatnew.png";
import { useNavigate } from "react-router-dom";

/**
 * @name Navbar
 * @description Barre de navigation (different des autres car bouton decouvrir et professionnel)
 * @param isOpen
 * @param setIsOpen
 * @constructor
 */
function Navbar({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const navigation = useNavigate();
  const navMenuRef = useRef(null);

  const toggleMenu = () => {
    if (isOpen) {
      gsap.to(navMenuRef.current, {
        x: "100%",
        duration: 0.3,
        ease: "power2.inOut",
      });
    } else {
      gsap.to(navMenuRef.current, {
        x: "0%",
        duration: 0.3,
        ease: "power2.inOut",
      });
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="hidden lg:flex w-full items-center justify-between px-[30px]">
        <button
          onClick={() => {
            window.scroll(0, 0);
            navigation("/flatway");
          }}
          className={
            "font-medium w-fit px-2 py-1 bg-dark-red rounded-lg transition-all duration-300 text-white"
          }
        >
          Découvrir
        </button>
        <button
          onClick={() => navigation("/flatwaypro")}
          className={
            "px-2 py-2 hover:bg-darkRedRgba bg-light-red rounded-lg text-sm font-medium"
          }
        >
          Vous êtes professionnel ?
        </button>
      </div>
      <div className="flex lg:hidden w-[10%]">
        <button onClick={toggleMenu}>
          <AiOutlineMenu />
        </button>
      </div>
      <div
        ref={navMenuRef}
        className={`fixed overflow-hidden top-0 right-0 h-[100vh] w-full bg-white z-50 transform translate-x-0 p-5`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="flex flex-row items-center justify-between">
          <img src={logoIMG} alt="logo" className="h-10" />
          <AiOutlineClose onClick={toggleMenu} />
        </div>
        <div className="flex flex-col items-center gap-5 justify-evenly">
          <button
            onClick={() => navigation("/flatway")}
            className={
              "font-medium w-fit px-2 py-1 bg-dark-red rounded-lg transition-all duration-300 text-white"
            }
          >
            Découvrir
          </button>
          <button
            onClick={() => navigation("/flatwaypro")}
            className={
              "px-2 py-2 w-fit bg-dark-red rounded-lg text-white font-medium"
            }
          >
            Vous êtes un professionnel ?
          </button>
        </div>
      </div>
    </>
  );
}

export default Navbar;
