import React, { useRef, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { ProForm } from "../../interfaces/FlatWayPro.interface";
import qrCode from "../../assets/qr-code.png";
import { useProForm } from "../../hooks/FlatwayPro.hooks";

/**
 * @name CustomSelect
 * @description Composant de select personnalisé avec les différents metiers de l'immo pour le formulaire de contact professionnel
 * @constructor
 */
export const CustomSelect = () => {
  const options = [
    "Agent immobilier",
    "Mandataire immobilier",
    "Notaire",
    "Syndic de copropriété",
  ];
  const SelectValue = useRef("Votre profession");
  const [isOpen, setIsOpen] = useState(false);
  const handleOptionClick = (option: string) => {
    SelectValue.current = option;
    setIsOpen(false);
  };
  const handleBlur = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={"relative flex select-none focus:outline-none"}
      contentEditable={true}
      onBlur={handleBlur}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`h-[5vh] focus:outline-none flex justify-between items-center bg-white leading-4 transition-all duration-300 w-full ease ${
          isOpen ? "border-dark-red" : ""
        } px-[5px] border-[2px] border-grey-300 rounded cursor-pointer text-sm ${
          SelectValue.current === "Votre profession" ? "text-gray-400" : ""
        }`}
      >
        {SelectValue.current}
        <IoIosArrowForward
          className={`transition-all duration-300 text-xl ${
            isOpen ? "rotate-90" : ""
          }`}
        />
      </div>
      <ul
        className={`absolute top-full text-sm z-10 bg-white w-full flex-col cursor-pointer ${
          isOpen ? "flex" : "hidden"
        }`}
      >
        {options.map((option, index) => (
          <li
            key={index}
            className={
              "text-md hover:bg-dark-red hover:text-white h-[25px] px-[8px]"
            }
            onClick={() => handleOptionClick(option)} // Mettre à jour la valeur sélectionnée et fermer la liste déroulante lorsque l'option est cliquée
          >
            <span>{option}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

/**
 * @name ProContactForm
 * @description Formulaire de contact professionnel
 * @constructor
 */
export const ProContactForm = () => {
  const { formRef, onSubmit, isLoading, error } = useProForm();

  const ProInput = ({
    placeholder,
    id,
  }: {
    placeholder: string;
    id: string;
  }) => (
    <div className="input-container">
      <input
        required
        name={id}
        placeholder={placeholder}
        className="input-field focus:outline-none text-sm focus:border-dark-red px-[5px] border-[2px] border-grey-300 rounded"
        type="text"
      />
      <label htmlFor="input-field" className="input-label">
        {placeholder}
      </label>
      <span className="input-highlight"></span>
    </div>
  );

  return (
    <form
      ref={formRef}
      onSubmit={onSubmit}
      className={
        "w-full sm:w-[500px] sm:h-[510px] h-auto bg-slate-50 rounded-lg border drop-shadow-3xl"
      }
    >
      <fieldset
        className={"px-[5vh] flex flex-col justify-evenly w-full h-[60vh]"}
      >
        <label
          className={
            "text-base sm:text-xl font-bold text-dark-red h-[15%] flex items-center"
          }
        >
          Vous souhaitez nous rejoindre ?<br />
          Un collaborateur vous recontacte
        </label>
        <span className={"flex flex-col gap-5"}>
          <span className={"grid grid-cols-2 gap-5"}>
            <ProInput id={"Firstname"} placeholder={"Nom *"} />
            <ProInput id={"Lastname"} placeholder={"Prénom *"} />
            <ProInput id={"Phone"} placeholder={"Téléphone *"} />
            <ProInput id={"Zip"} placeholder={"Code Postal *"} />
          </span>
          <ProInput id={"Email"} placeholder={"Adresse email *"} />
          <span className={"flex gap-4 flex-col"}>
            <CustomSelect />
            <ProInput id={"Entreprise"} placeholder={"Entreprise *"} />
            <ProInput id={"Sujet"} placeholder={"Sujet *"} />
          </span>
        </span>
        {error && <p className={"text-red-500"}>{error}</p>}
        <div className={"flex flex-col h-[20%] w-auto justify-between"}>
          <p className={"text-xs text-dark-red"}>* Champs obligatoires</p>
          <button
            disabled={isLoading}
            className={
              "text-white inline-block max-w-max rounded-lg text-sm w-auto font-bold px-4 py-2 bg-dark-red"
            }
          >
            {isLoading ? <span>Processing...</span> : <p>Valider ma demande</p>}
          </button>
          <a
            rel="noreferrer"
            target={"_blank"}
            href={require("../../assets/pdf/Politique de confidentialite.pdf")}
            className={"text-dark-red w-auto underline text-xs font-light pt-2 pb-4"}
          >
            Politique de confidentialités
          </a>
        </div>
      </fieldset>
    </form>
  );
};

/**
 * @name QrCodeFooter
 * @description Footer de la page FlatwayPro
 * @constructor
 */
export const QrCodeFooter = () => {
  return (
    <div className={"w-full flex flex-col justify-center items-center"}>
      <div
        className={
          "w-fit h-auto hover:text-dark-red bg-slate-50 select-none rounded-lg drop-shadow p-5 gap-3 flex items-center justify-center"
        }
      >
        <div className={"h-[100px] w-[100px] flex justify-center items-center"}>
          <img
            src={qrCode}
            alt={"qrcode"}
            className={"object-cover h-full w-full"}
          />
        </div>
        <div className={"lg:w-[500px]"}>
          <p
            className={
              "font-bold text-xs sm:text-sm transition-all duration-300"
            }
          >
              <span className={"text-dark-red"}>
                Bientot disponible
              </span>
              <br />
            Vous souhaitez nous rejoindre ?
            <br />
            <br />
            Scannez pour télécharger <span className={"mr-[2px]"}>Flatway</span>
            Pro
          </p>
        </div>
      </div>
    </div>
  );
};
