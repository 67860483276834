import { Dispatch, FC, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import ConsultationAPI from "../API/Consultation.API";
import { cityInfo, sellType } from "../interfaces/interface";
import { SelectOption } from "./Select";

/**
 * @name handleNavigate
 * @description Gère le changement de la ville
 * @param selectCity
 * @param value1
 * @param navigate
 * @param mod
 */
export const handleNavigate = (
  selectCity: cityInfo[],
  value1: SelectOption[],
  navigate: NavigateFunction,
  mod: boolean,
) => {
  let name;
  let zipcode;
  let tb;
  if (selectCity.length !== 0) {
    name = selectCity.map((a) => (a.name.includes("Paris")) ? a.name.split(" ")[0].trim() : a.name.trim()); // Enlève les espaces à la fin de chaque nom
    zipcode = selectCity.map((a) => a.zipcode);
  } else {
    name = "Paris";
    zipcode = 75000;
  }
  tb = value1.map((a) => a.value);
  console.log(name, zipcode, tb);
  if (name) {
    navigate(
      `${mod ? "" : "consultation"}?city=${name}&zipcode=${zipcode}&tb=${tb}`,
    );
    if (mod) {
      window.location.reload();
    }
  }
};

/**
 * @name handleChangeCity
 * @description Gere l'autocomplete de la ville
 * @param input
 * @param setCityList
 */
export const handleChangeCity = async (
  input: string,
  setCityList: Dispatch<SetStateAction<never[]>>,
) => {
  if (input.length > 1) {
    const tmp = input.replaceAll(" ", "-");
    const url = `https://${process.env.REACT_APP_API_URL}/api/location/autocomplete?input=${tmp}`;
    const data = await ConsultationAPI(url, false);
    console.log(data);
    setCityList(data);
  } else if (input.length === 0) {
    setCityList([]);
  }
};

/**
 * @name handleClick
 * @description Gère le click sur une ville de l'autocomplete
 * @param value
 * @param selectCity
 * @param setSelectCity
 */
export const handleClick = (
  value: cityInfo,
  selectCity: cityInfo[],
  setSelectCity: (value: cityInfo[]) => void,
) => {
  if (
    !selectCity.some((city) => city.name === value.name) &&
    selectCity.length <= 10
  ) {
    setSelectCity([...selectCity, value]);
  }
};

/**
 * @name handleBlur
 * @description Gère le blur de l'autocomplete (pas super performant)
 * @param e
 * @param setFocus
 */
export const handleBlur = (
  e: React.FocusEvent<HTMLDivElement>,
  setFocus: (arg0: boolean) => void,
) => {
  if (!e.currentTarget.contains(e.relatedTarget as Node)) {
    setFocus(false);
  }
};

interface InfoProps {
  focus: boolean;
  CityList: never[];
  selectCity: cityInfo[];
  setSelectCity: (value: cityInfo[]) => void;
  setUserInput: (value: string) => void;
}

/**
 * @name ConsultationInfo
 * @description Affichage de l'autocomplete de la ville
 * @param focus
 * @param CityList list des villes
 * @param selectCity list des villes selectionnées
 * @param setSelectCity
 * @param setUserInput
 * @constructor
 */
export const ConsultationInfo: FC<InfoProps> = ({
  focus,
  CityList,
  selectCity,
  setSelectCity,
  setUserInput,
}) => {
  return (
    <div
      className={`absolute top-full sm:top-full mt-2 right-0 overflow-hidden z-50 rounded-lg shadow bg-white border-[#BABABA] w-full max-h[140]  ${
        focus && CityList.length > 0 ? "block" : "hidden"
      }`}
    >
      {CityList.map(({ city, zipcode }, index) => {
        if (selectCity.some((a) => a.name === city)) return null;

        return (
          <button
            onFocus={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClick(
                {
                  name: city != "Paris" ? city : `${city} (${zipcode})`,
                  index: index,
                  zipcode: zipcode,
                },
                selectCity,
                setSelectCity,
              );
              setUserInput("");
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClick(
                {
                  name: city,
                  index: index,
                  zipcode: zipcode,
                },
                selectCity,
                setSelectCity,
              );
              setUserInput("");
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClick(
                {
                  name: city != "Paris" ? city : `${city} (${zipcode})`,
                  index: index,
                  zipcode: zipcode,
                },
                selectCity,
                setSelectCity,
              );
              setUserInput("");
            }}
            className="h-[30px] w-full hover:bg-light-red z-50 px-2"
            key={index}
          >
            <div className="h-full w-full flex items-center justify-between">
              <h1>{ city != "Paris" ? city : `${city} (${zipcode})`}</h1>
              <h2>{zipcode}</h2>
            </div>
          </button>
        );
      })}
    </div>
  );
};

/**
 * @name SortByPriceUp
 * @description Trie par prix croissant
 * @param sellList
 * @constructor
 */
function SortByPriceUp(sellList: sellType[]) {
  sellList.sort((a, b) => a.prixdevente - b.prixdevente);
}

/**
 * @name SortByPriceDown
 * @description Trie par prix décroissant
 * @param sellList
 * @constructor
 */
function SortByPriceDown(sellList: sellType[]) {
  sellList.sort((a, b) => b.prixdevente - a.prixdevente);
}

/**
 * @name SortBySurfaceUp
 * @description Trie par surface croissante
 * @param sellList
 * @constructor
 */
function SortBySurfaceUp(sellList: sellType[]) {
  sellList.sort((a, b) => a.surface - b.surface);
}

/**
 * @name SortBySurfaceDown
 * @description Trie par surface décroissante
 * @param sellList
 * @constructor
 */
function SortBySurfaceDown(sellList: sellType[]) {
  sellList.sort((a, b) => b.surface - a.surface);
}

/**
 * @name SortByPriceMUp
 * @description Trie par prix du m² croissant
 * @param sellList
 * @constructor
 */
function SortByPriceMUp(sellList: sellType[]) {
  sellList.sort(
    (a, b) => b.surface / b.prixdevente - a.surface / a.prixdevente,
  );
}

/**
 * @name SortByPriceMDown
 * @description Trie par prix du m² décroissant
 * @param sellList
 * @constructor
 */
function SortByPriceMDown(sellList: sellType[]) {
  sellList.sort(
    (a, b) => a.surface / a.prixdevente - b.surface / b.prixdevente,
  );
}

/**
 * @name SortData
 * @description Trie les données
 * @param option
 * @param data
 * @constructor
 */
export function SortData(option: number, data: sellType[]) {
  const functions = [
    SortByPriceUp,
    SortByPriceDown,
    SortBySurfaceUp,
    SortBySurfaceDown,
    SortByPriceMUp,
    SortByPriceMDown,
  ];
  if (data.length > 1) return functions[option](data);
  return;
}
