import React, { useState } from "react";
import Logo from "../assets/appIcon.png";
import Navbar from "../components/navbar";
import Footers, { QRCodeFooter } from "../utils/Footers";
import { PartnerAgent } from "../interfaces/Vendre";
import {
  VendreContent,
  VendreInput,
} from "../components/Vendre/Vendre.component";
import PhoneDisplay from "../components/Vendre/Vendre.phone.component";

/**
 * @name Vendre
 * @description Page de vente de bien immobilier
 * @constructor
 */
const Vendre = () => {
  const [data, setData] = useState<PartnerAgent[]>();
  const [PhoneFocus, setPhoneFocus] = useState<boolean>(false);

  return (
    <div className="h-auto w-full">
      <Navbar mod={true} />
      <nav className="h-[70px]" />
      <main>
        <div
          className={"flex lg:flex-row flex-col-reverse h-auto bg-[#d59999]"}
        >
          <main
            className={
              "lg:w-[40vw] h-auto bg-white-bubble backdrop-blur z-10 items-center flex flex-col"
            }
          >
            <VendreInput setData={setData} />
            <VendreContent />
          </main>
          <aside
            className={`${
              PhoneFocus
                ? "absolute w-full h-full top-0"
                : "relative h-auto w-full lg:w-[60vw] py-5 lg:py-0 lg:pb-[12vh] flex justify-center"
            }`}
          >
            <div
              className={
                "h-[20vh] w-[20vh] animate-blob2 bg-light-red absolute top-1/4 right-[10vh]"
              }
            />
            <div
              className={
                "h-[20vh] w-[30vh] animate-blob bg-light-red absolute top-[50%] -left-[15vh]"
              }
            />
            <div
              className={`transition-all duration-300 ease-out ${
                PhoneFocus
                  ? "h-full w-full z-30 bg-white-rgba backdrop-blur flex justify-center items-center fixed"
                  : "sticky top-[12vh] h-fit w-fit p-4 sm:px-10 py-[2vh] bg-white rounded-xl"
              }`}
            >
              {data ? (
                <PhoneDisplay
                  data={data}
                  setPhoneFocus={setPhoneFocus}
                  PhoneFocus={PhoneFocus}
                />
              ) : (
                <div className={"h-full  flex justify-center items-center"}>
                  <div
                    className={
                      "w-[272px] h-[552.5px] bg-[url('./assets/phone_esti.png')] bg-contain bg-no-repeat rounded flex flex-col items-center justify-center"
                    }
                  >
                    <img
                      src={Logo}
                      alt={"Loading logo"}
                      className={"object-scale-down h-[10vh]"}
                    />
                  </div>
                </div>
              )}
            </div>
          </aside>
        </div>
      </main>
      <QRCodeFooter multiple={false} />
      <Footers />
    </div>
  );
};

export default Vendre;
