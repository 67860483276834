import React, { useEffect, useState } from "react";
import { BiSolidCircle } from "react-icons/bi";
import { BsBarChartFill } from "react-icons/bs";
import { IoIosArrowBack, IoIosBatteryFull, IoIosWifi } from "react-icons/io";
import { BienImmo } from "../../interfaces/interface";
import { BVScreen, EstimationScreen } from "./EstimationScreen";
import { estimationInfo } from "../Home-component/Estimation";
import { EstimationData } from "../../interfaces/Estimer.interface";

/**
 * @name DisplayIndice
 * @description Affichage de l'indice de confiance
 * @param indice
 * @constructor
 */
export const DisplayIndice = ({ indice }: { indice: number }) => {
  const result = [];
  const DisplayCircle = ({
    index,
    circleKey,
  }: {
    index: number;
    circleKey: number;
  }) => {
    return (
      <BiSolidCircle
        key={circleKey}
        className={`text-clampPhoneLittle ${
          index < indice ? "text-[#980000]" : "text-[grey]"
        }`}
      />
    );
  };

  for (let i = 0; i !== 5; i++) {
    result.push(<DisplayCircle index={i} circleKey={i} key={i} />);
  }
  return (
    <div className="flex justify-start items-center gap-[0.1vw]">
      <div className={"text-clampPhoneLittle"}>Indice de confiance</div>
      <span className="flex gap-0.5">{result}</span>
    </div>
  );
};

/**
 * @name DisplayResult
 * @description Affichage du résultat de l'estimation
 * @param estimationValue
 * @param data
 * @param bienVendu
 * @param setBienVendu
 * @param handleBv
 * @param screen
 * @param setScreen
 * @param clicked
 * @param setClicked
 * @param PhoneFocus
 * @param setPhoneFocus
 * @constructor
 */
const DisplayResult = ({
  estimationValue,
  data,
  bienVendu,
  setBienVendu,
  handleBv,
  screen,
  setScreen,
  clicked,
  setClicked,
  PhoneFocus,
  setPhoneFocus,
}: {
  estimationValue: EstimationData;
  data: estimationInfo;
  bienVendu: BienImmo[] | null;
  setBienVendu: (args: BienImmo[] | null) => void;
  handleBv: (args: string) => void;
  screen: number;
  setScreen: (args: number) => void;
  clicked: boolean;
  setClicked: (args: boolean) => void;
  PhoneFocus: boolean;
  setPhoneFocus: (args: boolean) => void;
}) => {
  useEffect(() => {
    window.addEventListener("click", function (e) {
      const clickbox = document.getElementById("phoneFocus");
      const target = e.target as HTMLElement;

      if (
        clickbox &&
        !clickbox.contains(target) &&
        target.id !== "btn-biens-vendus"
      ) {
        setPhoneFocus(false);
      }
    });
  }, [PhoneFocus]);

  return (
    <div
      className={`${
        PhoneFocus
          ? "relative h-full w-full flex items-center justify-center"
          : "h-auto w-auto"
      }`}
    >
      <div
        id={`${PhoneFocus ? "phoneFocus" : "phoneNoFocus"}`}
        className={`w-auto h-auto ${
          PhoneFocus
            ? "bg-white rounded-xl transition-all duration-300 scale-125 absolute"
            : "select-none lg:left-[60vw] top-[12vh]"
        } flex items-center justify-center`}
      >
        <div
          className={`${
            PhoneFocus ? "m-10" : ""
          } w-[272px] h-[552.5px] focus:outline-none bg-[url('./assets/phone_esti.png')] bg-contain`}
        >
          <div className="w-full h-full relative">
            <PhonePopUp
              clicked={clicked}
              PhoneFocus={PhoneFocus}
              setPhoneFocus={setPhoneFocus}
              setClicked={setClicked}
            />
            <button
              onClick={() => setPhoneFocus(true)}
              className={`${
                PhoneFocus ? "hidden" : "block"
              } w-full h-[50%] absolute`}
            ></button>
            <main className={"w-full h-full"}>
              <PhoneHeader
                screen={screen}
                setBienVendu={setBienVendu}
                setPhoneFocus={setPhoneFocus}
                setScreen={setScreen}
                PhoneFocus={PhoneFocus}
              />
              <section className={"px-3 h-full"} id={"phoneFocus"}>
                {screen === 0 ? (
                  <EstimationScreen
                    estimationValue={estimationValue}
                    data={data}
                    setScreen={setScreen}
                    handleBV={handleBv}
                    clicked={clicked}
                    setClicked={setClicked}
                    PhoneFocus={PhoneFocus}
                    setPhoneFocus={setPhoneFocus}
                  />
                ) : (
                  <BVScreen bienVendu={bienVendu} />
                )}
              </section>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * @name PhoneHeader
 * @description Header du téléphone (heure + icones)
 * @param screen
 * @param setBienVendu
 * @param setPhoneFocus
 * @param setScreen
 * @param PhoneFocus
 * @constructor
 */
const PhoneHeader = ({
  screen,
  setBienVendu,
  setPhoneFocus,
  setScreen,
  PhoneFocus,
}: {
  screen: number;
  setBienVendu: (args: BienImmo[] | null) => void;
  setPhoneFocus: (args: boolean) => void;
  setScreen: (args: number) => void;
  PhoneFocus: boolean;
}) => {
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <header
      style={{ boxShadow: "0 4px 4px -4px gray" }}
      className={"pt-4 px-4 h-[15%] w-full"}
    >
      <div className={"text-clampPhone mx-[1vw] flex items-center h-[40%]"}>
        <ol className={"flex items-center w-full justify-between"}>
          {date ? (
            <li className={"font-bold cursor-default"}>
              {date.getHours()}:{date.getMinutes().toString().padStart(2, "0")}
            </li>
          ) : (
            <div className={"font-bold cursor-default"}>00:00</div>
          )}
          <ol className={"flex gap-1"}>
            <li>
              <BsBarChartFill />
            </li>
            <li>
              <IoIosWifi />
            </li>
            <li>
              <IoIosBatteryFull />
            </li>
          </ol>
        </ol>
      </div>
      <div className={"w-full flex justify-between h-[60%] items-center px-2"}>
        <button
          onClick={() => {
            if (screen !== 0) {
              setBienVendu(null);
              setScreen(0);
            } else setPhoneFocus(false);
          }}
        >
          <IoIosArrowBack
            className={`text-clampPhone ${
              PhoneFocus && screen === 0 ? "text-dark-red" : ""
            }`}
          />
        </button>
        <p className={"text-clampPhone cursor-default text-start"}>
          {screen === 0 ? "Estimation" : "Biens vendus"}
        </p>
        <span className={"w-[20px]"}></span>
      </div>
    </header>
  );
};

/**
 * @name PhonePopUp
 * @description PopUp pour l'interaction avec l'écran
 * @param clicked
 * @param PhoneFocus
 * @param setPhoneFocus
 * @param setClicked
 * @constructor
 */
const PhonePopUp = ({
  clicked,
  PhoneFocus,
  setPhoneFocus,
  setClicked,
}: {
  clicked: boolean;
  PhoneFocus: boolean;
  setPhoneFocus: (args: boolean) => void;
  setClicked: (args: boolean) => void;
}) => {
  return (
    <span
      className={`absolute z-20 top-1/2 -right-[11vw] ${
        clicked ? "hidden" : "block"
      }`}
    >
      <div
        className={`h-fit min-w-[100px] w-[10vw] p-2 rounded-lg font-semibold bg-gray-700 text-clampPhoneLittle ${
          PhoneFocus ? "hidden" : "block"
        }`}
      >
        <p className={"text-white"}>Intéragir avec cette écran</p>
        <button
          onClick={() => {
            setPhoneFocus(true);
            setClicked(true);
          }}
          className={"bg-dark-red px-2 py-1 rounded-lg text-white"}
        >
          Ok
        </button>
      </div>
    </span>
  );
};

export default DisplayResult;
