import React from "react";

type ExplicationCardProps = {
  icon: React.ReactElement;
  title: string;
  text: string;
};

/**
 * @name ExplicationCard
 * @description Consultation card component sur la page d'acceuil
 * @param icon
 * @param title
 * @param text
 * @constructor
 */
function ExplicationCard({ icon, title, text }: ExplicationCardProps) {
  return (
    <div className="w-full h-[15vh]">
      {icon}
      <h1 className="font-bold">{title}</h1>
      <div className="text-sm">{text}</div>
    </div>
  );
}

export default ExplicationCard;
