import React from "react";
import Navbar from "../components/navbar";
import Footers from "./Footers";
import { Outlet } from "react-router-dom";

/**
 * @name SAlogo
 * @description Le logo sans accompagnateur
 * @constructor
 */
export const SAlogo = () => {
  return (
    <span
      title="Cette visite s'éffectura sans accompagnateur"
      className="text-[10px] sm:text-xs rounded-[150px] h-6 w-6 border bg-white border-dark-red flex justify-center items-center text-dark-red font-bold"
    >
      Sa
    </span>
  );
};

/**
 * @name DefaultPageTemplate
 * @description Template par défaut
 * @param children composant enfant
 * @constructor
 */
export const DefaultPageTemplate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className={"h-auto w-full overflow-hidden"}>
      <Navbar mod={true} />
      <div className={"pt-[70px]"}>{children}</div>
      <Footers />
      <div>
        <Outlet />
      </div>
    </div>
  );
};
