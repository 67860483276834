import React, { useEffect, useRef, useState } from "react";
import { SelectOption } from "../utils/Select";
import { cityInfo, options, sellcityInfo } from "../interfaces/interface";
import { handleChangeCity } from "../utils/Consultation.utils";
import { estimationInfo } from "../components/Home-component/Estimation";
import { useNavigate } from "react-router-dom";

/**
 * @name useConsultation
 * @description Custom hook pour la consultation dans la page d'accueil
 */
export function useConsultation() {
  const [focus, setFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [CityList, setCityList] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [value1, setValue1] = useState<SelectOption[]>([
    options[0],
    options[1],
  ]);

  const [selectCity, setSelectCity] = useState<cityInfo[]>([]);
  const handleDelete = (index: number) => {
    const updatedCityList = selectCity.length === 1 ? [] : [...selectCity];
    updatedCityList.splice(index, 1);
    setSelectCity(updatedCityList);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setUserInput(input);
    handleChangeCity(input, setCityList);
    console.log(CityList);
  };

  return {
    focus,
    setFocus,
    isOpen,
    setIsOpen,
    CityList,
    userInput,
    value1,
    setValue1,
    selectCity,
    setSelectCity,
    handleDelete,
    handleChange,
    setUserInput,
  };
}

/**
 * @name useEstimation
 * @description Custom hook pour l'estimation dans la page d'accueil
 */
export function useEstimation() {
  const [estimationInput, setEstimationInput] = useState("");
  const [estimationList, setEstimationList] = useState<estimationInfo[]>([]);
  const [estimationFocus, setEstimationFocus] = useState(false);
  const [selectAddress, setSelectAddress] = useState<estimationInfo>();

  return {
    estimationInput,
    setEstimationInput,
    estimationList,
    setEstimationList,
    estimationFocus,
    setEstimationFocus,
    selectAddress,
    setSelectAddress,
  };
}

/**
 * @name useSell
 * @description Custom hook pour la vente dans la page d'accueil
 */
export function useSell() {
  const [SelluserInput, setSellUserInput] = useState("");
  const [sellcityList, setSellCityList] = useState([]);
  const [sellselectCity, setSellSelectCity] = useState<sellcityInfo>();
  const [sellfocus, setSellFocus] = useState(false);

  return {
    SelluserInput,
    setSellUserInput,
    sellcityList,
    setSellCityList,
    sellselectCity,
    setSellSelectCity,
    sellfocus,
    setSellFocus,
  };
}

export enum SearchBar {
  acheter = 0,
  vendre = 1,
  estimer = 2,
}

/**
 * @name useOther
 * @description Custom hook pour les autres fonctionnalités
 * @param setFocus
 * @param setSellFocus
 * @param setEstimationFocus
 * @param setIsOpen
 */
export function useOther(
  setFocus: (args: boolean) => void,
  setSellFocus: (args: boolean) => void,
  setEstimationFocus: (args: boolean) => void,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const SearchBardata = [
    {
      title: "Acheter",
      enum: SearchBar.acheter,
    },
    {
      title: "Vendre",
      enum: SearchBar.vendre,
    },
    {
      title: "Estimer",
      enum: SearchBar.estimer,
    },
  ];

  const navigate = useNavigate();
  const [searchBar, setSearchBar] = useState(SearchBar.acheter);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchBarRef.current &&
      !searchBarRef.current.contains(event.target as Node)
    ) {
      console.log(searchBar);
      switch (searchBar) {
        case SearchBar.acheter:
          setFocus(false);
          break;
        case SearchBar.vendre:
          setSellFocus(false);
          break;
        case SearchBar.estimer:
          setEstimationFocus(false);
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, searchBar]);

  const toggleOptions = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return {
    searchBar,
    searchBarRef,
    toggleOptions,
    navigate,
    SearchBardata,
    SearchBar,
    setSearchBar,
  };
}

/**
 * @name useIntroCard
 * @description Custom hook pour la carte d'introduction (gestion de la vidéo)
 */
export function useIntroCard() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const handleVideoHover = (play: boolean) => {
    console.log(play);
    if (play) {
      if (videoRef.current) {
        videoRef.current.play();
      }
    } else {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  };

  return { videoRef, handleVideoHover };
}
