import { SectionIdEnum } from "./types/section-id";
import React from "react";

export type SectionContainerProps = {
  children: React.ReactNode;
  sectionId: SectionIdEnum;
};

/**
 * @name SectionContainer
 * @description Container de section
 * @param children
 * @param sectionId
 * @constructor
 */
export const SectionContainer: React.FC<SectionContainerProps> = ({
  children,
  sectionId,
}) => {
  return (
    <div className={"w-full"} id={sectionId} key={sectionId}>
      <div className={"w-full"}>{children}</div>
    </div>
  );
};
