import React, { useState, useRef } from "react";
import { filterList, sellType } from "../../../interfaces/interface";

/**
 * @name HeaderCard
 * @description Header de la liste des biens à vendre
 * @param cityName
 * @param data
 * @param filter
 * @param setFilter
 * @constructor
 */
function HeaderCard({
  cityName,
  data,
  filter,
  setFilter,
}: {
  cityName: string[];
  data: sellType[];
  acc: { with: boolean; without: boolean };
  setAcc: React.Dispatch<
    React.SetStateAction<{
      with: boolean;
      without: boolean;
    }>
  >;
  filter: { index: number; value: string };
  setFilter: React.Dispatch<
    React.SetStateAction<{
      index: number;
      value: string;
    }>
  >;
}) {
  const [displayFilter, setDisplayFilter] = useState(false);
  const dropdownRef = useRef(null);

  const toggleFilter = () => {
    setDisplayFilter(!displayFilter);
  };

  const handleFilterClick = (index: number) => {
    setFilter(filterList[index]);
    setDisplayFilter(false);
  };

  const FilterInput = () => {
    return (
      <div
        onBlur={() => setDisplayFilter(false)}
        className="relative flex flex-col"
      >
        <span className="text-[12px]">Trier par:</span>
        <button
          onClick={toggleFilter}
          className="text-[13px] flex p-1 transition-all duration-300 h-7 w-[160px] border rounded focus:border-[#980000]"
        >
          {filter.value}
        </button>
        {displayFilter && (
          <div
            ref={dropdownRef}
            className="absolute top-12 w-[160px] left-0 z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm flex flex-col"
          >
            {filterList.map(({ index, value }) => {
              return (
                <button
                  className="flex p-1"
                  onClick={() => handleFilterClick(index)}
                  key={index}
                >
                  {value}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <header
      className={`sm:p-3 px-3 pb-5 bg-w text-sm w-full h-auto sm:h-[10vh] flex flex-col`}
    >
      <div>
        <span className="">{cityName[0]}</span>{" "}
        {cityName.length === 1 ? null : `et (+${cityName.length - 1})`} :{" "}
        <span className="">
          {data
            ? `${data.length} ${
                data.length === 1 ? "annonce" : "annonces"
              } dans ${
                cityName.length === 1
                  ? "la zone renseignée"
                  : "les zones renseignées"
              }`
            : "0 annonce"}
        </span>
      </div>
      <div className="flex flex-row grid grid-rows-2 grid-flow-col">
        <FilterInput />
        <span className={"text-dark-red content-center row-start-1 row-end-2 font-bold"}>
          Les annonces affichées sont fictives
        </span>
      </div>
    </header>
  );
}

export default HeaderCard;
