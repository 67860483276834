import format from "date-fns/format/index";
import { fr } from "date-fns/locale";

/**
 * @name formatDate
 * @description Formate les dates "MM/yyyy"
 * @param dateISO
 */
export const formatDate = (dateISO: string) => {
  const date = new Date(dateISO);
  return format(date, "MM/yyyy", { locale: fr });
};

/**
 * @name formatDateFull
 * @description Formate les dates "dd/MM/yyyy"
 * @param dateISO
 */
export const formatDateFull = (dateISO: string) => {
  const date = new Date(dateISO);
  return format(date, "dd/MM/yyyy", { locale: fr });
};

/**
 * @name price_format
 * @description Formate les prix en euros
 * @param price
 */
export function price_format(price: number) {
  const format = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });
  return format.format(price).replaceAll(".", " ");
}
