import React, { useRef } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SectionIdEnum } from "./types/section-id";
import estimationPic from "../../assets/estimation steven 1.jpg";
import sellPic from "../../assets/vente-steven-2.jpg";
import {
  ConsultationInfo,
  handleNavigate,
} from "../../utils/Consultation.utils";
import { ConsultationOther } from "./Consultation";
import { options } from "../../interfaces/interface";
import { IoClose, IoSearch } from "react-icons/io5";
import {
  DisplayEstimationList,
  handleEstimationChange,
  handleNavigateEsti,
} from "../../utils/Estimation.utils";
import {
  SearchBar,
  useConsultation,
  useEstimation,
  useIntroCard,
  useOther,
  useSell,
} from "../../hooks/Intro.hooks";
import { DisplaySellList } from "../Vendre/Vendre.component";
import { handleChangeSell } from "../../utils/Vendre.utils";
import { DisplaySelectCity } from "../Consultation-Component/Consultation.component";

/**
 * @name Intro
 * @description Section d'introduction de la page d'accueil
 * @constructor
 */
function Intro() {
  // sell search
  const {
    SelluserInput,
    setSellUserInput,
    sellcityList,
    setSellCityList,
    sellselectCity,
    setSellSelectCity,
    sellfocus,
    setSellFocus,
  } = useSell();

  // estimation search
  const {
    estimationInput,
    setEstimationInput,
    estimationList,
    setEstimationList,
    estimationFocus,
    setEstimationFocus,
    selectAddress,
    setSelectAddress,
  } = useEstimation();

  // consult search
  const {
    focus,
    setFocus,
    isOpen,
    setIsOpen,
    CityList,
    userInput,
    value1,
    setValue1,
    selectCity,
    setSelectCity,
    handleDelete,
    handleChange,
    setUserInput,
  } = useConsultation();

  const {
    searchBar,
    searchBarRef,
    toggleOptions,
    navigate,
    SearchBardata,
    SearchBar,
    setSearchBar,
  } = useOther(setFocus, setSellFocus, setEstimationFocus, setIsOpen);

  const buyContent = (
    <>
      <div
        className={`w-full relative lg:w-[50%] flex flex-col transition-all duration-300`}
      >
        <form
          className={`transition-all duration-300 rounded-lg items-center flex ${
            selectCity.length > 0
              ? `py-[.5em] lg:py-2 w-full border-[#b64c4c] ${
                  focus ? "flex-wrap" : "h-[40px]"
                }`
              : "h-[40px]"
          } w-full`}
        >
          <DisplaySelectCity
            selectCity={selectCity}
            handleDelete={handleDelete}
            focus={focus}
          />
          <input
            onFocus={() => setFocus(true)}
            value={userInput}
            onChange={handleChange}
            placeholder={"Saisir une ville ou code postal"}
            className={`focus:outline-none mr-2 text-sm w-full h-[49px] bg-transparent`}
          />
        </form>
        <ConsultationInfo
          focus={focus}
          CityList={CityList}
          selectCity={selectCity}
          setSelectCity={setSelectCity}
          setUserInput={setUserInput}
        />
      </div>
      <ConsultationOther
        focus={focus}
        isOpen={isOpen}
        toggleOptions={toggleOptions}
        options={options}
        value1={value1}
        setValue1={setValue1}
        selectCity={selectCity}
      />
    </>
  );

  const SellInput = (
    <div className={"h-[40px] relative w-full"}>
      <span className={"w-full flex justify-between h-full items-center"}>
        <input
          onFocus={() => setSellFocus(true)}
          value={SelluserInput}
          onChange={(e) => {
            handleChangeSell(
              e,
              setSellCityList,
              SelluserInput,
              setSellUserInput,
            );
          }}
          placeholder={"Saisir une ville ou code postal"}
          className={`focus:outline-none mr-2 text-sm w-full h-full bg-transparent`}
        />
        <button
          onClick={() => {
            setSellCityList([]);
          }}
          onTouchStart={() => {
            setSellCityList([]);
          }}
        >
          <IoClose className={"text-[#737373]"} />
        </button>
      </span>
      <DisplaySellList
        focus={sellfocus}
        cityList={sellcityList}
        setUserInput={setSellUserInput}
        setSelectCity={setSellSelectCity}
        setFocus={setSellFocus}
      />
    </div>
  );

  const EstimationInput = (
    <div className={"h-[40px] relative w-full"}>
      <span className={"w-full flex justify-between h-full items-center"}>
        <input
          onFocus={() => setEstimationFocus(true)}
          value={estimationInput}
          onChange={(e) =>
            handleEstimationChange(
              e.target.value,
              setEstimationInput,
              setEstimationList,
            )
          }
          placeholder={"Saisir une adresse"}
          className={`focus:outline-none mr-2 text-sm w-full h-full bg-transparent`}
        />
        <button
          onClick={() => {
            setEstimationList([]);
          }}
        >
          <IoClose className={"text-[#737373]"} />
        </button>
      </span>
      <DisplayEstimationList
        estimationFocus={estimationFocus}
        estimationList={estimationList}
        setEstimationInput={setEstimationInput}
        setEstimationFocus={setEstimationFocus}
        setSelectAddress={setSelectAddress}
      />
    </div>
  );

  return (
    <div className="h-auto 2xl:h-[94dvh] bg-consultationBg xl:bg-[0_-800px] bg-cover bg-no-repeat bg-slate-50 w-full">
      <div
        className={
          "h-[50vh] md:px-0 px-2 2xl:h-[60%] bg-darkRedRgba w-full flex flex-col items-center justify-end py-4"
        }
      >
        <section
          className={
            "w-full lg:w-[900px] h-auto static flex gap-7 flex-col items-center"
          }
        >
          <IntroHeader
            searchBar={searchBar}
            setSearchBar={setSearchBar}
            SearchBardata={SearchBardata}
          />
          <div
            ref={searchBarRef}
            className={`bg-white  sm:flex-row ${
              searchBar === SearchBar.acheter ? "flex-col" : "flex-row"
            } px-1 pl-3 py-1 transition-all duration-300 shadow-[rgba(0,0,0,0.35)_0px_5px_15px] h-auto w-[95%] md:w-[70%] xl:w-[700px] rounded-3xl flex md:justify-center gap-2 items-end`}
          >
            {searchBar === SearchBar.acheter
              ? buyContent
              : searchBar === SearchBar.vendre
              ? SellInput
              : EstimationInput}
            <div className={"w-fit flex items-end h-full"}>
              <button
                name={"search"}
                onClick={() => {
                  if (searchBar === SearchBar.acheter) {
                    handleNavigate(selectCity, value1, navigate, false);
                  } else if (searchBar === SearchBar.estimer) {
                    handleNavigateEsti(selectAddress, navigate);
                  } else {
                    navigate(
                      `/vente?city=${sellselectCity?.name}&cp=${sellselectCity?.zipcode}&index=${sellselectCity?.index}`,
                    );
                  }
                }}
                className={`transition-all ${
                  searchBar === SearchBar.vendre
                    ? sellselectCity?.statusdeploy
                      ? ""
                      : "bg-slate-50 text-black pointer-events-none"
                    : ""
                } ${
                  searchBar === SearchBar.acheter ? "hidden md:flex" : ""
                } duration-300 text-white bg-dark-red flex items-center justify-center h-[35px] w-[35px] rounded-full`}
              >
                <IoSearch className={"text-2xl"} />
              </button>
            </div>
          </div>
        </section>
        <p className={"mt-5 text-sm text-white"}>
          Retrouvez tous nos services dans{" "}
          <AnchorLink
            className={"underline font-medium"}
            href={`#${SectionIdEnum.telecharger}`}
          >
            nos applications
          </AnchorLink>
        </p>
      </div>
      <aside
        className={
          "w-full relative flex justify-center items-center bg-white py-5 2xl:h-[40%]"
        }
      >
        <IntroCards />
      </aside>
    </div>
  );
}

/**
 * @name IntroCards
 * @description Cartes d'introduction de la page d'accueil
 * @constructor
 */
const IntroCards = () => {
  const { videoRef, handleVideoHover } = useIntroCard();

  return (
    <main
      className={
        "items-center xl:justify-center xl:overflow-visible overflow-x-auto py-10 gap-12 flex"
      }
    >
      <div className="relative xl:ml-0 ml-2">
        <div className="overflow-hidden relative max-w-sm mx-auto w-[350px] h-[180px] bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex items-center gap-6">
          <div
            className={
              "h-[100px] w-[100px] rounded-customBlobTriangle3 absolute bottom-4 left-16 bg-light-red"
            }
          />
          <img
            src={estimationPic}
            alt={"estimation"}
            className="object-[0_0] absolute -left-40 w-[300px] h-[300px] object-cover rounded-full shadow-lg"
          />
          <div className="flex flex-col h-full justify-between py-3 pl-44">
            <span></span>
            <span className={"flex flex-col"}>
              <span className="text-[#737373] text-sm font-medium">
                <span className={"font-bold text-black "}>Estimation</span>{" "}
                accès libre et Instantané. Aucune information demandée
              </span>
            </span>
            <span className={"w-full flex justify-end px-2"}>
              <AnchorLink
                href={`#${SectionIdEnum.estimer}`}
                className={"text-dark-red font-bold text-sm"}
              >
                En savoir plus
              </AnchorLink>
            </span>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="overflow-hidden hover:justify-center group relative max-w-sm mx-auto w-[350px] h-[180px] bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex items-center gap-6">
          <div
            className={
              "h-[100px] w-[100px] rounded-customBlobTriangle3 absolute bottom-4 left-16 bg-darkRedRgba"
            }
          />
          <iframe
            onMouseEnter={() => {
              if (videoRef.current) videoRef.current.play();
            }}
            onMouseLeave={() => handleVideoHover(false)}
            allowFullScreen={true}
            src={"https://www.youtube.com/embed/fLIljVW4IcM?rel=0"}
            className="video group-hover:static group-hover:h-full group-hover:cursor-pointer group-hover:w-full group-hover:object-cover absolute group-hover:translate-x-32 transition-all duration-300 group-hover:rounded-none -left-40 w-[300px] h-[300px] object-cover rounded-full shadow-lg"
          ></iframe>
          <div className="flex flex-col py-5 pl-44">
            <span className="text-slate-500 text-sm font-medium">
              <span className="text-black text-sm font-bold">Flatway </span>en
              video
            </span>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="overflow-hidden relative max-w-sm mx-auto w-[350px] h-[180px] bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex items-center gap-6">
          <div
            className={
              "h-[100px] w-[100px] rounded-customBlobTriangle3 absolute bottom-4 left-16 bg-dark-red"
            }
          />
          <img
            src={sellPic}
            alt={"seller"}
            className="object-left absolute -left-40 w-[300px] h-[300px] object-cover rounded-full shadow-lg"
          />
          <div className="flex flex-col h-full justify-between py-3 pl-44">
            <span></span>
            <span className={"flex flex-col"}>
              <span className="text-[#737373] text-sm font-medium">
                Confier la <span className={"font-bold text-black"}>vente</span>{" "}
                à un ou plusieurs professionnels depuis chez vous
              </span>
            </span>
            <span className={"w-full flex justify-end px-2"}>
              <AnchorLink
                href={`#${SectionIdEnum.vendre}`}
                className={"text-dark-red font-bold text-sm"}
              >
                Découvrir
              </AnchorLink>
            </span>
          </div>
        </div>
      </div>
    </main>
  );
};

/**
 * @name IntroHeader
 * @description En-tête de la section d'introduction
 * @param searchBar
 * @param setSearchBar
 * @param SearchBardata
 * @constructor
 */
const IntroHeader = ({
  searchBar,
  setSearchBar,
  SearchBardata,
}: {
  searchBar: SearchBar;
  setSearchBar: (searchBar: SearchBar) => void;
  SearchBardata: { title: string; enum: SearchBar }[];
}) => {
  return (
    <>
      <div
        className={
          "w-full sm:w-fit h-fit flex items-center justify-evenly sm:justify-center flex-col gap-5 xl:gap-[20vh]"
        }
      >
        <header className={"flex flex-col items-center"}>
          <h1
            className={
              "font-bold text-clampIntroTitle leading-8 sm:leading-[4vw] text-white"
            }
          >
            Flatway, l'immobilier revisité
          </h1>
          <span>
            <p className={"text-clampIntroXl text-white"}>
              La visite par le vendeur ou par le professionnel
            </p>
          </span>
        </header>
      </div>
      <section className={"w-fit h-fit flex items-center relative"}>
        <div
          className={
            "w-full h-full absolute flex items-center pointer-events-none"
          }
        >
          <div
            className={`bg-light-red py-1 px-1.5 w-fit rounded-lg ${
              searchBar === SearchBar.acheter
                ? ""
                : searchBar === SearchBar.vendre
                ? "translate-x-[125%]"
                : "translate-x-[230%]"
            } transition-all bg-darkRedTitle text-dark-red font-medium duration-300 flex items-center justify-center`}
          >
            {SearchBardata.find((data) => data.enum === searchBar)?.title}
          </div>
        </div>
        <ul className={"flex w-full gap-4"}>
          {SearchBardata.map((data, index) => (
            <li key={index}>
              <button
                onClick={() => {
                  console.log(data.enum);
                  setSearchBar(data.enum);
                }}
                className={`bg-gray-200 py-1 px-2 rounded-lg text-sm transition-all duration-300`}
              >
                {data.title}
              </button>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Intro;
