/**
 * Model pour les biens vendus
 */
export interface BienImmo {
  date_mutation: string;
  valeur_fonciere: string;
  adresse_numero: number;
  adresse_suffixe: string | null;
  adresse_nom_voie: string;
  code_postal: string;
  nom_commune: string;
  type_local: string;
  surface_reelle_bati: string;
  nombre_pieces_principales: number;
  surface_carrez_reconstituee: string | null;
  nb_dependance: number;
  locaux: boolean;
  qte_lot: number;
  prix_m2: number;
  prix_m2_maj: number;
  crete: number;
  distance: number;
}

/**
 * Model pour les biens
 */
export type sellType = {
  annonce_id: number;
  property_id: number;
  partneragent_id: string;
  prixdevente: number;
  city: string;
  code_postal: string;
  type_bien: string;
  surface: number;
  accompanist: boolean;
  latitude: string;
  longitude: string;
  nb_piece: number;
  nb_chambre: number;
  pictures: string[];
  logo_pa: string;
  create_date: string;
};

/**
 * @name options
 * @description Options pour le type de bien
 */
export const options = [
  { label: "Appartement", value: "app" },
  { label: "Maison", value: "mai" },
];

export type cityInfo = {
  index: number;
  zipcode: string;
  name: string;
};

export type sellcityInfo = {
  index: number;
  zipcode: string;
  name: string;
  statusdeploy: boolean;
};

export const filterList = [
  { index: 0, value: "Prix croissant" },
  { index: 1, value: "Prix décroissant" },
  { index: 2, value: "Surface croissant" },
  { index: 3, value: "Surface décroissant" },
  { index: 5, value: "Prix m² croissant" },
  { index: 6, value: "Prix m² décroissant" },
];
