import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import phoneImg from "../assets/phone_esti.png";
import { IoIosArrowForward } from "react-icons/io";

/**
 * @name FlatWayHeader
 * @description Template Header de la page Flatway
 * @param title
 * @param desc
 * @constructor
 */
export const FlatWayHeader = ({
  title,
  desc,
}: {
  title: string;
  desc: string;
}) => {
  return (
    <header className={"h-auto flex flex-col items-center mb-10"}>
      <h1
        className={
          "text-3xl sm:text-clampProIntroTitle text-darkRedTitle leading-tight font-bold"
        }
      >
        {title}
      </h1>
      <h2
        className={
          "xl:w-[1200px] sm:text-lg xl:text-[1vw] font-semibold text-[#737373] text-center"
        }
      >
        {desc}
      </h2>
    </header>
  );
};

/**
 * @name PreseText
 * @description Template pour les textes de présentation (format "icone Check" + text)
 * @param text
 * @constructor
 */
export const PreseText = ({ text }: { text: String }) => {
  return (
    <span className="flex xl:justify-center items-center gap-5 text-wrap w-full">
      <FaCheck className="text-[#980000] text-2xl" />
      <p className="w-[80%] sm:w-[680px] text-justify">{text}</p>
    </span>
  );
};

/**
 * @name VisiteCard
 * @description Template pour les cartes de visite visuelle sur la page des visites
 * @param children
 * @param title
 * @param image Image du telephone qui slide vers le haut au hover
 * @constructor
 */
export const VisiteCard = ({
  children,
  title,
  image,
}: {
  children: React.ReactNode;
  title: string;
  image: string;
}) => {
  return (
    <div
      className={
        "h-auto min-w-[350px] w-[30%] overflow-hidden border rounded-lg drop-shadow-lg flex flex-col justify-between bg-white hover:shadow-dark-red"
      }
    >
      <header
        className={
          "h-auto min-h-[400px] xl:min-h-[330px] pt-10 px-10 flex flex-col gap-5"
        }
      >
        <h1 className={"font-bold text-dark-red text-xl sm:text-2xl"}>
          {title}
        </h1>
        {children}
      </header>
      <div className={"max-h-[200px] w-full flex justify-center"}>
        <div className={"h-full w-[70%]"}>
          <img
            src={image}
            className={
              "object-scale-down transition-all duration-300 hover:-translate-y-60"
            }
            alt={"demande"}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * @name FlatwayButton
 * @description Template pour les buttons de la page Flatway (pas utiliser partout)
 * @param text
 * @param route
 * @constructor
 */
export const FlatwayButton = ({
  text,
  route,
}: {
  text: string;
  route: string;
}) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        window.scrollTo(0, 0);
        navigate(route);
      }}
      className={
        "w-fit bg-dark-red px-2 py-1 rounded-lg text-white font-medium"
      }
    >
      {text}
    </button>
  );
};

/**
 * @name FlatwaySection
 * @description Template pour les sections de la page Flatway
 * @param title
 * @param route Route qui renvoie vers la page détaillée du service (achat, vente et visite / offre)
 * @param image
 * @param list Liste des points à afficher
 * @constructor
 */
export const FlatwaySection = ({
  title,
  route,
  image,
  list,
}: {
  title: string;
  route: string;
  image: string;
  list: string[];
}) => {
  return (
    <div
      className={`flex ${
        title ===
        "Flatway vous accompagne dans la vente et simplifie vos démarches"
          ? "xl:flex-row-reverse"
          : "xl:flex-row"
      } gap-[5vw] items-center flex-col xl:px-0 px-10 justify-center`}
    >
      <aside>
        <div className={"w-full xl:w-[30vw] xl:h-[400px]"}>
          <img
            src={image}
            className={"w-full h-full object-cover rounded-xl"}
            alt={"sellImgSection"}
          />
        </div>
      </aside>
      <main className="sm:h-auto flex w-full xl:w-[30vw] justify-center gap-5 flex-col">
        <h1 className={"text-3xl sm:text-3xl leading-tight font-bold"}>
          {title}
        </h1>
        <ul className={"flex flex-col  text-sm md:text-base gap-2"}>
          {list.map((item, index) => (
            <li key={index} className={"flex gap-3 items-center"}>
              <PreseText text={item} />
            </li>
          ))}
        </ul>
        <FlatwayButton text={"En savoir plus"} route={route} />
      </main>
    </div>
  );
};

/**
 * @name  FlatwaySectionTemplate
 * @description Template pour les sections des timeline deffilantes dans la page achat, vente et visite
 * @param title
 * @param description
 * @param children
 * @constructor
 */
export const FlatwaySectionTemplate = ({
  title,
  description,
  children,
}: {
  children: React.JSX.Element;
  title: string;
  description: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"mb-7"}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={
          "bg-slate-50 py-1 active:bg-light-red transition-all duration-300 px-2 rounded-xl hover:bg-slate-200 flex items-center justify-between w-full"
        }
      >
        <h1 className={"font-bold text-[15px] sm:text-clampTimelineTitle"}>
          {title}
        </h1>
        <IoIosArrowForward
          className={`text-[20px] text-dark-red transition-all duration-300 ${
            isOpen ? "rotate-90" : ""
          }`}
        />
      </button>
      <h3 className={"text-clampTimelineContent"}>{description}</h3>
      <span
        className={`${
          isOpen ? "block" : "hidden"
        } text-clampTimelineContent mt-5 leading-5`}
      >
        {children}
      </span>
    </div>
  );
};

/**
 * @name DisplayBubble
 * @description Affichage des sections de la timeline avec les bulles, leur contenu
 * @param counter
 * @param sections
 * @constructor
 */
const DisplayBubble = ({
  counter,
  sections,
}: {
  counter: number;
  sections: React.JSX.Element[];
}) => {
  return (
    <div className={"h-auto w-full flex flex-col items-start z-10"}>
      {sections.map((item, index) => (
        <div
          key={index}
          className={"flex justify-start w-full flex-row gap-3 h-auto relative"}
        >
          <div className={"flex flex-col items-center h-full"}>
            <span
              className={`h-[4vh] z-10 w-[4vh] rounded-3xl transition-all duration-300 flex justify-center items-center text-white text-clampTimelineTitle font-bold p-2 ${
                index === counter ? "bg-dark-red" : "bg-[#1e2428]"
              }`}
            >
              {index + 1}
            </span>
            {index === sections.length - 1 ? null : (
              <span className={"h-full w-[5px] bg-[grey] absolute"}></span>
            )}
          </div>
          <div className={"w-full"}>{item}</div>
        </div>
      ))}
    </div>
  );
};

/**
 * @name DisplayRoadMapSection
 * @description Affichage de la timeline avec le telephone qui slide les écrans
 * @param images
 * @param sections
 * @param title
 * @constructor
 */
export const DisplayRoadMapSection = ({
  images,
  sections,
  title,
}: {
  images: string[];
  sections: React.JSX.Element[];
  title: string;
}) => {
  const [counter, setCounter] = useState(0);
  const [hover, setHover] = useState(false);

  // genre l'interval de slide entre les ecrans et les points de la timeline
  useEffect(() => {
    if (hover) return;
    const interval = setInterval(() => {
      setCounter((prevCounter) =>
        prevCounter === images.length - 1 ? 0 : prevCounter + 1,
      );
    }, 2500);
    return () => clearInterval(interval);
  }, [hover, images.length]);

  return (
    <div
      className={
        "h-auto w-full flex flex-col mt-10 gap-10 justify-between items-center"
      }
    >
      <div
        className={
          "h-auto md:h-auto xl:px-0 px-2 w-full flex lg:flex-row flex-col gap-10 justify-between md:justify-center"
        }
      >
        <div
          className={
            "h-[60vh] flex items-center w-full justify-center xl:w-[20vw]"
          }
        >
          <aside
            className={`h-auto flex-none w-[30vh] flex justify-center items-center relative`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <img
              src={phoneImg}
              alt="phone"
              className={`w-full absolute z-10`}
            />
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className={`opacity-${
                  counter + 1 === index + 1 ? "100" : "0"
                } w-[99%] transition-opacity absolute duration-500`}
                alt={`${index}`}
              />
            ))}
          </aside>
        </div>
        <div
          className={
            "h-auto w-full lg:w-[500px] flex justify-center flex-col gap-10"
          }
        >
          <h1 className={"font-bold text-xl md:text-3xl"}>{title}</h1>
          <DisplayBubble counter={counter} sections={sections} />
        </div>
      </div>
    </div>
  );
};
