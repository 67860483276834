import React, { useState } from "react";
import { FlatWayHeader } from "../../utils/FlatWay.utils";
import bg from "../../assets/intro-bg.jpg";
import { CiMedal } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FaRegHandshake } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import AnchorLink from "react-anchor-link-smooth-scroll";

/**
 * @name FlatWayIntroComponent
 * @description Section d'introduction de la page Flatway
 * @constructor
 */
export const FlatWayIntroComponent = () => {
  return (
    <div className={"h-auto w-full"}>
      <div className={"h-auto w-full flex items-center flex-col gap-10"}>
        <FlatwayIntroHeader />
        <FlatwayIntroContent />
        <FlatwaySecurityContent />
      </div>
    </div>
  );
};

/**
 * @name FlatwayIntroHeader
 * @description Header de la section d'introduction de la page Flatway
 * @constructor
 */
const FlatwayIntroHeader = () => {
  return (
    <section
      className={
        "lg:h-[80vh] w-full font-bold overflow-hidden flex items-center justify-center text-white relative"
      }
    >
      <img
        src={bg}
        className={"object-cover w-full h-full brightness-75"}
        alt={"bg img"}
      />
      <header className={"absolute text-center"}>
        <h1 className={"text-2xl sm:text-7xl mt-32"}>Découvrir nos services</h1>
        <h2 className={"text-lg sm:text-4xl"}>
          Gratuits pour les particuliers{" "}
        </h2>
      </header>
    </section>
  );
};

/**
 * @name FlatwayIntroContent
 * @description Contenu de la section d'introduction de la page Flatway
 * @constructor
 */
const FlatwayIntroContent = () => {
  return (
    <main
      className={"h-auto w-auto flex flex-col items-center justify-end gap-10"}
    >
      <h1
        className={
          "text-xl sm:text-clampProIntroTitle mt-10 text-darkRedTitle font-bold"
        }
      >
        Ce qui nous différencie
      </h1>
      <section
        className={
          "w-full px-[10%] xl:sm-0 xl:w-[50vw] h-auto grid grid-cols-2 gap-2 grid-flow-row"
        }
      >
        <p className={"p-4 bg-light-red rounded-xl "}>
          Flatway ne vend pas de l’immobilier.
        </p>
        <p className={"p-4 bg-light-red rounded-xl "}>
          Nos services sont gratuits pour les particuliers.
        </p>
        <p className={"col-span-2 p-4 bg-slate-50 text-black rounded-xl "}>
          Notre approche s’appuie sur les compétences des professionnels pour
          simplifier la transaction immobilière et offrir aux utilisateurs une
          alternative moins onéreuse en termes d’honoraires.
        </p>

        <ul className={"flex gap-5 mt-10 sm:flex-row flex-col col-span-full"}>
          <li>
            <CiMedal className={"text-3xl text-dark-red"} />
            Utilisez les services des professionnels pour vendre et acheter en
            toute transparence
          </li>
          <li>
            <IoIosInformationCircleOutline
              className={"text-3xl text-dark-red"}
            />
            Le professionnel renseigne ses barèmes d’honoraires standards et à
            taux réduits
          </li>
          <li>
            <FaRegHandshake className={"text-3xl text-dark-red"} />
            Assurez vous-même les visites et bénéficiez des honoraires à taux
            réduits
          </li>
        </ul>
      </section>
    </main>
  );
};

/**
 * @name FlatwaySecurityContent
 * @description Contenu de la section de sécurité de la page Flatway
 * @constructor
 */
const FlatwaySecurityContent = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <span className={"lg:px-0 px-4 pt-[70px]"} id={"security"}>
      <section
        className={
          "bg-light-red w-full lg:w-[65vw] px-10 rounded-xl gap-5 flex flex-col justify-center items-center py-5"
        }
      >
        <h1 className={"text-3xl sm:text-3xl leading-tight font-bold"}>
          Votre sécurité, notre priorité
        </h1>
        <p>
          La confidentialité des informations et la sécurité de nos utilisateurs
          sont au cœur de nos préoccupations.
        </p>
        <button
          onClick={() => setIsOpen(true)}
          className={
            "w-fit bg-dark-red px-2 py-1 rounded-lg text-white font-medium"
          }
        >
          En savoir plus
        </button>
      </section>
      <span
        className={`${isOpen ? "block" : "hidden"} flex flex-col items-center`}
      >
        <span className={"h-[20px] w-full flex justify-center"}>
          <div className={"h-full w-2 bg-dark-red"} />
        </span>
        <ConfiSection />
        <span className={"h-[20px] w-full flex justify-center"}>
          <div className={"h-full w-2 bg-dark-red"} />
        </span>
        <section
          className={
            "bg-light-red w-full lg:w-[65vw] rounded-xl gap-5 px-10 flex flex-col justify-center items-center py-5"
          }
        >
          <h1 className={"text-3xl sm:text-3xl leading-tight font-bold"}>
            La sécurité de nos utilisateurs{" "}
          </h1>
          <p>
            Pour la sécurité de tous, nous sensibilisons nos utilisateurs,
            vendeurs, acheteurs et professionnels, à l'utilisation des QR codes
            fournis par Flatway pour les visites des biens.
            <br />
            Les utilisateurs munis de QR codes sont préalablement identifiés.
          </p>
        </section>
        <span className={"h-[20px] w-full flex justify-center"}>
          <div className={"h-full w-2 bg-dark-red"} />
        </span>
        <AnchorLink
          href={"#security"}
          onClick={() => setIsOpen(false)}
          className={
            "text-3xl h-8 w-8 flex items-center justify-center rounded-full bg-dark-red text-white"
          }
        >
          <IoClose />
        </AnchorLink>
      </span>
    </span>
  );
};

/**
 * @name ConfiSection
 * @description Section de confidentialité de la page Flatway
 * @constructor
 */
const ConfiSection = () => {
  return (
    <section
      className={
        "bg-slate-50 w-full lg:w-[65vw] rounded-xl gap-5 px-10 flex flex-col justify-center items-center py-5"
      }
    >
      <h1 className={"text-3xl sm:text-3xl leading-tight font-bold"}>
        La confidentialité des informations
      </h1>
      <div className={"flex flex-col gap-5"}>
        <span>
          <p className={"font-medium"}>
            La protection de la vie privée de nos utilisateurs est notre
            principale préoccupation.
            <br />
            Le modèle économique Flatway repose pour l’essentiel sur les frais
            de services payés par les professionnels de l'immobilier.
          </p>
          <ul className={"list-inside my-2 text-sm"}>
            <li>
              - Les documents de nos utilisateurs ne sont ni stockés dans nos
              systèmes ni partagés avec les professionnels
            </li>
            <li>- Nous ne vendons pas les données de nos utilisateurs</li>
            <li>
              - Nos services sont conçus dans le respect des exigences en
              matière de confidentialité énoncées par les réglementations
              nationales et européennes
            </li>
            <li>
              - Nos mesures de sécurité intègrent des techniques de chiffrement
              des données
            </li>
            <li>- Nos données sont hébergées en France</li>
          </ul>
        </span>
        <span>
          <h1 className={"font-bold"}>Données de compte</h1>
          <p>
            Les informations de compte sont exclusivement accessibles au
            professionnel responsable de la vente et ne seront en aucun cas
            partagées avec d'autres professionnels.
          </p>
        </span>
        <span>
          <h1 className={"font-bold"}>Documents scannés :</h1>
          <ul className={"text-sm ml-2 flex flex-col gap-2 my-2"}>
            <li>
              <h2 className={"font-bold"}>Pour établir le mandat de vente</h2>
              <p>
                {" "}
                Les documents de nos utilisateurs vendeurs ne seront ni archivés
                dans nos systèmes ni partagés avec le professionnel choisi pour
                la vente. Les données extraites des scans sont exclusivement
                utilisées pour établir le mandat de vente.
                <br />
                Le professionnel choisi pour la vente est libre de demander
                ensuite à l’utilisateur vendeur tous les documents nécessaires à
                la vente.
              </p>
            </li>
            <li>
              <h2 className={"font-bold"}>Pour visiter les biens</h2>
              <p>
                Les documents de nos utilisateurs acheteurs pour la visite ne
                seront ni archivés dans nos systèmes ni partagés avec les
                professionnels.
              </p>
            </li>
          </ul>
          <p>
            Nous nous engageons à conserver les données de nos utilisateurs
            acheteurs et vendeurs de manière confidentielle et sécurisée.
          </p>
        </span>
        <span>
          <h1 className={"font-bold"}>Adresses des biens en vente</h1>
          <p>
            À l'exception du professionnel responsable de la vente, les adresses
            des biens en vente ne seront divulguées à quiconque ; seuls les
            points de rendez-vous pour les visites seront communiqués aux
            acheteurs.
          </p>
        </span>
      </div>
    </section>
  );
};
