import { DefaultPageTemplate } from "../utils/utils.components";
import esti from "../assets/estimation steven 1.jpg";
import image from "../assets/Paris_IA.jpg";
import { useNavigate } from "react-router-dom";

/**
 * @name news
 * @description List des articles de presse (titre, image, date, description)
 */
export const news = [
  {
    title:
      "Flatway, le nouveau médiateur en matière d’achats et ventes en immobilier.",
    image: image,
    date: "19/03/2024",
    desc:
      "Flatway, la première application 100 % digitale à destination des particuliers et des\n" +
      "professionnels de l’immobilier.",
    id: 1,
  },
  {
    title: "L’estimation immobilière instantanée",
    image: esti,
    date: "19/03/2024",
    id: 2,
  },
];

/**
 * @name NewsPage
 * @description Page des articles de presse
 * @constructor
 */
const NewsPage = () => {
  return (
    <DefaultPageTemplate>
      <div className={"h-auto relative"}>
        <header
          className={"h-[70vh] bg-Paris bg-no-repeat bg-cover bg-center w-full"}
        >
          <div
            className={
              "w-full h-full bg-[rgba(0,0,0,0.2)] backdrop-blur-sm flex items-center justify-center"
            }
          >
            <h1 className={"text-2xl sm:text-7xl font-bold text-white"}>
              On en parle
            </h1>
          </div>
        </header>
        <main
          className={
            "h-auto md:h-[100vh] w-full py-10 flex flex-wrap gap-10 px-[20%]"
          }
        >
          {news.map((item, index) => (
            <PressSection
              title={item.title}
              image={item.image}
              date={item.date}
              key={index}
              id={item.id}
            />
          ))}
        </main>
      </div>
    </DefaultPageTemplate>
  );
};

/**
 * @name PressSection
 * @description Affichage d'un article de presse
 * @param title
 * @param image
 * @param date
 * @param id
 * @constructor
 */
const PressSection = ({
  title,
  image,
  date,
  id,
}: {
  title: string;
  image: string;
  date: string;
  id: number;
}) => {
  const navigate = useNavigate();

  return (
    <div className={"h-[500px] w-[350px] flex flex-col gap-10"}>
      <header className={"h-[50%] w-full bg-slate-50"}>
        <img alt={image} src={image} className={"object-cover h-full w-full"} />
      </header>
      <main className={"font-semibold"}>
        <p>{title}</p>
        <p className={"text-[#737373]"}>{date}</p>
      </main>
      <button
        onClick={() => {
          window.scroll(0, 0);
          navigate(`/article/${id}`);
        }}
        className={
          "w-fit bg-dark-red px-2 py-1 rounded-lg text-white font-medium"
        }
      >
        En savoir plus
      </button>
    </div>
  );
};

export default NewsPage;
