import React, { useRef, useState } from "react";
import { ProForm } from "../interfaces/FlatWayPro.interface";

/**
 * @name useProForm
 * @description Custom hook pour le formulaire de contact
 */
export function useProForm() {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [error, setError] = useState("");

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(formRef.current!);

    const formObject = {
      firstname: formData.get("Firstname") as string,
      lastname: formData.get("Lastname") as string,
      phone_nb: formData.get("Phone") as string,
      cp: formData.get("Zip") as string,
      mail: formData.get("Email") as string,
      occupation: formData.get("Job") as string,
      companyname: formData.get("Entreprise") as string,
      topic: formData.get("Sujet") as string,
    };

    if (!checkForm(formObject)) {
      return;
    }

    setIsLoading(true);
    const url = `https://${process.env.REACT_APP_API_URL}/api/support/contact/create`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formObject),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }
  function isFrenchPhoneNumber(phoneNumber: string): boolean {
    const frenchPhoneNumberRegex = /^(?:(?:\+|00)33|0)6\d{8}$/;
    return frenchPhoneNumberRegex.test(phoneNumber);
  }

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function checkForm(data: ProForm): boolean {
    if (!isFrenchPhoneNumber(data.phone_nb)) {
      setError("Le numéro de téléphone n'est pas valide");
      return false;
    }
    if (!isValidEmail(data.mail)) {
      setError("L'adresse email n'est pas valide");
      return false;
    }
    return true;
  }

  return { formRef, onSubmit, error, isLoading };
}
