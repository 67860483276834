import React from "react";
import Navbar from "../components/navbar";
import NoPageImg from "../assets/NoPage.svg";
import Footers from "../utils/Footers";

/**
 * @name NoPage
 * @description Page 404
 * @constructor
 */
const NoPage = () => {
  return (
    <div className={"h-[100vh]"}>
      <Navbar mod={false} />
      <div className={"h-full w-full flex justify-center items-center"}>
        <span className={"w-[25%]"}>
          <h1 className={"font-bold text-[24px]"}>Tu es perdu ?</h1>
          <h2>
            Tu m'as l'air perdu click sur le logo en haut a gauche pour
            retrouver ton chemin.
          </h2>
        </span>
        <img src={NoPageImg} alt={"IMG"} className={"h-[40%]"} />
      </div>

      <Footers />
    </div>
  );
};

export default NoPage;
