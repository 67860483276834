import { news } from "./News";
import { useParams } from "react-router-dom";
import { DefaultPageTemplate } from "../utils/utils.components";
import { NewId1, NewId2 } from "../components/News";

/**
 * @name Article
 * @description Page Article
 * @constructor
 */
const Article = () => {
  const { id } = useParams();
  if (!id) return <div>Article non trouvé</div>;
  const article = news.find((item) => item.id === parseInt(id));

  if (!article) {
    return <div>Article non trouvé</div>;
  }

  /**
   * @name newsList
   * @description Liste des articles
   */
  const newsList = [<NewId1 />, <NewId2 />];

  return (
    <DefaultPageTemplate>
      <header
        className={
          "flex items-end h-[70vh] justify-center bg-darkRedRgba overflow-hidden relative"
        }
      >
        <img
          src={article.image}
          alt={`${article.title} bg`}
          className={"w-full object-contain object-[0_200px] absolute -z-10"}
        />
        <div
          className={
            "w-[60%] px-10 py-10 items-center justify-between flex bg-white"
          }
        >
          <span className={"w-[70%]"}>
            <p className={"text-[#737373] font-semibold"}>{article.date}</p>
            <h2 className={"text-darkRedTitle text-3xl font-bold"}>
              {article.title}
            </h2>
          </span>
          <aside className={"h-[60%] w-[30%]"}>
            <img
              src={article.image}
              alt={article.title}
              className={"object-cover h-full w-full"}
            />
          </aside>
        </div>
      </header>
      <main className={"px-[25%] pb-10"}>
        <h3 className={"text-xl font-semibold mb-5"}>{article.desc}</h3>
        <section className={"flex flex-col gap-5 pr-[15%]"}>
          {newsList[parseInt(id) - 1]}
        </section>
      </main>
    </DefaultPageTemplate>
  );
};

export default Article;
