import PDV from "../../assets/4 - vendre/Bareme partenaires.png";
import { PiArrowRightLight, PiPathDuotone } from "react-icons/pi";
import { MdCompareArrows, MdUpgrade } from "react-icons/md";
import React, { useState } from "react";
import choosePro from "../../assets/4 - vendre/Liste-partenaires.png";
import enregistrement from "../../assets/4 - vendre/Enregistrement vente.png";
import { useNavigate } from "react-router-dom";

const explicationCardmap = [
  {
    icon: <PiPathDuotone className={"text-[25px] sm:text-[30px]"} />,
    title: "Vendre",
    text: "Sans se déplacer \n",
  },
  {
    icon: <MdCompareArrows className="text-[25px] sm:text-[30px]" />,
    title: "Comparer",
    text: "Les honoraires des professionnels",
  },
  {
    icon: <MdUpgrade className={"text-[25px] sm:text-[30px]"} />,
    title: "Optimiser",
    text: "Vos coûts",
  },
];

/**
 * @name Vente
 * @description Parent des sections de la page de vente
 * @constructor
 */
const Vente = () => {
  const [sectionHover, setSectionHover] = useState<number>(0);

  return (
    <div
      className={
        "h-auto lg:h-[100vh] pt-[70px] w-full bg-no-repeat bg-center bg-cover relative overflow-y-hidden flex items-center justify-center"
      }
    >
      <div
        className={
          "rounded-customBlobTriangle3 z-0 w-[30%] h-[30%] bg-light-red blur-3xl absolute -top-40 left-1/2"
        }
      />
      <div
        className={
          "rounded-customBlobTriangle3 z-0 w-[70%] h-[30%] bg-light-red blur-3xl absolute -bottom-32"
        }
      />
      <div
        className={
          "h-[50vh] w-[50vh] rounded-full bg-light-red blur-3xl absolute -left-[20vw]"
        }
      />
      <main
        className={
          "xl:w-[80vw] w-full z-10 h-auto lg:h-[90vh] overflow-hidden gap-4 grid lg:grid-rows-2 lg:grid-flow-col py-5 px-5 lg:px-[5vw] xl:px-[7vw]"
        }
      >
        <SectionOne
          sectionHover={sectionHover}
          setSectionHover={setSectionHover}
        />
        <SectionTwo
          sectionHover={sectionHover}
          setSectionHover={setSectionHover}
        />
        <SectionThree
          sectionHover={sectionHover}
          setSectionHover={setSectionHover}
        />
        <SectionFor
          sectionHover={sectionHover}
          setSectionHover={setSectionHover}
        />
      </main>
    </div>
  );
};

/**
 * @name SectionProps
 * @description Props des sections de la page de vente
 */
interface SectionProps {
  sectionHover: number;
  setSectionHover: (args: number) => void;
}

/**
 * @name SectionOne
 * @description Première section de la page de vente
 * @param sectionHover
 * @param setSectionHover
 * @constructor
 */
const SectionOne: React.FC<SectionProps> = ({
  sectionHover,
  setSectionHover,
}) => {
  return (
    <section
      onMouseEnter={() => setSectionHover(0)}
      className={`h-[80vh] lg:h-auto w-full transition-all duration-300 bg-pdv bg-no-repeat bg-left bg-cover
          row-span-2 rounded-xl`}
    >
      <div
        className={`h-full transition-all duration-300 ${
          sectionHover === 0
            ? " bg-darkRedRgba backdrop-blur-sm"
            : "bg-slate-50"
        }  px-10 py-5 gap-10 flex flex-col rounded-xl items-center justify-center`}
      >
        <header className={"h-[10%] flex flex-col justify-between"}>
          <h1
            className={`text-lg xl:text-xl 2xl:text-2xl font-bold ${
              sectionHover === 0 ? "text-white" : "text-darkRedTitle"
            } flex flex-col items-center`}
          >
            Vendre en 4 étapes
          </h1>
          <p
            className={`font-semibold lg:text-base text-sm ${
              sectionHover === 0 ? "text-white" : "text-[#737373]"
            } `}
          >
            <span
              className={`text-xl ${
                sectionHover === 0 ? "" : "text-dark-red"
              } font-bold`}
            >
              1
            </span>{" "}
            Choisir un professionnel
          </p>
        </header>
        <span className={"h-[65vh] flex items-center"}>
          <img
            src={choosePro}
            className={"object-scale-down h-full float-end drop-shadow-xl"}
            alt={"choisir un professionnel"}
          />
        </span>
      </div>
    </section>
  );
};

/**
 * @name SectionTwo
 * @description Deuxième section de la page de vente
 * @param sectionHover
 * @param setSectionHover
 * @constructor
 */
const SectionTwo: React.FC<SectionProps> = ({
  sectionHover,
  setSectionHover,
}) => {
  return (
    <section
      onMouseEnter={() => setSectionHover(1)}
      className={`h-auto w-full transition-all duration-300 bg-pdv bg-no-repeat bg-left bg-cover
          rounded-xl`}
    >
      <div
        className={`h-full transition-all duration-300 ${
          sectionHover === 1
            ? " bg-darkRedRgba backdrop-blur-sm"
            : "bg-slate-50"
        }  px-10 py-5 gap-5 flex flex-col rounded-xl items-center justify-center`}
      >
        <p
          className={`font-semibold lg:text-base text-sm ${
            sectionHover === 1 ? "text-white" : "text-[#737373]"
          } flex items-center h-[10%]`}
        >
          <span
            className={`text-xl ${
              sectionHover === 1 ? "" : "text-dark-red"
            } font-bold mr-1`}
          >
            2
          </span>{" "}
          Choisir le type d’honoraires
        </p>
        <span
          className={
            "h-[300px] w-[250px] flex md:items-center overflow-y-hidden"
          }
        >
          <img
            src={PDV}
            className={"object-cover w-full drop-shadow-xl"}
            alt={"choisir le type d'honoraires"}
          />
        </span>
      </div>
    </section>
  );
};

/**
 * @name SectionThree
 * @description Troisième section de la page de vente
 * @param sectionHover
 * @param setSectionHover
 * @constructor
 */
const SectionThree: React.FC<SectionProps> = ({
  sectionHover,
  setSectionHover,
}) => {
  const navigate = useNavigate();

  return (
    <section
      onMouseEnter={() => setSectionHover(2)}
      className={`h-auto w-full transition-all duration-300 bg-pdv bg-no-repeat bg-left bg-cover
          rounded-xl`}
    >
      <div
        className={`h-full transition-all duration-300 ${
          sectionHover === 2
            ? " bg-darkRedRgba backdrop-blur-sm"
            : "bg-slate-50"
        }  px-10 py-5 gap-10 flex flex-col rounded-xl items-center justify-center`}
      >
        <div className={"flex h-[70%] flex-col justify-evenly w-full"}>
          {explicationCardmap.map(({ title, text }, index) => {
            return (
              <div
                key={index}
                className={`${
                  sectionHover === 2 ? "text-white" : ""
                } flex items-center`}
              >
                <span className={"w-[10%]"}>
                  <PiArrowRightLight className={"text-xl"} />
                </span>{" "}
                <p>
                  <span className={"font-bold"}>{title}</span>
                  <br />
                  <span className={"text-xs"}>{text}</span>
                </p>
              </div>
            );
          })}
        </div>
        <span className={"h-[30%]"}>
          <button
            onClick={() => {
              window.scroll(0, 0);
              navigate("/vente");
            }}
            className={
              "font-bold text-white w-fit h-fit bg-dark-red px-4 py-2 rounded-lg"
            }
          >
            Vendre
          </button>
        </span>
      </div>
    </section>
  );
};

/**
 * @name SectionFor
 * @description Quatrième section de la page de vente
 * @param sectionHover
 * @param setSectionHover
 * @constructor
 */
const SectionFor: React.FC<SectionProps> = ({
  sectionHover,
  setSectionHover,
}) => {
  return (
    <section
      onMouseEnter={() => setSectionHover(3)}
      className={`h-auto w-full transition-all duration-300 bg-pdv bg-no-repeat bg-left bg-cover
          row-span-2 rounded-xl`}
    >
      <div
        className={`h-full transition-all duration-300 ${
          sectionHover === 3
            ? " bg-darkRedRgba backdrop-blur-sm"
            : "bg-slate-50"
        }  px-10 py-5 gap-5 flex flex-col rounded-xl items-center justify-center`}
      >
        <p
          className={`font-semibold lg:text-base text-sm ${
            sectionHover === 3 ? "text-white" : "text-[#737373]"
          } h-[30%] items-end flex`}
        >
          <span
            className={`text-xl ${
              sectionHover === 3 ? "" : "text-dark-red"
            } font-bold mr-1`}
          >
            3
          </span>{" "}
          Enregistrer l’annonce
        </p>
        <span className={"h-[65vh] flex items-center"}>
          <img
            src={enregistrement}
            className={"object-scale-down h-full float-end drop-shadow-xl"}
            alt={"enregistrer l'annonce"}
          />
        </span>
        <p
          className={`font-semibold lg:text-base text-sm ${
            sectionHover === 3 ? "text-white" : "text-[#737373]"
          } h-[30%]`}
        >
          <span
            className={`text-xl ${
              sectionHover === 3 ? "" : "text-dark-red"
            } font-bold`}
          >
            4
          </span>{" "}
          Signer le mandat
        </p>
      </div>
    </section>
  );
};
export default Vente;
