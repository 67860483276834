import React from "react";

/**
 * @name Modal
 * @description Template pour modal
 * @param isOpen boolean qui gere l'ouverture du modal
 * @param children composant enfant
 * @param setIsOpen fonction qui change la valeur de isOpen
 * @constructor
 */
function Modal({
  isOpen,
  children,
  setIsOpen,
}: {
  isOpen: boolean;
  children: any;
  setIsOpen: (arg: boolean) => void;
}) {
  return (
    <div
      className={`h-full w-full fixed z-30 inset-0 overflow-y-auto ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      style={{ transition: "opacity 0.3s", zIndex: 100000 }}
    >
      <div className="h-full w-full flex items-center justify-center min-h-screen text-center">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => setIsOpen(false)}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        <div
          className={`flex items-center justify-center  h-full w-full rounded-lg transform transition-all duration-300 ease-in-out ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
