import React, { useState } from "react";
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
} from "react-icons/io";
import Logo from "../../../assets/appIcon.png";

/**
 * @name Slider
 * @description Composant de slider
 * @param pictures
 * @param logo_pa
 * @constructor
 */
function Slider({
  pictures,
  logo_pa,
}: {
  pictures: string[];
  logo_pa: string;
}) {
  const [index, setIndex] = useState(0);
  const [hover, setHover] = useState(false);

  const handleSlide = (newIndex: number) => {
    if (newIndex >= pictures.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = pictures.length - 1;
    }
    setIndex(newIndex);
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="relative h-full w-full flex items-center overflow-hidden rounded-t-lg justify-center"
    >
      {logo_pa !== "" && (
        <img
          onError={(e) => {
            e.currentTarget.src = Logo;
          }}
          src={logo_pa}
          className="absolute z-30 top-0 left-0 h-[70px] w-[70px] object-cover"
          alt="Logo"
        />
      )}
      <div
        className={`h-full w-full flex items-center flex-row`}
        style={{
          transform: `translateX(-${index * 100}%)`,
          transition: "transform 0.5s ease-in-out",
        }}
      >
        {pictures.map((item, idx) => (
          <img
            key={idx}
            src={item}
            className="object-cover h-full w-full"
            alt={`Slide ${idx + 1}`}
          />
        ))}
      </div>
      {hover && (
        <div className="absolute h-full w-full flex justify-between p-2">
          <button
            onClick={() => handleSlide(index - 1)}
            className="hover:scale-110"
          >
            <IoIosArrowDropleftCircle className="text-white text-[27px]" />
          </button>
          <button
            onClick={() => handleSlide(index + 1)}
            className="hover:scale-110"
          >
            <IoIosArrowDroprightCircle className="text-white text-[27px]" />
          </button>
        </div>
      )}
    </div>
  );
}

export default Slider;
