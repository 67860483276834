import React, { Dispatch, SetStateAction } from "react";
import { handleChangeCity } from "./Consultation.utils";

/**
 * @name extractParams
 * @description Extrait les paramètres de l'url pour la vente
 */
export function extractParams() {
  const params = new URLSearchParams(window.location.search);
  const city = params.get("city");
  const zipcode = params.get("cp");
  const index = params.get("index");

  return city && zipcode && index ? { city, zipcode, index } : null;
}

/**
 * @name handleChangeSell
 * @description Gere le changement de l'input de la vente
 * @param e Html input event
 * @param setCityList Gere le status de la liste des villes
 * @param userInput User input
 * @param setUserInput Gere le status de l'input utilisateur
 */
export const handleChangeSell = async (
  e: React.ChangeEvent<HTMLInputElement>,
  setCityList: Dispatch<SetStateAction<never[]>>,
  userInput: string,
  setUserInput: React.Dispatch<React.SetStateAction<string>>,
) => {
  const input = e.target.value;
  setUserInput(input);
  await handleChangeCity(userInput, setCityList);
};
