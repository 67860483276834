import { FlatWayProComponentPhone } from "../components/FlatWay/Mobile/FlatWayPro.component.phone";
import React from "react";
import { DefaultPageTemplate } from "../utils/utils.components";

/**
 * @name FlatwayProPhone
 * @description Page FlatwayPro pour telephone
 * @constructor
 */
export const FlatwayProPhone = () => {
  const sectionsPhone = [
    "Fonctionnement FlatWayPro",
    "Devenir Partenaire",
    "La visite des biens",
    "Offres d’achat-vente",
  ];

  return (
    <DefaultPageTemplate>
      <div className={"flex flex-col gap-10"}>
        {sectionsPhone.map((title, index) => (
          <FlatWayProComponentPhone key={index} title={title} index={index} />
        ))}
      </div>
    </DefaultPageTemplate>
  );
};
