import Navbar from "../components/navbar";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatDateFull } from "../utils/format";

/**
 * @name CreatePassword
 * @description Page de création de mot de passe pour les commerciaux
 * @constructor
 */
const CreatePassword = () => {
  const [password, setPassword] = React.useState("");
  const [tmpOrNot, setTmpOrNot] = React.useState(false);
  const navigate = useNavigate();
  const date = new Date();
  date.setDate(date.getDate() + 7);

  /**
   * @name handleSubmit
   * @description Envoi du mot de passe (en cas de probleme redeployer le script google)
   */
  const handleSubmit = async () => {
    const form = new FormData();

    form.append("Password", password);
    form.append("Date", tmpOrNot ? formatDateFull(date.toISOString()) : "");

    await fetch(
      "https://script.google.com/macros/s/AKfycbwB-GyH8QCpuRHNsQ7GvjduCwqN97sBMd4NFrl5aGHdcIpAKw4fajLSzLftqUMq6WVsmg/exec",
      { method: "POST", body: form },
    )
      .then((response) => {
        console.log("response: ", response);
        if (response.ok) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Navbar mod={true} />
      <main className={"w-full h-screen flex justify-center items-center"}>
        <section className={"w-[30vw] flex flex-col gap-4"}>
          <input
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name={"password"}
            placeholder={"Renseigner le mot de passe"}
            className={"input"}
          />
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={tmpOrNot}
              onChange={(e) => setTmpOrNot(e.target.checked)}
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-dark-red"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Mot de passe temporaire
            </span>
          </label>
          <div className={"w-full flex justify-center"}>
            <button onClick={handleSubmit} className={"button"}>
              Valider
            </button>
          </div>
        </section>
      </main>
    </div>
  );
};

export default CreatePassword;
