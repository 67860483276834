import { useEffect, useRef, useState } from "react";
import { sellcityInfo } from "../interfaces/interface";
import BaremeAPI from "../API/Bareme.API";
import { extractParams } from "../utils/Vendre.utils";
import { PartnerAgent } from "../interfaces/Vendre";

/**
 * @name useVendreHeader
 * @description Custom hook for Vendre Header
 * @param setData
 */
export function useVendreHeader(
  setData: React.Dispatch<React.SetStateAction<PartnerAgent[] | undefined>>,
) {
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectCity, setSelectCity] = useState<sellcityInfo>();

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchBarRef.current &&
      !searchBarRef.current.contains(event.target as Node)
    ) {
      setFocus(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePartnerSearch = async () => {
    const url = `https://${process.env.REACT_APP_API_URL}/api/partner/partneragentbaremeW?zipcode=${selectCity?.zipcode}`;
    console.log(selectCity);
    try {
      const data = await BaremeAPI(url);
      setData(data.PARTNERAGENT);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const params = extractParams();
    if (params) {
      const { city, zipcode, index } = params;
      if (city && zipcode && index) {
        setSelectCity({
          name: city,
          zipcode: zipcode,
          index: parseInt(index),
          statusdeploy: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (selectCity) {
      handlePartnerSearch();
    }
  }, [selectCity]);

  return {
    handlePartnerSearch,
    setSelectCity,
    searchBarRef,
    focus,
    setFocus,
    userInput,
    setUserInput,
    cityList,
    setCityList,
  };
}

/**
 * @name useVendreMain
 * @description Custom hook pour la page de vente
 * @param setPhoneFocus
 * @param PhoneFocus
 */
export function useVendreMain(
  setPhoneFocus: (args: boolean) => void,
  PhoneFocus: boolean,
) {
  const [clicked, setClicked] = useState<boolean>(false);
  const [selectPro, setSelectPro] = useState<number>();

  useEffect(() => {
    window.addEventListener("click", function (e) {
      const clickbox = document.getElementById("phoneFocus");
      const target = e.target as HTMLElement;

      if (clickbox && !clickbox.contains(target)) {
        setPhoneFocus(false);
      }
    });
  }, [PhoneFocus]);

  return {
    clicked,
    setClicked,
    selectPro,
    setSelectPro,
  };
}
