import React from "react";
import {
  FlatwaySection,
  FlatwaySectionTemplate,
} from "../../utils/FlatWay.utils";
import { FlatwaySectionId } from "../../interfaces/Flatway.sectionId";
import Acheter from "../../assets/Acheter 2.jpg";
import { SAlogo } from "../../utils/utils.components";
import { useNavigate } from "react-router-dom";

/**
 * @name FlatWayBuyComponent
 * @description Section d'achat de la page Flatway
 * @constructor
 */
const FlatWayBuyComponent = () => {
  const buyAdvantageContent = [
    "Acheter au juste prix",
    "Prendre rendez-vous en ligne pour visiter",
    "Consulter notre estimation des biens en vente",
    "Voir la liste des biens vendus autour de chaque bien en vente",
    "Localiser chaque bien en vente par le nom de la voie",
    "Gérer les rendez-vous et visites à partir de l’espace client",
  ];

  return (
    <div className={"sm:h-auto w-full pt-[80px]"} id={FlatwaySectionId.acheter}>
      <FlatwaySection
        title={"Flatway vous facilite l’achat d’un bien"}
        route={"/comment-acheter"}
        image={Acheter}
        list={buyAdvantageContent}
      />
    </div>
  );
};

/**
 * @name SectionOne
 * @description Section 1 de la page Flatway (Consultation des annonces)
 * @constructor
 */
const SectionOne = () => {
  return (
    <FlatwaySectionTemplate
      title={"Consultation des annonces"}
      description={
        "Découvrir les biens à vendre en mode liste ou sur la carte."
      }
    >
      <ul className={"list-decimal ml-4 flex flex-col gap-1"}>
        <li>
          Pour localiser les biens en vente, le nom de la voie est affichée dans
          chaque annonce
        </li>
        <li>
          Certains biens sont en vente par plusieurs professionnels, les
          annonces restent identiques exception faite du prix de vente final qui
          varie en fonction des différents honoraires
        </li>
        <li>
          Les biens en vente avec des honoraires à taux réduits sont signalés
          par le{" "}
          <span>
            symbole <SAlogo />{" "}
          </span>
        </li>
        <li>
          Chaque annonce inclut les coordonnées du professionnel et ses
          honoraires
        </li>
      </ul>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name SectionTwo
 * @description Section 2 de la page Flatway (Estimation des biens en vente)
 * @constructor
 */
const SectionTwo = () => {
  return (
    <FlatwaySectionTemplate
      title={"Estimation des biens en vente"}
      description={
        "Indépendamment des prix de vente indiqués, Flatway vous fournit une estimation objective de chaque bien en vente."
      }
    >
      <>
        <p>
          Flatway vous fournit également la liste des biens vendus à l’adresse
          et à proximité de chaque bien en vente.
        </p>
        <p>
          Pour préserver l'anonymat des biens en vente nous n'affichons pas les
          numéros de voies dans la liste des biens vendus{" "}
        </p>
      </>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name SectionThree
 * @description Section 3 de la page Flatway (La visite des biens)
 * @constructor
 */
const SectionThree = () => {
  const navigate = useNavigate();

  return (
    <FlatwaySectionTemplate
      title={"La visite des biens"}
      description={
        "Depuis votre mobile, prendre rendez-vous en ligne et se laisser guider."
      }
    >
      <button
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("/offre-et-visite");
        }}
      >
        Voir notre rubrique dédiée à la visite{" "}
        <span className={"text-dark-red underline"}>en savoir plus</span>.
      </button>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name BuySections
 * @description Tableau des sections de la page Flatway
 */
export const BuySections = [<SectionOne />, <SectionTwo />, <SectionThree />];

export default FlatWayBuyComponent;
