import {
  FlatwaySellPhone,
  FlatwayIntroPhone,
  FlatwayPresePhone,
  FlatwayBuyComponentPhone,
  FlatwayOfferPhone,
  FlatwayVisitPhone,
} from "../components/FlatWay/Mobile/FlatwayPhone.components";
import FlatWayPartnerComponent from "../components/FlatWay/FlatWayPartner.Component";

/**
 * @name DisplayFlatwayPhone
 * @description Page Flatway pour telephone
 * @constructor
 */
const DisplayFlatwayPhone = () => {
  const components = [
    <FlatwayIntroPhone />,
    <FlatwayPresePhone />,
    <FlatwaySellPhone />,
    <FlatwayBuyComponentPhone />,
    <FlatwayVisitPhone />,
    <FlatwayOfferPhone />,
    <FlatWayPartnerComponent />,
  ];
  return (
    <div className={"flex flex-col gap-10"}>
      {components.map((items, index) => (
        <div
          key={index}
          className={
            "h-auto w-full flex sm:items-center flex-col sm:px-0 px-2 font-medium"
          }
        >
          {items}
        </div>
      ))}
    </div>
  );
};

/**
 * @name FlatwayPhone
 * @description Page Flatway pour telephone
 * @constructor
 */
const FlatwayPhone = () => {
  return (
    <div className={"h-auto w-full overflow-hidden"}>
      <DisplayFlatwayPhone />
    </div>
  );
};

export default FlatwayPhone;
