import { BsBarChartFill } from "react-icons/bs";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosBatteryFull,
  IoIosWifi,
} from "react-icons/io";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/appIcon.png";
import { FaCheck } from "react-icons/fa";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SectionIdEnum } from "../Home-component/types/section-id";
import { PartnerAgent } from "../../interfaces/Vendre";
import { price_format } from "../../utils/format";
import { useVendreMain } from "../../hooks/Vendre.hooks";

/**
 * @name PhoneHeader
 * @description Header du téléphone
 * @param setPhoneFocus
 * @constructor
 */
const PhoneHeader = ({
  setPhoneFocus,
}: {
  setPhoneFocus: (args: boolean) => void;
}) => {
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <header
      style={{ boxShadow: "0 4px 4px -4px gray" }}
      className={"pt-4 px-4 h-[15%] w-full"}
    >
      <div className={"text-clampPhone mx-[1vw] flex items-center h-[40%]"}>
        <ol className={"flex items-center w-full justify-between"}>
          {date ? (
            <li className={"font-bold cursor-default"}>
              {date.getHours()}:{date.getMinutes().toString().padStart(2, "0")}
            </li>
          ) : (
            <div className={"font-bold cursor-default"}>00:00</div>
          )}
          <ol className={"flex gap-1"}>
            <li>
              <BsBarChartFill />
            </li>
            <li>
              <IoIosWifi />
            </li>
            <li>
              <IoIosBatteryFull />
            </li>
          </ol>
        </ol>
      </div>
      <div className={"w-full flex justify-between h-[60%] items-center px-2"}>
        <IoIosArrowBack
          className={"text-clampPhone"}
          onClick={() => {
            setPhoneFocus(false);
          }}
        />
        <p className={"text-clampPhone cursor-default text-start"}>
          Liste des partenaires
        </p>
        <span className={"w-[20px]"}></span>
      </div>
    </header>
  );
};

/**
 * @name DisplaySeller
 * @description Affichage des partenaires avec leurs honoraires
 * @param data
 * @param selectPro Pro sélectionné
 * @param setSelectPro Setter du pro sélectionné
 * @constructor
 */
const DisplaySeller = ({
  data,
  selectPro,
  setSelectPro,
}: {
  data: PartnerAgent[];
  selectPro: number | undefined;
  setSelectPro: (args: number | undefined) => void;
}) => {
  const [open, setOpen] = useState<number[]>([]);

  return (
    <div
      className={
        "h-[65%] bg-slate-50 w-full flex flex-col overflow-y-auto py-2 gap-2"
      }
    >
      {data.map((items, index) => {
        const adress = items.fulladdress.split(",");
        const city = adress[1].split(" ");
        return (
          <div className={"h-auto w-full"}>
            <button
              onClick={() => {
                if (open.includes(index)) {
                  setOpen(open.filter((item) => item !== index));
                } else setOpen([...open, index]);
              }}
              className={"h-[10vh] w-full bg-white p-1"}
              key={index}
            >
              <div className={"h-[80%] w-full flex"}>
                <div className={"w-[30%]"}>
                  <img
                    onError={(e) => {
                      e.currentTarget.src = Logo;
                    }}
                    src={items.logo ? items.logo : Logo}
                    className={"object-contain h-full w-full"}
                    alt={"logo"}
                  />
                </div>
                <aside
                  className={
                    "text-start h-full w-[70%] flex flex-col justify-center relative"
                  }
                >
                  <button
                    onClick={() => {
                      if (selectPro === index) setSelectPro(undefined);
                      else setSelectPro(index);
                    }}
                    className={`h-[1.5vh] w-[1.5vh] flex items-center absolute top-1 right-1 justify-center rounded-full border border-black ${
                      selectPro === index ? "bg-dark-red" : "bg-white"
                    }`}
                  >
                    <FaCheck className={"text-white text-[0.6rem]"} />
                  </button>
                  <p
                    className={
                      "text-start text-[0.6rem] font-bold leading-tight text-dark-red flex-col flex justify-between"
                    }
                  >
                    {items.commercialname}
                  </p>
                  <p className={"text-[0.55rem] leading-tight text-black"}>
                    {adress[0]},<br /> {city[1]} {city[2]}
                  </p>
                </aside>
              </div>
              <div
                className={
                  "bg-slate-100 w-full text-dark-red h-[20%] flex items-center text-xs justify-center"
                }
              >
                <span className={"text-[0.6rem]"}>Voir honoraires</span>{" "}
                <IoIosArrowDown
                  className={`text-sm transition-all duration-300 ${
                    open.includes(index) ? "rotate-180" : ""
                  }`}
                />
              </div>
            </button>
            <section
              className={`${
                open.includes(index) ? "block" : "hidden"
              } w-full h-auto py-2 bg-white`}
            >
              <table className={"w-full text-center text-[0.6rem]"}>
                <thead className={"text-dark-red"}>
                  <tr>
                    <th className={"border"} scope={"col"} colSpan={2}></th>
                    <th className={"border"} scope={"col"}>
                      SANS
                    </th>
                    <th className={"border"} scope={"col"}>
                      AVEC
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.honoraires.tranches.map((tranche, index) => (
                    <tr key={index}>
                      <td className={"border"}>
                        {tranche.value1 === "min"
                          ? "Jusqu'à"
                          : tranche.value1 === "max"
                          ? "Supérieur"
                          : price_format(tranche.value1 as number)}
                      </td>
                      <td className={"border"}>
                        {price_format(tranche.value2 as number)}
                      </td>
                      <td className={"border"}>
                        {tranche.option1 !== "-1.0"
                          ? `${tranche.option1} %`
                          : ""}
                      </td>
                      <td className={"border"}>
                        {tranche.option2 !== "-1.0"
                          ? `${tranche.option2} %`
                          : ""}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </div>
        );
      })}
    </div>
  );
};

/**
 * @name PhoneDisplay
 * @description Affichage du téléphone
 * @param setPhoneFocus
 * @param PhoneFocus boolean (oui le telephone prend la page entière)
 * @param data
 * @constructor
 */
const PhoneDisplay = ({
  setPhoneFocus,
  PhoneFocus,
  data,
}: {
  setPhoneFocus: (args: boolean) => void;
  PhoneFocus: boolean;
  data: PartnerAgent[];
}) => {
  const { clicked, setClicked, selectPro, setSelectPro } = useVendreMain(
    setPhoneFocus,
    PhoneFocus,
  );

  return (
    <div
      className={`${
        PhoneFocus
          ? "relative h-full w-full flex items-center justify-center"
          : "h-auto w-auto"
      }`}
    >
      <div
        id={`${PhoneFocus ? "phoneFocus" : "phoneNoFocus"}`}
        className={`w-auto h-auto ${
          PhoneFocus
            ? "bg-white rounded-xl transition-all duration-300 scale-125 absolute"
            : "select-none lg:left-[60vw] top-[12vh]"
        } flex items-center justify-center`}
      >
        <div
          className={`${
            PhoneFocus ? "m-10" : ""
          } w-[272px] h-[552.5px] focus:outline-none bg-[url('./assets/phone_esti.png')] bg-contain`}
        >
          <main className={"h-full w-full relative"}>
            <span
              className={`absolute z-20 top-1/2 -right-[11vw] ${
                clicked ? "hidden" : "block"
              }`}
            >
              <div
                className={`h-fit min-w-[100px] w-[10vw] p-2 rounded-lg font-semibold bg-gray-700 text-clampPhoneLittle ${
                  PhoneFocus ? "hidden" : "block"
                }`}
              >
                <p className={"text-white"}>Intéragir avec cette écran</p>
                <button
                  onClick={() => {
                    setPhoneFocus(true);
                    setClicked(true);
                  }}
                  className={"bg-dark-red px-2 py-1 rounded-lg text-white"}
                >
                  Ok
                </button>
              </div>
            </span>
            <button
              onClick={() => setPhoneFocus(true)}
              className={`${
                PhoneFocus ? "hidden" : "block"
              } w-full h-[100%] z-10 absolute`}
            />
            <PhoneHeader setPhoneFocus={setPhoneFocus} />
            <section className={"px-5 h-full"}>
              <p
                className={
                  "h-[7%] px-2 flex items-center w-full text-[0.55rem]"
                }
              >
                Si un professionnel ne figure pas dans la liste, demandez-lui de
                télécharger l'application Flatway
              </p>
              <DisplaySeller
                data={data}
                selectPro={selectPro}
                setSelectPro={setSelectPro}
              />
              <div className={"w-full flex justify-center py-1"}>
                <AnchorLink
                  onClick={() => {
                    setPhoneFocus(false);
                  }}
                  href={`#${SectionIdEnum.telecharger}`}
                  className={`px-4 py-1.5 text-xs text-white rounded-xl ${
                    selectPro !== undefined
                      ? "bg-dark-red"
                      : "pointer-events-none bg-light-red cursor-default select-none"
                  }`}
                >
                  Valider
                </AnchorLink>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};
export default PhoneDisplay;
