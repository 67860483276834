import React, { useState } from "react";
import {
  DisplayRoadMapSection,
  FlatwaySectionTemplate,
} from "../../utils/FlatWay.utils";
import buyer from "../../assets/5 - offres/Offre-acheteur.png";
import seller from "../../assets/5 - offres/Offre-vendeur.png";
import { IoIosArrowForward } from "react-icons/io";
import Demande from "../../assets/offer/offer.png";

/**
 * @name SectionOne
 * @description Section 1 de la roadmap des offres
 * @constructor
 */
const SectionOne = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FlatwaySectionTemplate
      title={"L'acheteur"}
      description={
        "Depuis son espace formule une offre et renseigne son apport personnel et sa capacité d’emprunt."
      }
    >
      <>
        <p>
          L’offre est placée dans les espaces dédiés des utilisateurs, le
          vendeur et le professionnel sont informés.
        </p>
        <ul className={"list-decimal ml-4"}>
          <li>
            L'apport personnel plus la capacité d'emprunt doivent couvrir
            l'offre d'achat et les frais de notaire.
          </li>
          <li>
            {" "}
            Le vendeur peut demander à l’acheteur, via le professionnel, une
            attestation de capacité d'emprunt.
          </li>
        </ul>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={"font-bold flex items-center pr-5 justify-between w-full"}
        >
          Capacité d'emprunt, ce qu’il faut savoir
          <IoIosArrowForward
            className={`transition-all text-base duration-300 ${
              isOpen ? "rotate-90" : ""
            }`}
          />
        </button>
        <p className={`${isOpen ? "block" : "hidden"}`}>
          La capacité d'emprunt correspond au montant que l’acheteur peut
          emprunter en fonction des revenus récurrents, des charges mensuelles
          et de la mensualité à payer. L’acheteur peut obtenir une attestation
          de capacité d'emprunt en s’adressant à des banques ou des
          intermédiaires en opérations de banque.
        </p>
      </>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name SectionTwo
 * @description Section 2 de la roadmap des offres
 * @constructor
 */
const SectionTwo = () => {
  return (
    <FlatwaySectionTemplate
      title={"Le vendeur"}
      description={"Accepte l’offre, refuse ou formule une contre-offre."}
    >
      <p>
        À son tour, l’acheteur accepte, refuse ou formule une nouvelle offre.
        <br />
        In fine, si une offre est acceptée, le professionnel prend le relai et
        transmet le dossier au notaire.
      </p>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name SectionThree
 * @description Section 3 de la roadmap des offres
 * @constructor
 */
const SectionThree = () => {
  return (
    <FlatwaySectionTemplate
      title={"Le professionnel"}
      description={
        "Dispose d’un accès instantané aux offres et intervient pleinement comme conseil pour les parties."
      }
    >
      <>
        <p>
          Peut ajuster ses honoraires depuis son espace.
          <br />
        </p>
        <p className={"mt-2"}>
          Lorqu'une offre est acceptée, le partenaire transmet le dossier au
          notaire.
          <br />
          Jusqu'à ce que le professionnel transmette le dossier au notaire et
          informe Flatway, d'autres utilisateurs peuvent soumettre des offres
        </p>
      </>
    </FlatwaySectionTemplate>
  );
};

/**
 * @name FlatWayOfferComponent
 * @description Section des offres de la page flatway
 * @constructor
 */
function FlatWayOfferComponent() {
  const images = [Demande, seller, buyer];
  const sections = [<SectionOne />, <SectionTwo />, <SectionThree />];
  return (
    <DisplayRoadMapSection
      title={"Les offres"}
      images={images}
      sections={sections}
    />
  );
}

export default FlatWayOfferComponent;
