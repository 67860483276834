import { DefaultPageTemplate } from "../utils/utils.components";
import React from "react";
import Modal from "../utils/Modal";
import {
  AboutUsHeader,
  HistorySection,
  NumbersSection,
  ObjectifSection,
  PointSection,
  TeamSection,
} from "../components/AboutUs.component";

/**
 * @name AboutUs
 * @description Page A propos de nous
 * @constructor
 */
const AboutUs = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <DefaultPageTemplate>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div
          onClick={() => setIsOpen(false)}
          className={
            "h-[100vh] w-full flex items-center justify-center backdrop-blur"
          }
        >
          <iframe
            title={"video"}
            className={"h-[50%] w-[50%] object-cover"}
            allowFullScreen={true}
            src={"https://www.youtube.com/embed/fLIljVW4IcM?rel=0"}
          />
        </div>
      </Modal>
      <div
        className={"relative h-auto w-full flex flex-col items-center gap-10"}
      >
        <AboutUsHeader setIsOpen={setIsOpen} />
        <HistorySection />
        <ObjectifSection />
        <PointSection />
        <TeamSection />
        <NumbersSection />
      </div>
    </DefaultPageTemplate>
  );
};

export default AboutUs;
