import { DefaultPageTemplate } from "../../utils/utils.components";
import { DisplayRoadMapSection } from "../../utils/FlatWay.utils";
import React from "react";
import esti from "../../assets/1 - landing page/Landing4@2x.png";
import vendre3 from "../../assets/4 - vendre/Liste-partenaires.png";
import vendre1 from "../../assets/4 - vendre/Bareme partenaires.png";
import vendre2 from "../../assets/4 - vendre/Enregistrement vente.png";
import { BubbleList } from "../../components/FlatWay/FlatWay.Sell.Component";
import {
  GestionSections,
  HowToSellHeader,
  SecondSection,
} from "../../components/SubComponent/HowToSell.component";

/**
 * @name SellPage
 * @description Page explicative Comment vendre
 * @constructor
 */
const SellPage = () => {
  const images = [esti, vendre3, vendre1, vendre2];

  return (
    <DefaultPageTemplate>
      <HowToSellHeader />
      <main className={"flex flex-col items-center gap-10 justify-center"}>
        <section
          className={"w-full flex flex-col py-10 justify-center items-center"}
        >
          <DisplayRoadMapSection
            images={images}
            sections={BubbleList}
            title={"Comment vendre ?"}
          />
        </section>
        <SecondSection />
        <section
          className={"flex min-h-[300px] flex-col gap-4 items-center py-10"}
        >
          <h1 className={"font-bold text-xl md:text-3xl"}>
            Gestion des annonces
          </h1>
          <div className={"flex h-full"}>
            <ul className={"flex flex-col gap-16 lg:w-[1100px] lg:px-0 px-2"}>
              <GestionSections />
            </ul>
          </div>
        </section>
      </main>
    </DefaultPageTemplate>
  );
};

export default SellPage;
