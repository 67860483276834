import { PropertyData } from "../../interfaces/Annonce.interface";
import React from "react";
import { CaractIcon } from "../../interfaces/Caract.Icon";

/**
 * @name CaracteristicComponent
 * @description Composant d'affichage des caractéristiques du bien
 * @param annonceData
 * @param setCaractModal
 * @constructor
 */
export const CaracteristicComponent = ({
  annonceData,
  setCaractModal,
}: {
  annonceData: PropertyData;
  setCaractModal: (args: boolean) => void;
}) => {
  return (
    <div className={"w-full"}>
      <div className={"w-full"}>
        <h1 className={"text-base lg:text-2xl font-bold mb-5"}>
          Détail du bien
        </h1>
        <section className={"py-6 px-4"}>
          <div className={"flex flex-row justify-between"}>
            <div>
              <h1 className={"font-bold lg:text-base text-sm text-start"}>
                Composition
              </h1>
              {annonceData.compo.map(({ libelle_fr, quantité }, index) => {
                if (index < 3)
                  return (
                    <h2
                      key={libelle_fr}
                      className={"text-xs lg:text-base my-2"}
                    >
                      {quantité != 0 ? quantité : ""} {libelle_fr}
                    </h2>
                  );
                return null;
              })}
            </div>
            <div>
              {annonceData.compod.map((items, index) => {
                if (index < 4) {
                  let icon = CaractIcon.find(
                    (icon) => icon.title === items.libelle_fr,
                  );
                  return (
                    <h2
                      className={"text-[12px] sm:text-[15px] flex items-center"}
                      key={items.libelle_fr}
                    >
                      {icon?.icon}
                      {items.libelle_fr}
                    </h2>
                  );
                }
                return null;
              })}
            </div>
          </div>
          <button
            onClick={() => setCaractModal(true)}
            className={
              "bg-[#980000] rounded px-2 py-1 font-bold sm:font-medium text-xs lg:text-sm text-white"
            }
          >
            Afficher toute les caractéristiques
          </button>
        </section>
      </div>
    </div>
  );
};
