import {IoPeople} from "react-icons/io5";
import {IoIosTrendingUp, IoMdInformationCircleOutline} from "react-icons/io";
import {HiOutlinePencil} from "react-icons/hi";
import {MdOutlineCalendarMonth} from "react-icons/md";
import React from "react";

export interface ProForm {
  firstname: string;
  lastname: string;
  mail: string;
  phone_nb: string;
  occupation: string;
  companyname: string;
  cp: string;
  topic: string;
}


export const AdvantageContent = [
  {
    text: "Gagner une nouvelle clientèle",
    icon: <IoPeople className={"text-2xl"} />,
},
{
  text: "Optimiser les coûts de fonctionnement",
      icon: <IoIosTrendingUp className={"text-2xl"} />,
},
{
  text: "Signer électroniquement le mandat de vente sans exclusivité avec les vendeurs",
      icon: <HiOutlinePencil className={"text-2xl"} />,
},
{
  text: "Gérer les rendez-vous et visites à partir de l’espace dédié",
      icon: <MdOutlineCalendarMonth className={"text-2xl"} />,
},
{
  text: "Utiliser notre estimation et consulter la liste des biens vendus autour de chaque adresse",
      icon: <IoMdInformationCircleOutline className={"text-2xl"} />,
},
];
