import React from "react";
import Logo from "../assets/appIcon.png";
import { useNavigate } from "react-router-dom";

/**
 * @name Navbar
 * @param mod boolean (pour le fixer ou non en haut de la page)
 * @constructor
 */
const Navbar = ({ mod }: { mod: boolean }) => {
  const navigate = useNavigate();

  return (
    <nav
      className={`${
        mod ? "fixed" : ""
      } h-[70px] w-full z-30 backdrop-blur-[2px] bg-white shadow xl:px-2`}
    >
      <div className="h-full w-full flex justify between items-center px-[30px]">
        <img
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/");
          }}
          src={Logo}
          alt="logo"
          className="h-[50px] cursor-pointer drop-shadow-logo"
        />
      </div>
    </nav>
  );
};

export default Navbar;
