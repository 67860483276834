import React, { useEffect, useState } from "react";
import { FaPhone } from "react-icons/fa6";
import { Outlet } from "react-router-dom";
import { DefaultPageTemplate } from "../utils/utils.components";
import { AiOutlineMail } from "react-icons/ai";

/**
 * @name Contact
 * @description Page de contact (pas encore terminée, manque les calls et que la patron valide)
 * @constructor
 */
export const Contact = () => {
  const [filled, setFilled] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    updateFilledState();
  });

  const updateFilledState = () => {
    if (name && email && message) setFilled(true);
    else setFilled(false);
  };

  return (
    <DefaultPageTemplate>
      <main className={"h-screen bg-slate-50 flex w-full"}>
        <section
          className={"w-1/2 h-full flex flex-col items-center justify-center"}
        >
          <div className={"flex w-[500px] flex-col gap-10"}>
            <header>
              <h1 className={"font-medium text-6xl text-[#313131]"}>
                Contactez nous
              </h1>
              <h2 className={"font-medium text-[#737373]"}>
                par email, par téléphone, ou en remplissant le formulaire.
                <br />
                Nous serons ravis de vous aider.
              </h2>
            </header>
            <ul className={"flex flex-col gap-3 font-medium text-[#313131]"}>
              <li>
                <a
                  className={"underline text-dark-red"}
                  href={"tel:0147471385"}
                >
                  +33 1 47 47 13 85
                </a>
              </li>
              <li>
                <a
                  className={"underline text-dark-red"}
                  href={"mailto:contact@flatway.fr"}
                >
                  contact@flatway.fr
                </a>
              </li>
            </ul>
            <p className={"text-sm text-[#313131] font-medium"}>
              Chez FlatWay, nous mettons notre expertise à votre service pour
              concrétiser votre projet immobilier de la meilleure manière
              possible. Alors, n'hésitez plus, parlons-en et trouvons ensemble
              la solution la plus adaptée à vos besoins.
            </p>
          </div>
        </section>
        <aside className={"w-1/2 h-full flex items-center justify-center"}>
          <form
            className={
              "h-3/4 w-3/5 p-4 bg-white flex flex-col justify-evenly rounded-2xl drop-shadow-2xl"
            }
          >
            <label className={"text-3xl font-medium"}>Besoin d'aide ?</label>
            <fieldset className={"w-full grid grid-cols-2 grid-rows-4 gap-4"}>
              <input
                placeholder={"Prénom"}
                className="border-2 border-slate-50 rounded-full px-2 outline-none focus:border-dark-red transition-all duration-300"
              />
              <input
                placeholder={"Nom"}
                className="border-2 border-slate-50 rounded-full px-2 outline-none focus:border-dark-red transition-all duration-300"
              />
              <span className="focus-within:border-dark-red border-2 flex items-center gap-2 w-full col-span-2 border-slate-50 rounded-full px-2 focus:outline-none focus:border-dark-red transition-all duration-300">
                <AiOutlineMail className={"text-2xl text-dark-red"} />
                <input
                  placeholder={"Email"}
                  className="h-10 w-full border-none bg-transparent outline-none col-span-2 transition-all duration-300 focus:border-[#980000] focus:outline-none"
                />
              </span>
              <span className="focus-within:border-dark-red border-2 flex items-center gap-2 w-full col-span-2 border-slate-50 rounded-full px-2 focus:outline-none focus:border-dark-red transition-all duration-300">
                <FaPhone className={"text-2xl text-dark-red"} />
                <input
                  placeholder={"Numéro de téléphone"}
                  className="h-10 w-full border-none bg-transparent outline-none col-span-2 transition-all duration-300 focus:border-[#980000] focus:outline-none"
                />
              </span>
              <textarea
                placeholder={"Dites nous tout..."}
                className={
                  "col-span-2 resize-none row-span-10 border-2 p-2 rounded-xl bg-transparent outline-none transition-all duration-300 focus:border-[#980000]"
                }
              ></textarea>
              <button
                className={
                  "bg-dark-red rounded-full col-span-2 text-white text-lg py-2 font-medium"
                }
              >
                Envoyer
              </button>
              <a
                rel="noreferrer"
                target={"_blank"}
                href={require("../assets/pdf/Politique de confidentialite.pdf")}
                className={"text-dark-red w-auto underline text-xs font-light"}
              >
                Politique de confidentialités
              </a>
            </fieldset>
          </form>
        </aside>
      </main>
      <div>
        <Outlet />
      </div>
    </DefaultPageTemplate>
  );
};
