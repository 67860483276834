import { useEffect, useState } from "react";
import { estimationInfo } from "../components/Home-component/Estimation";
import { extractParams } from "../utils/Estimation.utils";
import { EstimationData } from "../interfaces/Estimer.interface";
import { BienImmo } from "../interfaces/interface";
import { EstimationResultAPI } from "../API/Estimation.API";

/**
 * @name useEstimer
 * @description Custom hook pour l'estimation dans la page d'accueil
 */
export function useEstimer() {
  const [data, setData] = useState<estimationInfo>(extractParams());
  const [estimationValue, setEstimationValue] = useState<EstimationData | null>(
    null,
  );
  const [PhoneFocus, setPhoneFocus] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [screen, setScreen] = useState<number>(0);
  const [bienVendu, setBienVendu] = useState<BienImmo[] | null>(null);
  const [inBv, setInBv] = useState(false);

  const handleBienVendu = async (type: string) => {
    if (data.id_adr !== "") {
      console.log(type);
      const url = `https://${process.env.REACT_APP_API_URL}/api/dvf/bv?id_adr=${data.id_adr}&type=${type}`;
      const response = await EstimationResultAPI(url);
      const value = Object.values(response.bienvendu);
      const combinedList = value.reduce(
        (acc: any[], curr) => acc.concat(curr),
        [],
      );
      setBienVendu(combinedList);
    }
  };

  const handleScroll = () => {
    if (window.pageYOffset >= 1800 && window.pageYOffset <= 1900 && !inBv) {
      setInBv(true);
      setScreen(1);
      if (bienVendu === null && estimationValue) {
        handleBienVendu(estimationValue?.esti_mai.avg > 0 ? "1" : "0");
      }
    }
  };

  const handleEstimation = async () => {
    if (data.id_adr !== "") {
      try {
        const url = `https://${process.env.REACT_APP_API_URL}/api/dvf/e?id_adr=${data.id_adr}`;
        const response = await EstimationResultAPI(url);
        if (response && response.estimation) {
          setEstimationValue(response.estimation);
          console.log(response);
        } else {
          console.error(
            "Response or estimation property is undefined:",
            response,
          );
        }
      } catch (error) {
        console.error("Error fetching estimation data:", error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    handleEstimation();
  }, [data]);

  return {
    data,
    setData,
    estimationValue,
    setEstimationValue,
    PhoneFocus,
    setPhoneFocus,
    clicked,
    setClicked,
    screen,
    setScreen,
    bienVendu,
    setBienVendu,
    handleBienVendu,
  };
}
