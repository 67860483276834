import { PropertyData } from "../../interfaces/Annonce.interface";
import React, { useEffect, useState } from "react";
import { PriceInformation } from "./Price.component";
import { options } from "../../interfaces/interface";
import { CaracteristicComponent } from "./Caracteristic.component";
import { EnergyDiag } from "./Diagnostic.component";
import { EstimationComponent } from "./Estimation.component";
import { PartnerComponent } from "./Annonce.partner.component";
import { DiscoverMap } from "./Annonce.map.component";
import qrcode from "../../assets/qr-code.png";
import { price_format } from "../../utils/format";
import { AiOutlinePicture } from "react-icons/ai";

/**
 * @name DisplayPictures
 * @description Composant d'affichage des images de la page d'annonce (les 3 premieres)
 * @param setImageModal
 * @param annonceData
 * @constructor
 */
export const DisplayPictures = ({
  setImageModal,
  annonceData,
}: {
  setImageModal: (args: boolean) => void;
  annonceData: PropertyData;
}) => {
  return (
    <section
      onClick={() => setImageModal(true)}
      className={
        "sm:h-[100vh] overflow-hidden w-full px-10 xl:px-[15%] rounded-lg sm:grid sm:grid-rows-2 sm:grid-flow-col sm:gap-2 group sm:items-start mt-2"
      }
    >
      <img
        src={annonceData.picture[0].picturepath}
        alt={`${annonceData.picture[0].picture_id}`}
        className={
          "sm:row-span-2 sm:col-span-2 md:h-full w-full object-cover rounded mr-0 md:mr-2 cursor-pointer hover:brightness-75"
        }
      />
      <img
        src={annonceData.picture[1].picturepath}
        alt={`${annonceData.picture[1].picture_id}`}
        className={
          "md:block hidden object-cover h-full w-full col-span-1 rounded cursor-pointer hover:brightness-75"
        }
      />
      <div className={"w-full relative h-full md:block hidden col-span-1"}>
        <img
          src={annonceData.picture[2].picturepath}
          alt={`${annonceData.picture[2].picture_id}`}
          className={
            "object-cover h-full w-full rounded cursor-pointer hover:brightness-75"
          }
        />
        <button
          onClick={() => setImageModal(true)}
          className={
            "absolute bg-white rounded shadow p-2 flex items-center text-[0.6rem] lg:text-base  bottom-4 right-4 gap-2"
          }
        >
          <AiOutlinePicture className={"text-base"} />
          Afficher toutes les photos
        </button>
      </div>
    </section>
  );
};

const DisplayInformation = ({
  annonceData,
  property_id,
  setCaractModal,
}: {
  annonceData: PropertyData;
  property_id: string;
  setCaractModal: (args: boolean) => void;
}) => {
  const [showResume, setShowResume] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 800) {
      setShowResume(true);
    } else {
      setShowResume(false);
    }
  };

  return (
    <div
      className={
        "h-full w-full flex flex-row my-10 p-2 gap-2 px-[10%] xl:px-[17%]"
      }
    >
      <div
        className={"h-full w-full lg:w-[60%] flex flex-col items-center gap-10"}
      >
        <div className={"h-auto w-full flex flex-col items-center"}>
          <PriceInformation annonceData={annonceData} />
        </div>
        <div className={"w-full h-auto relative"}>
          <div
            className={`${
              showResume ? "opacity-100" : "opacity-0"
            } fixed py-1 top-0 left-1/4 w-full flex items-center gap-5 z-50 h-[70px] transition-all duration-300`}
          >
            <img
              src={annonceData.picture[0].picturepath}
              alt={"header"}
              className={"h-full object-scale-down"}
            />
            <span className={"flex flex-col text-xs"}>
              <p>
                {
                  options.find(
                    (element) => element.value === annonceData.typebien.type,
                  )?.label
                }
              </p>
              <p>{price_format(annonceData.pdvc.prixdevente)}</p>
              <p>{annonceData.info.city}</p>
            </span>
          </div>
          <section className={"flex flex-col items-center gap-10"}>
            <CaracteristicComponent
              annonceData={annonceData}
              setCaractModal={setCaractModal}
            />
            <div className={"h-[1px] w-full bg-slate-200"}></div>
            <EnergyDiag index={annonceData.energy.type} />
            <div className={"h-[1px] w-full bg-slate-200"}></div>
            <EstimationComponent
              propData={annonceData}
              property_id={property_id}
            />
            <div className={"h-[1px] w-full bg-slate-200"}></div>
            <PartnerComponent
              Partner={annonceData.partner}
              accompanist={annonceData.info.accompanist}
            />
            <div
              className={"h-[1px] w-full bg-slate-200"}
              id={"map"}
              key={"map"}
            ></div>
            <DiscoverMap
              city={annonceData.info.city}
              latitude={parseFloat(annonceData.info.latitude != null ? annonceData.info.latitude : "0")}
              longitude={parseFloat(annonceData.info.longitude != null ? annonceData.info.longitude : "0")}
            />
          </section>
        </div>
      </div>
      <div className={"h-full w-[50%] justify-center relative lg:block hidden"}>
        <section className={"sticky top-[120px] group"}>
          <div className={"flex justify-center"}>
            <div
              className={
                "bg-white h-auto py-10 justify-center text-xs lg:text-base hidden drop-shadow rounded sm:flex flex-col items-center z-10 p-2 w-[60%] "
              }
            >
              <h1 className={"font-bold xl:text-base text-center text-sm"}>
                Pour visiter le bien téléchargez notre application.
                <br />
                <span className={"text-dark-red"}>
                  Bientot disponible
                </span>
              </h1>
              <div
                className={
                  "h-full w-full flex xl:flex-row flex-col justify-evenly items-center"
                }
              >
                <div className={"h-[120px] w-[120px] flex justify-center items-center"}>
                  <img src={qrcode} alt={"QR code"} />
                </div>
                <button
                  className={
                    "rounded bg-dark-red px-2 py-1 font-semibold text-white"
                  }
                >
                  Telecharger
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DisplayInformation;
