import { FlatwayButton, VisiteCard } from "../../utils/FlatWay.utils";
import Demande from "../../assets/4 - visiter/En attente-vendeur.png";
import VisiteP from "../../assets/4 - visiter/Planifier-acheteur.png";
import Visite from "../../assets/4 - visiter/Planifier QR code.png";
import React from "react";
import { FlatwaySectionId } from "../../interfaces/Flatway.sectionId";
import Visiter from "../../assets/visiter 1.jpg";

/**
 * @name FlatWayVisiteComponent
 * @description Section de visite de la page Flatway
 * @constructor
 */
export const FlatWayVisiteComponent = () => {
  return (
    <div
      id={FlatwaySectionId.visiter}
      className={"sm:h-auto w-full py-20 xl:px-0 px-10"}
    >
      <div
        className={`flex xl:flex-row  flex-col gap-[5vw] items-center justify-center`}
      >
        <aside>
          <div className={"w-full xl:w-[30vw] xl:h-[400px]"}>
            <img
              src={Visiter}
              className={"w-full h-full object-cover rounded-xl"}
              alt={"sellImgSection"}
            />
          </div>
        </aside>
        <main className="sm:h-auto flex w-full xl:w-[30vw] justify-center gap-5 flex-col">
          <h1 className={"text-3xl leading-tight font-bold"}>
            Nous avons repensé les visites et les offres pour vous
          </h1>
          <p className={"text-sm md:text-base"}>
            Avec une approche moderne et sécurisée, notre application permet à
            nos utilisateurs de prendre des rendez-vous en ligne et visiter les
            biens en toute sécurité. Nos utilisateurs peuvent également négocier
            directement l’achat-vente dans un processus fluide, transparent et
            moderne.
          </p>
          <FlatwayButton text={"En savoir plus"} route={"/offre-et-visite"} />
        </main>
      </div>
    </div>
  );
};

/**
 * @name VisiteCardSection
 * @description Section des cartes de visite de la page Flatway
 * @constructor
 */
export const VisiteCardSection = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center font-medium">
      <div className={"w-full flex justify-center gap-10"}>
        <main
          className={
            "flex w-full 2xl:px-[10%] py-10 px-0 gap-10 flex-row xl:justify-center items-start xl:ml-0 ml-2 2xl:justify-between overflow-x-auto"
          }
        >
          <VisiteCard title={"La demande de visite"} image={Demande}>
            <p className={"text-sm text-[#737373] font-medium leading-relaxed"}>
              L’acheteur consulte les annonces et propose des dates.
              <br />
              <br />
              La demande de visite est placée{" "}
              <span className={"text-dark-red underline"}>En attente</span> dans
              l’espace « Mes Echanges » des parties ; le vendeur et le
              professionnel sont notifiés.
              <br />
              <br />
              Si la visite est assurée par le vendeur, il choisit la date de
              visite et le professionnel est informé. Sinon, le professionnel
              choisit la date et le vendeur est informé.
            </p>
          </VisiteCard>
          <VisiteCard title={"La visite planifiée"} image={VisiteP}>
            <p className={"text-sm text-[#737373] font-medium leading-relaxed"}>
              La date de visite confirmée est placée en{" "}
              <span className={"text-dark-red underline"}>Planifier</span> dans
              l’espace ‘Mes Echanges’.
              <br />
              <br /> Si une des parties annule ou déplace le rendez-vous, les
              autres parties sont aussitôt notifiées.
            </p>
          </VisiteCard>
          <VisiteCard title={"La visite des biens"} image={Visite}>
            <p className={"text-sm text-[#737373] font-medium leading-relaxed"}>
              L’acheteur confirme sa présence.
              <br />
              <br />
              Un point de rencontre, choisi par le vendeur ou par le
              professionnel, est ensuite communiqué à l’acheteur.
              <br />
              <br />
              L’identité de l’acheteur est confirmée grâce au QR code accessible
              depuis l’espace Visite.
            </p>
          </VisiteCard>
        </main>
      </div>
    </div>
  );
};
