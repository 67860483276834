import MainLayout from "../components/Home-component/main-layout";
import { SectionIdEnum } from "../components/Home-component/types/section-id";
import Intro from "../components/Home-component/Intro";
import Consultation from "../components/Home-component/Consultation";
import Estimation from "../components/Home-component/Estimation";
import { SectionContainer } from "../components/Home-component/Section-layout";
import { Outlet } from "react-router-dom";
import Vente from "../components/Home-component/Vente";
import React from "react";
import { QRCodeFooter } from "../utils/Footers";

/**
 * @name Home
 * @description Page d'accueil
 * @constructor
 */
const Home = () => {
  return (
    <MainLayout>
      <SectionContainer sectionId={SectionIdEnum.decouvrir}>
        <Intro />
      </SectionContainer>
      <SectionContainer sectionId={SectionIdEnum.consulter}>
        <Consultation />
      </SectionContainer>
      <SectionContainer sectionId={SectionIdEnum.vendre}>
        <Vente />
      </SectionContainer>
      <SectionContainer sectionId={SectionIdEnum.estimer}>
        <Estimation />
      </SectionContainer>
      <QRCodeFooter multiple={true} />
      <div>
        <Outlet />
      </div>
    </MainLayout>
  );
};

export default Home;
