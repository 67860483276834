import React from "react";

/**
 * @name EnergyDiag
 * @description Affichage de la classe énergétique
 * @param index
 * @constructor
 */
export const EnergyDiag = ({ index }: { index: string }) => {
  const energyData = [
    { tag: "A", color: "#368f4b" },
    { tag: "B", color: "#45ad54" },
    { tag: "C", color: "#b4cf06" },
    { tag: "D", color: "#f8e600" },
    { tag: "E", color: "#f7a043" },
    { tag: "F", color: "#f36c3c" },
    { tag: "G", color: "#e22f2b" },
  ];

  return (
    <section className={"w-full flex-col flex"}>
      <h1 className={"text-base lg:text-2xl font-bold mb-5"}>
        Classe énergetique
      </h1>
      <div className={"h-10 w-full flex justify-between items-center"}>
        {energyData.map(({ tag, color }) => {
          if (tag === index)
            return (
              <div
                key={tag}
                className={`bg-[${color}] rounded h-10
               w-[14%] flex justify-center items-center`}
                style={{ backgroundColor: color }}
              >
                {tag}
              </div>
            );
          return (
            <div
              key={tag}
              className={`bg-[${color}] w-[14%] h-2`}
              style={{ backgroundColor: color }}
            ></div>
          );
        })}
      </div>
    </section>
  );
};
