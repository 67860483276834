export const NewId1 = () => {
  return (
    <>
      <p>
        Flatway lance, après 3 années de développement, une plateforme 100%
        digitale dédiée à la vente et à l’acquisition immobilière et destinée au
        grand public ainsi qu’à l’ensemble des professionnels du secteur de
        l’immobilier avec une approche totalement innovante.
      </p>
      <p>
        Le projet est né d’une réflexion autour d’une vente et d’un achat par un
        même agent immobilier et générant un montant d’honoraires astronomiques.
        Pour quels motifs ? Comment pallier ce phénomène qui lèse aussi bien le
        vendeur que l’acheteur et nuit à la réputation du professionnel de
        l’immobilier ? Pourquoi autant d’écart de prix au sein d’une zone
        géographique ?
      </p>
      <p>
        Flatway repose sur trois piliers, la transparence, le professionnalisme
        et la simplicité et se positionne comme le “réconciliateur” entre les
        particuliers et une profession décriée sur un sujet majeur : les coûts
        et la justification de ceux-ci. Dans un contexte tendu avec des
        obtentions d’accord de prêt très difficiles, chaque denier compte !
      </p>
      <p>
        Quelle est l’approche novatrice offerte par cette application ?
        L’immobilier revisité, en bousculant les idées reçues et en offrant aux
        particuliers la possibilité, en accord avec les professionnels,
        d’effectuer eux-mêmes les visites et ainsi bénéficier d’honoraires
        minorés versus la grille traditionnellement présentée. Gain de temps,
        gain d’argent ! Le professionnel reste aux côtés de l &#39;acheteur et
        du vendeur tout au long du processus, leurs conseils et leur
        accompagnement étant indispensables à la bonne réalisation de la
        transaction.
      </p>
      <p>
        A l’heure où le marché de l’immobilier a besoin de se réinventer,
        Flatway propose donc une application fluide et intuitive, un modèle
        d&#39;accompagnement novateur, non intrusif et unique, des étapes de
        vente ultra sécurisées et une transparence totale des avancées du projet
        vis à vis des utilisateurs (particuliers et professionnels) qui parlent
        le même langage.
      </p>
      <p>
        On sait qu’aujourd’hui, l’un des points de blocage dans ce contexte de
        crise du secteur immobilier est lié au fait que vendeurs et
        professionnels ne parviennent à se mettre en accord sur le prix de
        vente.
        <br />
        Grâce à son estimation fiable, Flatway pourrait bien devenir le
        médiateur sur ce sujet.
      </p>
      <p>Rendez-vous sans plus attendre sur www.flatway.fr</p>
    </>
  );
};

export const NewId2 = () => {
  return (
    <>
      <p>
        Flatway, propose l’un des outils les plus fiables et pertinents du
        marché en matière d’estimation.
      </p>
      <p>
        Pour rappel Flatway est une application 100 % digitalisée à destination
        des particuliers qui souhaitent vendre ou acheter un bien avec le
        soutien des professionnels de l’immobilier exerçant avec des coûts
        optimisés : autrement dit, gain de temps, gain d’argent.
      </p>
      <p>
        Cette estimation est fondée sur les données fournies par la DGFIP,
        traitées et mises à jour par Flatway et propose un résultat couvrant un
        périmètre géographique extrêmement restreint contrairement à ce qui est
        d’ordinaire proposé dans le cadre de cette requête.
      </p>
      <p>
        Obtenue de façon quasi instantanée, elle fournit aux particuliers et aux
        professionnels le même chiffrage favorisant d’entrée de jeu un même
        discours.
      </p>
      <p>Alors testez l’estimation et faites-vous votre opinion !</p>
    </>
  );
};
