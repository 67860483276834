import { BsDoorClosed } from "react-icons/bs";
import React from "react";
import {
  MdBalcony,
  MdLocalPhone,
  MdMeetingRoom,
  MdOutlineRoom,
  MdOutlineTableBar,
  MdOutlineWindow,
} from "react-icons/md";
import { LuSofa } from "react-icons/lu";
import { GrUserPolice } from "react-icons/gr";
import {
  PiBellRinging,
  PiElevatorLight,
  PiPark,
  PiSwimmingPool,
  PiToilet,
} from "react-icons/pi";
import { GoMoveToTop, GoNumber } from "react-icons/go";
import { LiaUmbrellaSolid } from "react-icons/lia";
import { SiApacheparquet } from "react-icons/si";
import { TbAirConditioning, TbGeometry } from "react-icons/tb";
import { FaHouseChimney } from "react-icons/fa6";
import { FaBox, FaParking } from "react-icons/fa";
import { BiBuildingHouse, BiCheck, BiHandicap } from "react-icons/bi";

/**
 * @name CaractIcon
 * @description Icones des caractéristiques
 */
export const CaractIcon = [
  {
    title: "Entrée",
    icon: <BsDoorClosed className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Salle à Manger",
    icon: (
      <MdOutlineTableBar className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Séjour",
    icon: <LuSofa className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Gardien",
    icon: <GrUserPolice className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Ascenseur",
    icon: (
      <PiElevatorLight className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Cave",
    icon: <MdMeetingRoom className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Interphone",
    icon: <MdLocalPhone className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Digicode",
    icon: <GoNumber className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Terrasse",
    icon: (
      <LiaUmbrellaSolid className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Balcon",
    icon: <MdBalcony className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Parquet",
    icon: (
      <SiApacheparquet className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Moulures",
    icon: <TbGeometry className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Cheminée",
    icon: (
      <FaHouseChimney className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Terrain-Jardin",
    icon: <PiPark className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Box",
    icon: <FaBox className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Dernier étage",
    icon: <GoMoveToTop className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Toilettes séparées",
    icon: <PiToilet className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Alarme",
    icon: <PiBellRinging className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Accès handicapés",
    icon: <BiHandicap className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Piscine",
    icon: (
      <PiSwimmingPool className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Climatisation",
    icon: (
      <TbAirConditioning className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Chambre de service",
    icon: <MdOutlineRoom className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Sans Vis à Vis",
    icon: (
      <MdOutlineWindow className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Parking",
    icon: <FaParking className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Mitoyenne",
    icon: (
      <BiBuildingHouse className={"text-lg lg:text-[30px] text-black m-1"} />
    ),
  },
  {
    title: "Sous-sol",
    icon: <MdMeetingRoom className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
  {
    title: "Dépendance",
    icon: <BiCheck className={"text-lg lg:text-[30px] text-black m-1"} />,
  },
];
