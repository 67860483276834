import React from "react";
import { FlatWayHeader } from "../../utils/FlatWay.utils";

/**
 * @name FlatWayPartnerComponent
 * @description Section des partenaires la page flatway
 * @constructor
 */
function FlatWayPartnerComponent() {
  return (
    <div className="sm:h-auto w-full">
      <FlatWayHeader desc={""} title="Les Professionnels Partenaires" />
      <div className="w-full flex justify-center px-2 lg:px-[20%]">
        <p className={"sm:text-base text-sm"}>
          Tout professionnel immobilier détenant une carte de transaction ou de
          gestion peut s’inscrire sur l’application FlatwayPro lorsque le
          service est disponible dans sa commune.
          <br />
          <br /> L’inscription est soumise, conformément à la législation en
          cours, à la vérification de certains documents et informations ; le
          professionnel et Flatway signent un contrat de partenariat.
          <br />
          <br /> Le professionnels verse à Flatway des frais de services
          seulement si la vente est signée chez le notaire
        </p>
      </div>
    </div>
  );
}

export default FlatWayPartnerComponent;
