import React from "react";
import { Select, SelectOption } from "../../utils/Select";
import { BsFillPinMapFill } from "react-icons/bs";
import { BiBuildingHouse } from "react-icons/bi";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import ExplicationCard from "../../utils/ExplicationCard";
import { useNavigate } from "react-router-dom";
import { handleNavigate } from "../../utils/Consultation.utils";
import { cityInfo } from "../../interfaces/interface";
import Paris from "../../assets/Paris_IA.jpg";
import { IoSearch } from "react-icons/io5";

const explicationCardmap = [
  {
    icon: <BsFillPinMapFill className="text-[25px] sm:text-[30px]" />,
    title: "Localisation",
    text: "Localisez chaque  annonce par le nom de la voie",
  },
  {
    icon: <BiBuildingHouse className="text-[25px] sm:text-[30px]" />,
    title: "Estimation",
    text: "Consultez notre estimation pour chaque bien en vente",
  },
  {
    icon: <PiMagnifyingGlassBold className="text-[25px] sm:text-[30px]" />,
    title: "Biens vendus",
    text: "Voir les biens vendus autour de chaque bien en vente",
  },
];

type otherProps = {
  focus: boolean;
  isOpen: boolean;
  toggleOptions: () => void;
  options: SelectOption[];
  value1: SelectOption[];
  setValue1: (value: SelectOption[]) => void;
  selectCity: cityInfo[];
};

/**
 * @name ConsultationOther
 * @description Section de select et de bouton de recherche de consultation de la page d'accueil
 * @param focus
 * @param isOpen
 * @param toggleOptions
 * @param options
 * @param value1
 * @param setValue1
 * @param selectCity
 * @constructor
 */
export const ConsultationOther = ({
  focus,
  isOpen,
  toggleOptions,
  options,
  value1,
  setValue1,
  selectCity,
}: otherProps) => {
  const navigate = useNavigate();

  return (
    <div className={"flex w-full sm:w-[50%] items-center h-[40px]"}>
      <div className={"w-[0.05em] z-10 h-[70%] bg-[#BABABA] sm:block hidden"} />
      <Select
        mod={false}
        focus={focus}
        isOpen={isOpen}
        setIsOpen={toggleOptions}
        multiple
        options={options}
        value={value1}
        onChange={(o) => setValue1(o)}
      />
      <button
        onClick={() => {
          handleNavigate(selectCity, value1, navigate, false);
        }}
        className={`transition-all duration-300 text-white bg-dark-red flex md:hidden items-center justify-center h-[35px] w-[35px] rounded-full`}
      >
        <IoSearch className={"text-2xl"} />
      </button>
    </div>
  );
};

/**
 * @name ConsultationInfo
 * @description Section de consultation de la page d'accueil
 * @constructor
 */
function Consultation() {
  return (
    <div className={"flex flex-col items-center pt-[35px] gap-10"}>
      <div className="h-auto w-full">
        <div
          className={
            "w-full h-full backdrop-blur relative justify-center items-center flex px-2"
          }
        >
          <div className="h-auto w-fit py-7 items-center flex md:flex-row flex-col lg:gap-5">
            <main className="w-full md:w-[50vw] xl:w-[30vw] h-fit flex flex-col px-[3vw]">
              <div className="w-full flex flex-col">
                <h1 className="font-bold text-4xl">
                  Achetez selon
                  <br />
                  <span className={"text-dark-red"}>vos critères</span>
                </h1>
              </div>
              <div className="w-auto h-fit py-5 flex flex-col gap-2">
                {explicationCardmap.map(({ icon, title, text }, index) => {
                  return (
                    <div key={index}>
                      <ExplicationCard icon={icon} title={title} text={text} />
                    </div>
                  );
                })}
              </div>
            </main>
            <aside className={"w-full h-[70vh]"}>
              <img
                src={Paris}
                className={"object-cover h-full w-full rounded-xl"}
                alt="Paris"
              />
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
}

// @ts-ignore
export default Consultation;
