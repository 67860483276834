import { PreseText } from "../../utils/FlatWay.utils";
import React, { useEffect, useState } from "react";
import phoneImg from "../../assets/phone_esti.png";
import { FlatwaySectionId } from "../../interfaces/Flatway.sectionId";
import estiImg from "../../assets/estimation steven 1.jpg";
import { useFlatwayEstimation } from "../../hooks/Flatway.hooks";

/**
 * @name FlatwayEstimation
 * @description Section d'estimation de la page Flatway
 * @constructor
 */
const FlatwayEstimation = () => {
  const { images, counter, setHover } = useFlatwayEstimation();

  return (
    <section
      className={"sm:h-auto w-full py-[70px] bg-slate-50"}
      id={FlatwaySectionId.estimer}
    >
      <FlatwayEstimationHeader />
      <div
        className={
          "h-auto sm:h-[70%] w-full relative flex flex-col lg:flex-row justify-between items-center xl:px-[100px] 2xl:px-[18vw] mt-[50px]"
        }
      >
        <aside className="h-[61vh] w-full md:w-[50%] flex justify-center">
          <aside
            className={`h-auto flex-none w-[30vh] flex justify-center items-center relative`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <img
              src={phoneImg}
              alt="phone"
              className={`w-full absolute z-10`}
            />
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className={`opacity-${
                  counter === index ? "100" : "0"
                } w-[99%] transition-opacity absolute duration-500`}
                alt={`flat image-${index} `}
              />
            ))}
          </aside>
        </aside>
        <ContentDisplay counter={counter} />
      </div>
    </section>
  );
};

/**
 * @name FlatwayEstimationHeader
 * @description Header de la section d'estimation de la page Flatway
 * @constructor
 */
const FlatwayEstimationHeader = () => {
  const headerList = [
    "Accès libre, facile et instantané. Aucune information demandée",
    "Renseigner une adresse pour obtenir une estimation simple et les caractéristiques du bien pour une estimation avancée",
    "Consulter la liste des biens vendus autour de l’adresse estimée",
  ];

  return (
    <div
      className={`flex xl:flex-row gap-[5vw] items-center flex-col xl:px-0 px-10 justify-center`}
    >
      <main className="sm:h-auto flex w-full xl:w-[30vw] justify-center gap-5 flex-col">
        <h1 className={"text-3xl leading-tight font-bold"}>
          Une estimation, et bien plus
        </h1>
        <ul className={"flex flex-col md:text-base text-sm gap-2"}>
          {headerList.map((item, index) => (
            <li key={index} className={"flex gap-3 items-center"}>
              <PreseText text={item} />
            </li>
          ))}
        </ul>
      </main>
      <aside>
        <div className={"w-full xl:w-[30vw] xl:h-[400px]"}>
          <img
            src={estiImg}
            className={"w-full h-full object-cover rounded-xl"}
            alt={"sellImgSection"}
          />
        </div>
      </aside>
    </div>
  );
};

/**
 * @name ContentDisplay
 * @description Contenu de la section d'estimation de la page Flatway
 * @param counter
 * @constructor
 */
const ContentDisplay = ({ counter }: { counter: number }) => {
  const EstimationContent = [
    {
      title: "Source des bien",
      text: "La liste des biens vendus est extraite de la base des données de la DGFIP. Données traitées et analysées.",
    },
    {
      title: "Frais agence et notaire",
      text: "Ne sont pas inclus dans les prix de vente affichés.",
    },
    {
      title: "Ventes exceptionnelles",
      text: "Nous avons signalé par un triangle d’alerte       les ventes dont le prix est anormalement élevé (ou bas) par rapport au prix du m² autour de l’adresse.",
    },
  ];

  return (
    <main
      className={"flex justify-between px-2 xl:w-[30vw] gap-10 h-full flex-col"}
    >
      <div>
        <h1
          className={`${
            counter < 2 ? "text-dark-red" : ""
          } text-lg sm:text-2xl font-bold transition-all duration-300`}
        >
          Estimation
        </h1>
        <div className={"w-full grid grid-cols-2 gap-4"}>
          <span className="w-full flex my-2 flex-col">
            <h1 className={"font-bold items-center"}>Source de calcul</h1>
            <p className={`text-xs text-[#737373]`}>
              Nos calculs sont fondés sur les données fournies par la DGFIP
              <span className={"text-dark-red"}>*</span>. Données traitées et
              mises à jour.
            </p>
          </span>
          <span className="w-full flex my-2 flex-col">
            <h1 className={"font-bold items-center"}>Indice de confiance</h1>
            <p className={`text-xs text-[#737373]`}>
              Varie en fonction des biens vendus à l’adresse et à proximité.
            </p>
          </span>
          <span className="w-full flex my-2 flex-col">
            <h1
              className={`${
                counter === 1 ? "text-dark-red" : ""
              } font-bold items-center transition-all duration-300`}
            >
              Estimation avancée
            </h1>
            <p className={`text-xs text-[#737373]`}>
              Nous signalons par un triangle d’alerte les biens dont le prix
              moyen est supérieur au prix le plus haut ou inférieur au prix le
              plus bas <span className={"font-bold italic"}>à l’adresse</span>.
            </p>
          </span>
          <span className="w-full flex my-2 flex-col">
            <h1 className={"font-bold items-center"}>
              Frais agence et notaire
            </h1>
            <p className={`text-xs text-[#737373]`}>
              Ne sont pas inclus dans le calcul des estimations.
            </p>
          </span>
        </div>
        <p className={"text-[0.6rem] italic"}>
          <span className={"text-dark-red"}>*</span>Direction Générale des
          Finances Publiques
        </p>
      </div>
      <div>
        <h1
          className={`text-lg sm:text-2xl font-bold ${
            counter === 2 ? "text-dark-red" : ""
          }`}
        >
          Liste des biens vendus
        </h1>
        <div className={"w-full grid grid-cols-2 gap-4"}>
          {EstimationContent.map(({ title, text }, index) => {
            return (
              <span className="w-full flex my-2 flex-col" key={index}>
                <h1 className={"font-bold items-center"}>{title}</h1>
                <p className={`text-xs text-[#737373]`}>{text}</p>
              </span>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default FlatwayEstimation;
