import { AiOutlineClose } from "react-icons/ai";
import { NavigateFunction } from "react-router-dom";
import EstimationAPI from "../API/Estimation.API";
import { estimationInfo } from "../components/Home-component/Estimation";
import React from "react";
import { FaTriangleExclamation } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";

/**
 * @name extractParams
 * @description Extrait les query params de l'url pour l'estimation
 */
export function extractParams(): estimationInfo {
  const params = new URLSearchParams(window.location.search);
  const adress_complete = params.get("address_complete") || "";
  const id_adr = params.get("id_adr") || "";

  return {
    adresse_complete: adress_complete,
    id_adr: id_adr,
  };
}

/**
 * @name handleEstimationChange
 * @description Gère le changement de l'estimation
 * @param value
 * @param setEstimationInput
 * @param setEstimationList
 */
export const handleEstimationChange = (
  value: string,
  setEstimationInput: (args: string) => void,
  setEstimationList: React.Dispatch<React.SetStateAction<estimationInfo[]>>,
) => {
  setEstimationInput(value);
  handleChangeEstimation(setEstimationList, value);
};

/**
 * @name DisplayEstimationList
 * @description Affiche l'autocomplete de l'estimation
 * @param estimationFocus
 * @param estimationList
 * @param setEstimationInput
 * @param setEstimationFocus
 * @param setSelectAddress
 * @constructor
 */
export const DisplayEstimationList = ({
  estimationFocus,
  estimationList,
  setEstimationInput,
  setEstimationFocus,
  setSelectAddress,
}: {
  estimationFocus: boolean;
  estimationList: estimationInfo[];
  setEstimationInput: (args: string) => void;
  setEstimationFocus: (args: boolean) => void;
  setSelectAddress: (args: estimationInfo) => void;
}) => {
  return estimationFocus && estimationList.length !== 0 ? (
    <div className="absolute left-0 z-20 rounded-lg top-full mt-2 shadow border bg-white border-[#BABABA] w-full">
      {estimationList.map(({ adresse_complete, id_adr }, index) => {
        return (
          <button
            key={index}
            onTouchStart={() => {
              setEstimationInput(adresse_complete);
              setSelectAddress({
                adresse_complete: adresse_complete,
                id_adr: id_adr,
              });
              setEstimationFocus(false);
            }}
            onClick={() => {
              setEstimationInput(adresse_complete);
              setSelectAddress({
                adresse_complete: adresse_complete,
                id_adr: id_adr,
              });
              setEstimationFocus(false);
            }}
            className="h-[30px] w-[320px] sm:w-full hover:bg-[#BABABA] px-2 flex justify-start whitespace-nowrap	truncate ..."
          >
            {adresse_complete}
          </button>
        );
      })}
    </div>
  ) : null;
};

/**
 * @name handleNavigateEsti
 * @description Gère la navigation vers l'estimation sur la page d'accueil
 * @param selectAddress
 * @param navigate
 */
export const handleNavigateEsti = (
  selectAddress: estimationInfo | undefined,
  navigate: NavigateFunction,
) => {
  const url = "/estimation";
  window.scroll(0, 0);
  const location = window.location.href;

  if (selectAddress?.id_adr === undefined) {
  } else {
    navigate(
      `${url}?address_complete=${selectAddress?.adresse_complete}&id_adr=${selectAddress?.id_adr}`,
    );
    if (location.includes("estimation")) {
      window.location.reload();
    }
  }
};

/**
 * @name handleDeleteEstimation
 * @description Gere l'input de l'estimation (fait des requetes uniquement si il y a plus de 2 espaces ex: 11 rue de ...)
 * @param setEstimationList
 * @param input
 */
export const handleChangeEstimation = async (
  setEstimationList: React.Dispatch<React.SetStateAction<estimationInfo[]>>,
  input: string,
) => {
  const countSpace = () => {
    let res: number = 0;
    for (let i = 0; i !== input.length; i++) {
      if (input[i] === " ") {
        res++;
      }
    }
    return res;
  };
  if (countSpace() >= 2) {
    const url = `https://${process.env.REACT_APP_API_URL}/api/dvf/adrautocompletedvf?input=${input}`;
    const data = await EstimationAPI(url);
    console.log(data);
    if (data) {
      setEstimationList(data);
    }
  } else if (input.length === 0) {
    setEstimationList([]);
  }
};

interface InfoItemProps {
  title: string;
  text: string | React.JSX.Element;
}

/**
 * @name InfoItem
 * @description Affiche de text
 * @param title
 * @param text
 * @constructor
 */
export const InfoItem: React.FC<InfoItemProps> = ({ title, text }) => (
  <section>
    <h1 className="font-bold text-xl">{title}</h1>
    <p className="text-base">{text}</p>
  </section>
);

interface PreseTextInfoProps {
  text: string;
}

/**
 * @name PreseTextInfo
 * @description Affiche de text avec un icone
 * @param text
 * @constructor
 */
const PreseTextInfo: React.FC<PreseTextInfoProps> = ({ text }) => (
  <li className="text-xs flex items-center">
    <span className={"w-fit"}>
      <FaCheckCircle className="text-[#980000] text-xl mr-2" />
    </span>
    <span>{text}</span>
  </li>
);

/**
 * @name EstimationSection
 * @description Texte d'information pour l'estimation (en dessous de l'animation et de l'input dans la page d'estimation)
 * @constructor
 */
export const EstimationSection = () => {
  return (
    <section className="flex flex-col items-center justify-center h-auto text-sm gap-5">
      <header>
        <h1 className={"font-bold text-darkRedTitle text-3xl"}>
          Notre estimation des biens
        </h1>
        <h1 className="font-bold text-dark-red">
          Toute estimation est une valeur approximative
        </h1>
      </header>
      <main className={"h-full justify-evenly flex flex-col gap-[15vh]"}>
        <InfoItem
          title="Estimation de prix"
          text="Les prix affichés sont : le prix moyen, le prix le moins cher et le
            prix le plus cher du m² à l’adresse. Nos calculs de prix sont
            fondés, pour l’essentiel, sur les données fournies par la DGFIP (Direction générale des
finances publiques)."
        />
        <InfoItem
          title="Indice de confiance"
          text="L’indice de confiance varie en fonction des biens vendus à l’adresse
            et à ses alentours. Plus nous disposons d’informations, plus
            l’indice de confiance est élevé."
        />
        <section>
          <h1 className="font-bold text-xl">Estimation avancée</h1>
          <p className="text-base">
            Pour une estimation avancée, les prix affichés sont : le prix moyen,
            le prix le plus bas et le prix le plus haut du bien. Nous signalons
            par un <span className={"text-dark-red"}>triangle d’alerte*</span>{" "}
            les biens exceptionnels dont le prix moyen est supérieur au prix le
            plus haut ou inférieur au prix le plus bas à l’adresse.
          </p>
        </section>
        <section>
          <ul className="flex flex-col gap-2 bg-light-red py-3 px-6">
            <span className={"font-bold"}>A noter :</span>
            <PreseTextInfo text="Les honoraires des professionnels et les frais de notaires ne sont pas inclus dans le calcul des estimations et dans les prix de vente affichés." />
            <PreseTextInfo text="Notre estimation ne dissocie pas les ensembles immobiliers composés de plusieurs bâtiments ou ouvrant sur plusieurs adresses." />
            <PreseTextInfo text="Nous affichons un maximum de 150 ventes autour de chaque adresse renseignée." />
            <PreseTextInfo text="Biens vendus : La surface est précisée en Carrez uniquement si elle est indiquée dans la vente" />
          </ul>
        </section>
        <section>
          <h1 className="font-bold text-xl">Liste des biens vendus</h1>
          <p className="text-base">
            La liste des biens vendus autour de l’adresse, jusqu’à 300 m, est
            extraite de la base des transactions immobilières fournies par la
            DGFIP. À certaines adresses, nous avons signalé par un{" "}
            <span className={"text-dark-red"}>triangle d’alerte*</span> les
            ventes que nous avons considérées comme exceptionnelles par leur
            prix de vente, anormalement élevé ou anormalement bas par rapport au
            prix du m² autour de l’adresse.
          </p>
        </section>
        <section className={"mb-10"}>
          <p className="text-xs">
            <span className={"flex items-center gap-1"}>
              <FaTriangleExclamation className={"text-base text-dark-red"} /> :
              Triangle d’alerte
            </span>
            <span className="font-bold">Règles des CGU</span> : Les informations
            ne sont données qu'à titre indicatif et ne sauraient être
            considérées comme constituant une offre de services. Elles n’ont pas
            de valeur contractuelle. Flatway ne garantit ni l’exactitude ni le
            caractère exhaustif ni le caractère actuel des informations
            présentées sur la plateforme, quelles qu’elles soient. Flatway
            produit cependant ses meilleurs efforts pour proposer les valeurs
            les plus en adéquation avec les prix du marché.
          </p>
        </section>
      </main>
    </section>
  );
};
