import { IoIosArrowForward } from "react-icons/io";
import React from "react";

/**
 * @name ScrollTemplate
 * @description Template de scroll pour les sections de la roadmap
 * @param title
 * @param desc
 * @param isOpen
 * @param setIsOpen
 * @constructor
 */
export const ScrollTemplate = ({
  title,
  desc,
  isOpen,
  setIsOpen,
}: {
  title: string;
  desc: React.JSX.Element;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  return (
    <div className="h-full w-full flex items-center flex-col">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`h-fit w-full border-b border-0 border-[#980000] p-2 flex flex-col transition-all duration-300 overflow-hidden`}
      >
        <div className="flex w-full text-start font-medium justify-between items-center">
          <h1
            className={`${
              isOpen
                ? "text-sm md:text-base text-dark-red"
                : "text-base md:text-lg"
            } font-medium hover:text-dark-red transition-all duration-300`}
          >
            {title}
          </h1>
          <span>
            <IoIosArrowForward
              className={`text-[20px] text-dark-red transition-all duration-300 ${
                isOpen ? "rotate-90" : ""
              }`}
            />
          </span>
        </div>
        <div
          className={`transition-all md:text-base text-sm text-start duration-300 ${
            isOpen ? "block" : "hidden"
          }`}
        >
          {desc}
        </div>
      </button>
    </div>
  );
};
