import { Outlet } from "react-router-dom";
import React from "react";
import Navbar from "../components/navbar";
import {
  CaractModal,
  ImageModal,
} from "../components/Annonce-component/Annonce.modal.component";
import Footers from "../utils/Footers";
import Logo from "../assets/appIcon.png";
import DisplayInformation, {
  DisplayPictures,
} from "../components/Annonce-component/Annonce.component";
import { useAnnonce } from "../hooks/Annonce.hooks";

/**
 * @name Annonce
 * @description Page Annonce
 * @constructor
 */
export const Annonce = () => {
  const {
    caractModal,
    setCaractModal,
    imageModal,
    setImageModal,
    annonceData,
    property_id,
  } = useAnnonce();

  return (
    <div className="h-auto w-full">
      <Navbar mod={true} />
      <div className={"h-[70px]"} />
      {annonceData ? (
        <>
          <ImageModal
            imageModal={imageModal}
            setImageModal={setImageModal}
            annonceData={annonceData}
          />
          <CaractModal
            annonceData={annonceData}
            setCaractModal={setCaractModal}
            caractModal={caractModal}
          />
          <div className="h-auto w-[100%] flex justify-center mb-28">
            <div className={"flex-col w-full flex"}>
              <DisplayPictures
                setImageModal={setImageModal}
                annonceData={annonceData}
              />
        <span className={"text-dark-red flex justify-center text-xl content-center font-bold"}>
          Les annonces affichées sont fictives
        </span>
              <DisplayInformation
                annonceData={annonceData}
                setCaractModal={setCaractModal}
                property_id={property_id}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="h-auto w-full flex justify-center sm:px-[17%] mb-28">
          <div className={"flex-col w-full flex"}>
            <section
              className={
                "sm:h-[70vh] w-full rounded-lg animate-pulse sm:grid sm:grid-rows-2 sm:grid-flow-col sm:gap-2 group sm:items-start mt-2"
              }
            >
              <div
                className={
                  "sm:row-span-2 sm:col-span-2 h-full bg-slate-300 w-full sm:h-full sm:w-full object-cover rounded mr-0 sm:mr-2"
                }
              />
              <div
                className={
                  "sm:block hidden object-cover h-full bg-slate-300 col-span-1 rounded"
                }
              />
              <div
                className={
                  "sm:block hidden object-cover h-full bg-slate-300 rounded"
                }
              />
            </section>
            <span
              className={
                "flex items-center w-full justify-center flex-col py-10"
              }
            >
              <img
                src={Logo}
                alt={"Loading logo"}
                className={"object-scale-down h-[10vh]"}
              />
              <svg
                width="20"
                height="20"
                fill="currentColor"
                className="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
              </svg>
              <p>Chargement en cours</p>
            </span>
          </div>
        </div>
      )}
      <Footers />
      <div>
        <Outlet />
      </div>
    </div>
  );
};
