import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Supercluster from "supercluster";
import mapboxgl from "mapbox-gl";
import { sellType } from "../interfaces/interface";

/**
 * @name useMaps
 * @description Custom hook pour la carte
 * @param city
 * @param sellList
 */
export function useMaps(city: string, sellList: sellType[]) {
  const navigate = useNavigate();
  const [width, setWidth] = useState("80%");
  const [viewport, setViewport] = useState({
    latitude: 48.8566,
    longitude: 2.3522,
    zoom: 5,
  });
  const [rerenderMap, setRerenderMap] = useState(false); // Etat local pour forcer le rerender
  const supercluster = new Supercluster({
    radius: 40,
    maxZoom: 20,
  });

  if (!sellList || sellList.length === 0) {
  } else {
    supercluster.load(
      sellList.map((item, idx) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parseFloat(item.longitude != null ? item.longitude : "0"), parseFloat(item.latitude != null ? item.latitude : "0")],
        },
        properties: { id: idx, ...item },
      })),
    );
  }

  const handleMap = useCallback(async () => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${city.replaceAll(
      ",",
      "&",
    )}.json?access_token=${mapboxgl.accessToken}&country=FR`;
    const response = await fetch(url, { method: "GET" });
    const data = await response.json();
    try {
      const coordinates = data.features.map((item: any) => item.center);
      const lats = coordinates.map((coord: any) => coord[1]);
      const lngs = coordinates.map((coord: any) => coord[0]);

      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);

      const bounds = [
        [minLng, minLat],
        [maxLng, maxLat],
      ];

      const center = [(minLng + maxLng) / 2, (minLat + maxLat) / 2];

      const fitBounds = (bounds: any) => {
        const WORLD_DIM = {
          height: window.innerHeight,
          width: window.innerWidth,
        };
        const ZOOM_MAX = 21;

        const latDiff = bounds[1][1] - bounds[0][1];
        const lngDiff = bounds[1][0] - bounds[0][0];

        const latZoom = Math.log2(WORLD_DIM.height / latDiff);
        const lngZoom = Math.log2(WORLD_DIM.width / lngDiff);

        return Math.min(latZoom, lngZoom, ZOOM_MAX) - 2; // Subtract 1 to add some padding
      };

      const zoom = fitBounds(bounds);

      console.log(zoom);
      setViewport({
        latitude: center[1],
        longitude: center[0],
        zoom: zoom < 12 ? Math.max(zoom, 0) : 12, // Ensure zoom is at least 0
      });
    } catch (error) {
      console.log("Error ConsulterMap:", error);
    }
  }, [city]);

  useEffect(() => {
    handleMap();
  }, [handleMap]);

  return {
    navigate,
    width,
    setWidth,
    viewport,
    setViewport,
    rerenderMap,
    setRerenderMap,
    supercluster,
  };
}
