/**
 * @name FlatwaySectionId
 * @description Enumération des sections de la page Flatway
 */
export const enum FlatwaySectionId {
  estimer = "estimer",
  vendre = "vendre",
  acheter = "acheter",
  visiter = "visiter",
}
