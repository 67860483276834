import React, { useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { sellType } from "../../interfaces/interface";
import { IoIosArrowBack } from "react-icons/io";
import Slider from "./SellList-component/Slider";
import { SAlogo } from "../../utils/utils.components";
import { useMaps } from "../../hooks/Consulter.map.hooks";
import { price_format } from "../../utils/format";
import { NavigateFunction } from "react-router-dom";
mapboxgl.accessToken =
  "pk.eyJ1Ijoic3RldmVuZmxhdDUiLCJhIjoiY2xwMTVobmphMGlnaTJrczQ2OWlsMGVpbCJ9.w2JtUyw5T5fKEjLy0Sdh0g";

/**
 * @name Map
 * @description Composant de la carte de consultation
 * @param city
 * @param sellList
 * @param hover
 * @param listOpen
 * @param setListOpen
 * @constructor
 */
function Map({
  city,
  sellList,
  hover,
  listOpen,
  setListOpen,
}: {
  city: string;
  sellList: sellType[];
  hover: number;
  listOpen: boolean;
  setListOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    navigate,
    width,
    setWidth,
    viewport,
    setViewport,
    rerenderMap,
    setRerenderMap,
  } = useMaps(city, sellList);

  const [hoveredMarker, setHoveredMarker] = useState<number | null>(null);

  return (
    <div className={`h-full w-[100%] z-0 hidden lg:block relative`}>
      <button
        onClick={() => {
          setListOpen(!listOpen);
          setWidth(width === "80%" ? "100%" : "100%");
          setRerenderMap(!rerenderMap);
        }}
        className={"w-fit absolute bg-white p-2 left-2 z-10 top-7"}
      >
        <IoIosArrowBack
          className={`text-xl ${
            listOpen ? "" : "rotate-180"
          } transition-all duration-300`}
        />
      </button>
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={mapboxgl.accessToken}
        mapStyle={"mapbox://styles/stevenflatmap/cls90mytj011w01qs2pvz3pgs"}
        onMove={(evt) => setViewport(evt.viewState)}
        key={rerenderMap ? "rerender" : "original"}
        style={{ width: "100%", height: "100%" }}
      >
        {sellList && sellList.length != 0
          ? sellList.map((property, index) => {
              return (
                <Marker
                  key={index}
                  latitude={parseFloat(property.latitude != null ? property.latitude : "0")}
                  longitude={parseFloat(property.longitude != null ? property.longitude : "0")}
                  style={{
                    cursor: "pointer",
                    zIndex: hoveredMarker === property.annonce_id ? 10 : 1,
                  }}
                >
                  <MapMarker
                    property={property}
                    hover={hover}
                    setHoveredMarker={setHoveredMarker}
                    hoveredMarker={hoveredMarker}
                    navigate={navigate}
                  />
                </Marker>
              );
            })
          : null}
      </ReactMapGL>
    </div>
  );
}

const MapMarker = ({
  property,
  hover,
  setHoveredMarker,
  hoveredMarker,
  navigate,
}: {
  property: sellType;
  hover: number;
  setHoveredMarker: React.Dispatch<React.SetStateAction<number | null>>;
  hoveredMarker: number | null;
  navigate: NavigateFunction;
}) => {
  return (
    <div
      onMouseEnter={() => {
        setHoveredMarker(property.annonce_id);
      }}
      onMouseLeave={() => {
        setHoveredMarker(null);
      }}
      style={{
        cursor: "pointer",
        zIndex: hoveredMarker === property.annonce_id ? 10 : 1,
      }}
      className={"relative flex items-center justify-center group"}
    >
      <div
        className={
          "group-hover:flex h-72 w-72 bottom-full overflow-hidden flex-col rounded-xl items-center absolute bg-white hidden"
        }
      >
        <div className={"h-3/4 w-full"}>
          <Slider pictures={property.pictures} logo_pa={property.logo_pa} />
        </div>
        <div className={"w-full h-1/4 flex items-center px-4"}>
          <ul className={"w-full"}>
            <li
              className={
                "font-semibold flex w-full items-center justify-between"
              }
            >
              <span>
                {property.type_bien === "app" ? "Appartement" : "Maison"} -{" "}
                {property.city}
              </span>
              {property.accompanist ? <SAlogo /> : null}
            </li>
            <li>
              {property.surface} m² - {property.nb_piece} pièces -{" "}
              {property.nb_chambre} chambres
            </li>
            <li className={"font-semibold"}>
              {price_format(property.prixdevente)}
            </li>
          </ul>
        </div>
      </div>
      <button
        onClick={() => {
          console.log(property.partneragent_id);
          navigate(
            `/annonce?property_id=${property.property_id}&partneragent_id=${property.partneragent_id}`,
          );
        }}
        className={`h-3 w-3 rounded-full border-2 hover:bg-light-red border-dark-red bg-dark-red ${
          hover === property.annonce_id ? "bg-light-red" : "bg-dark-red"
        }`}
      ></button>
    </div>
  );
};

export default Map;
