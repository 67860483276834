import React from "react";
import { cityInfo } from "../interfaces/interface";
import { Outlet } from "react-router-dom";
import Map from "../components/Consultation-Component/map";
import SellList from "../components/Consultation-Component/SellList";
import { useConsultation } from "../hooks/Consulter.hooks";
import { ConsultationNavBar } from "../components/Consultation-Component/Consultation.component";

/**
 * @name Consulter
 * @description Page Consulter
 * @constructor
 */
function Consulter() {
  const {
    navigate,
    listOpen,
    setListOpen,
    zipcode,
    sellVariable,
    sellList,
    filter,
    setFilter,
    annonceHover,
    setAnnonceHover,
  } = useConsultation();

  return (
    <div className="h-[100vh] w-full">
      <ConsultationNavBar navigate={navigate} sellVariable={sellVariable} />
      {sellList != null && sellList.length != 0?
      <div className="h-[92%] w-full z-0 flex flex-row">
        <SellList
          filter={filter}
          setFilter={setFilter}
          cityName={sellVariable.cityname}
          data={sellList}
          setHover={setAnnonceHover}
          listOpen={listOpen}
        />
        <Map
          city={zipcode}
          sellList={sellList}
          hover={annonceHover}
          listOpen={listOpen}
          setListOpen={setListOpen}
        />
      </div> : 
      <div className={"w-full h-1/4 flex items-center px-4"}>
          Aucune annonce disponible dans cette zone
      </div>
        }
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default Consulter;
