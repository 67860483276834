import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cityInfo, filterList, options } from "../interfaces/interface";
import { SelectOption } from "../utils/Select";
import ConsultationAPI from "../API/Consultation.API";
import { handleChangeCity } from "../utils/Consultation.utils";

/**
 * @name extractParams
 * @description Extract params from URL
 * @param city
 * @param zipcode
 * @param tb
 */
function extractParams(
  city: string | null,
  zipcode: string | null,
  tb: string | null,
) {
  const cityname = city ? city.split(",") : [];
  const zipcodelist = zipcode ? zipcode.split(",") : [];
  const tblist = tb ? tb.split(",") : [];

  return { cityname, zipcodelist, tblist };
}

/**
 * @name fillOption
 * @description Remplir les options
 * @param tb
 */
export function fillOption(tb: string[]) {
  const optionsList: SelectOption[] = [];

  tb.forEach((item) => {
    const option = options.find((opt) => opt.value === item);
    if (option) {
      optionsList.push(option);
    }
  });
  return optionsList;
}

/**
 * @name fillCity
 * @description Remplir les villes
 * @param name
 * @param zipcode
 */
export function fillCity(name: string[], zipcode: string[]) {
  let cityList: cityInfo[] = [];

  name.forEach((element, index) => {
    const tmp: cityInfo = {
      name: element,
      index: index,
      zipcode: zipcode[index],
    };

    cityList.push(tmp);
  });
  return cityList;
}

/**
 * @name useConsultation
 * @description Custom hook pour la consultation
 */
export function useConsultation() {
  const [listOpen, setListOpen] = useState(true);
  // Search BAR
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useState(new URLSearchParams(location.search));
  const city = params.get("city") || "";
  const zipcode = params.get("zipcode") || "";
  const tb = params.get("tb") || "";
  const [sellVariable, setSellVariable] = useState(
    extractParams(city, zipcode, tb),
  );
  const [sellList, setSellList] = useState([]);
  const [filter, setFilter] = useState(filterList[0]);
  const [annonceHover, setAnnonceHover] = useState(-1);

  const initSellList = async () => {
    if (city === "" && zipcode === "" && tb === "") {
      window.location.reload();
      navigate("?city=Paris&zipcode=75000&tb=1");
    }
    const url = `https://${process.env.REACT_APP_API_URL}/api/registration/search?city=${city}&cp=${zipcode}&tb=${tb}`;
    console.log(url);
    const data = await ConsultationAPI(url, true);
    setSellVariable(extractParams(city, zipcode, tb));
    setSellList(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      await initSellList();
    };

    fetchData();
  }, [city, zipcode, tb]);
  return {
    navigate,
    listOpen,
    setListOpen,
    zipcode,
    sellVariable,
    sellList,
    filter,
    setFilter,
    annonceHover,
    setAnnonceHover,
  };
}

/**
 * @name useConsultationNavbar
 * @description Custom hook pour la barre de recherche
 * @param sellVariable
 */
export function useConsultationNavbar(sellVariable: {
  cityname: string[];
  zipcodelist: string[];
  tblist: string[];
}) {
  const [userInput, setUserInput] = useState("");
  const [selectCity, setSelectCity] = useState<cityInfo[]>(
    fillCity(sellVariable.cityname, sellVariable.zipcodelist),
  );
  const [isOpen, setIsOpen] = useState(false);
  const [value1, setValue1] = useState<SelectOption[]>(
    fillOption(sellVariable.tblist),
  );
  const [CityList, setCityList] = useState([]);
  const [focus, setFocus] = useState(false);

  const handleDelete = (index: number) => {
    const updatedCityList = selectCity.length === 1 ? [] : [...selectCity];
    updatedCityList.splice(index, 1);
    setSelectCity(updatedCityList);
    if (selectCity.length === 0) {
      setFocus(false);
    }
  };

  const toggleOptions = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setUserInput(input);
    handleChangeCity(input, setCityList);
  };

  return {
    userInput,
    selectCity,
    isOpen,
    value1,
    CityList,
    focus,
    setUserInput,
    setSelectCity,
    setValue1,
    setFocus,
    handleDelete,
    toggleOptions,
    handleChange,
  };
}
