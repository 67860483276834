import Logo from "../../assets/appIcon.png";
import {
  ConsultationInfo,
  handleBlur,
  handleNavigate,
} from "../../utils/Consultation.utils";
import { ConsultationOther } from "../Home-component/Consultation";
import { cityInfo, options } from "../../interfaces/interface";
import { IoSearch } from "react-icons/io5";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { useConsultationNavbar } from "../../hooks/Consulter.hooks";

/**
 * @name DisplaySelectCity
 * @description Affichage des villes sélectionnées dans la barre de consultation
 * @param selectCity
 * @param handleDelete
 * @param focus
 * @constructor
 */
export const DisplaySelectCity = ({
  selectCity,
  handleDelete,
  focus,
}: {
  selectCity: cityInfo[];
  handleDelete: (args: number) => void;
  focus: boolean;
}) => {
  return (
    <>
      {selectCity.length > 0 ? (
        focus ? (
          selectCity.map(({ name, zipcode }, index) => {
            return (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(index);
                }}
                className="transition-all duration-300 flex items-center rounded-lg bg-slate-50 hover:bg-light-red py-1.5 px-2 cursor-pointer outline-none text-sm h-[30px] m-1"
              >
                {name}
                <span className={"bg-none ml-2"}>&times;</span>
              </button>
            );
          })
        ) : (
          <div className="flex items-center">
            <button
              onFocus={() => handleDelete(0)}
              onTouchStart={() => handleDelete(0)}
              onClick={() => handleDelete(0)}
              type="button"
              className="transition-all duration-300 hover:bg-light-red flex items-center gap-2 rounded-lg bg-slate-50 w-fit py-1.5 px-2 cursor-pointer outline-none text-sm h-[30px] whitespace-nowrap m-1"
            >
              {selectCity[0].name}
              <span className={"bg-none ml-2"}>&times;</span>
            </button>
            {selectCity.length >= 2 ? (
              <div
                className={`border border-[#BABABA] rounded-full flex justify-center items-center w-7 h-7
               mr-1`}
              >
                {selectCity.length - 1}
              </div>
            ) : null}
          </div>
        )
      ) : null}
    </>
  );
};

/**
 * @name ConsultationNavBar
 * @description Barre de navigation pour la consultation dans la page de consultation
 * @param navigate
 * @param sellVariable
 * @constructor
 */
export const ConsultationNavBar = ({
  navigate,
  sellVariable,
}: {
  navigate: NavigateFunction;
  sellVariable: {
    cityname: string[];
    zipcodelist: string[];
    tblist: string[];
  };
}) => {
  const {
    userInput,
    selectCity,
    isOpen,
    value1,
    CityList,
    focus,
    setUserInput,
    setSelectCity,
    setValue1,
    setFocus,
    handleDelete,
    toggleOptions,
    handleChange,
  } = useConsultationNavbar(sellVariable);

  return (
    <nav
      style={{ zIndex: 1000 }}
      className={`
         min-h-[70px] relative items-center py-2 w-full z-50 backdrop-blur-[2px] flex bg-white shadow xl:px-2`}
    >
      <div className="h-full w-full z-50 flex justify-center relative flex-col lg:flex-row items-center px-[30px]">
        <div className={"w-full"}>
          <img
            onClick={() => navigate("/")}
            src={Logo}
            alt="logo"
            className="h-[50px] cursor-pointer drop-shadow-logo"
          />
        </div>
        <div className="bg-white absolute sm:flex-row top-0 z-50 flex-col px-2 pl-4 py-1 transition-all duration-300 shadow-[rgba(0,0,0,0.035)_0px_5px_15px] h-auto w-[98%] lg:w-[70%] xl:w-[800px] rounded-3xl flex md:justify-center gap-2">
          <div
            className={`w-full relative lg:w-[50%] flex flex-col transition-all duration-300`}
            onBlur={(e) => handleBlur(e, setFocus)}
          >
            <form
              className={`transition-all duration-300 rounded-lg items-center flex ${
                selectCity.length > 0
                  ? `py-[.5em] lg:py-2 w-full border-[#b64c4c] ${
                      focus ? "flex-wrap" : "h-[40px]"
                    }`
                  : "h-[40px]"
              } w-full`}
            >
              <DisplaySelectCity
                selectCity={selectCity}
                handleDelete={handleDelete}
                focus={focus}
              />
              <input
                onFocus={() => setFocus(true)}
                value={userInput}
                onChange={handleChange}
                placeholder={"Saisir une ville ou code postal"}
                className={`focus:outline-none mr-2 w-full h-[49px] bg-transparent`}
              />
            </form>
            <ConsultationInfo
              focus={focus}
              CityList={CityList}
              selectCity={selectCity}
              setSelectCity={setSelectCity}
              setUserInput={setUserInput}
            />
          </div>
          <ConsultationOther
            focus={focus}
            isOpen={isOpen}
            toggleOptions={toggleOptions}
            options={options}
            value1={value1}
            setValue1={setValue1}
            selectCity={selectCity}
          />
          <div className={"w-fit flex items-end h-full"}>
            <button
              onClick={() => {
                handleNavigate(selectCity, value1, navigate, true);
              }}
              className={`transition-all md:flex hidden duration-300 text-white bg-dark-red items-center justify-center h-[35px] w-[35px] rounded-full`}
            >
              <IoSearch className={"text-2xl"} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
