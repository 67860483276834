import { options } from "../../interfaces/interface";
import { SAlogo } from "../../utils/utils.components";
import React from "react";
import { PropertyData } from "../../interfaces/Annonce.interface";
import { CiMap } from "react-icons/ci";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { price_format } from "../../utils/format";

/**
 * @name PriceInformation
 * @description Composant d'information de prix de la page de consultation
 * @param annonceData
 * @constructor
 */
export const PriceInformation = ({
  annonceData,
}: {
  annonceData: PropertyData;
}) => {
  return (
    <section className={"w-full h-auto gap-5 flex flex-col"}>
      <header className={"h-full w-full flex flex-col md:flex-row"}>
        <div className={"flex flex-col w-full"}>
          <div className={"flex gap-5 items-center"}>
            <h1 className="sm:text-lg font-bold">
              {
                options.find(
                  (element) => element.value === annonceData.typebien.type,
                )?.label
              }
            </h1>
            {!annonceData.info.accompanist ? <SAlogo /> : null}
          </div>
          <p className={"lg:text-base text-xs"}>
            {annonceData.compo[0].quantité} pièces -{" "}
            {annonceData.compo[1].quantité} chambres - {annonceData.info.size}m²
          </p>
          <p className={"lg:text-base text-xs"}>
            {annonceData.info.streettype} {annonceData.info.streetname},<br />
            {annonceData.info.city}
          </p>
        </div>
        <div className={"flex flex-col w-[30%] items-start md:items-end"}>
          <h1 className={"text-sm lg:text-base xl:text-xl font-bold mb-5"}>
            {price_format(annonceData.pdvc.prixdevente)}
          </h1>
          <AnchorLink href={"#map"}>
            <CiMap
              className={
                "text-2xl lg:text-4xl border rounded-full border-dark-red text-dark-red"
              }
            />
          </AnchorLink>
        </div>
      </header>
      <div className={"h-[1px] w-full bg-slate-200"}></div>
    </section>
  );
};
