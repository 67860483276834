import React from "react";
import Navbar from "../components/navbar";
import Footers from "../utils/Footers";
import { Outlet } from "react-router-dom";
import "./FlatWayPro.css";
import Geometry from "../assets/geometry/blob.svg";
import Geometry2 from "../assets/geometry/blob2.svg";
import Geometry3 from "../assets/geometry/blob3.svg";
import {
  ProContactForm,
  QrCodeFooter,
} from "../components/FlatwayPro/FlatwayPro.component";
import FlatWayProComponent, {
  ModelSection,
} from "../components/FlatwayPro/FlatwayProDisplay.component";

/**
 * @name FlatWayPro
 * @description Page FlatwayPro
 * @constructor
 */
const FlatWayPro = () => {
  window.scrollTo(0, 0);
  const sections = [
    {
      title: "Devenir Partenaire",
      desc: "",
    },
    { title: "La visite des biens", desc: "" },
    { title: "Les offres", desc: "" },
  ];

  return (
    <div className={"h-auto w-full overflow-hidden"}>
      <Navbar mod={true} />
      <div className={"h-auto w-full flex flex-col gap-5 pt-[70px]"}>
        <div className={"h-auto"}>
          <div
            className={
              "overflow-hidden sm:px-0 px-5 py-5 lg:py-0 relative h-auto pt-[70px] lg:h-[80vh] justify-center flex lg:flex-row bg-gradient-to-br  via-[#d73e3e] from-[#d73e3e] to-dark-red drop-shadow-lg flex-col gap-10 items-center"
            }
          >
            <img
              src={Geometry}
              alt={"Blob"}
              className={
                "select-none w-[30vw] absolute top-0 -left-[9vw] drop-shadow-lg"
              }
            />
            <img
              src={Geometry2}
              alt={"Blob"}
              className={
                "w-[50vw] select-none absolute bottom-[-30vw] drop-shadow-lg"
              }
            />
            <img
              src={Geometry3}
              alt={"Blob"}
              className={
                "w-[40vw] absolute bottom-0 select-none right-[-10vw] z-10 drop-shadow-lg"
              }
            />
            <header
              className={
                "z-10 w-full items-center lg:items-start pt-[70px] lg:w-[30vw] h-auto flex flex-col justify-center"
              }
            >
              <h1 className={"font-bold text-white text-clampProIntroTitle"}>
                Devenir partenaire{" "}
                <span className={"text-dark-red"}>Flatway</span>
              </h1>
              <h2 className={"font-medium text-white mt-5"}>
                Nos solutions sont faites pour vous apporter une aide au
                quotidien, faciliter vos rendez-vous de visite et simplifier la
                gestion de vos mandats de vente.
              </h2>
            </header>
            <div className={"z-10 flex h-full justify-center items-center"}>
              <ProContactForm />
            </div>
          </div>
        </div>
        <div className={"flex gap-[50px] flex-col "}>
          <ModelSection />
          {sections.map((items, index) => (
            <FlatWayProComponent
              title={items.title}
              index={index}
              desc={items.desc}
              key={index}
            />
          ))}
        </div>
        <QrCodeFooter />
        <Footers />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default FlatWayPro;
