import React from "react";
import { ScrollTemplate } from "./FlatWay/FlatWay.Other.Component";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { headerCardList } from "../interfaces/FAQ.interface";

/**
 * @name EstimationFirstSection
 * @description Première section de l'estimation dans la FAQ
 * @param title
 * @param content
 * @param isOpen
 * @param setIsOpen
 * @constructor
 */
export const EstimationFirstSection = ({
  title,
  content,
  isOpen,
  setIsOpen,
}: {
  title: string;
  content: { question: string; response: React.JSX.Element }[];
  isOpen: boolean[];
  setIsOpen: (value: boolean[]) => void;
}) => {
  return (
    <section className={"flex flex-col items-center gap-5 justify-center"}>
      <h1 className={"font-bold text-dark-red text-lg"}>{title}</h1>
      {content.map((item, index) => (
        <ScrollTemplate
          title={item.question}
          desc={item.response}
          isOpen={isOpen[index]}
          setIsOpen={(value) => {
            const updatedIsOpen = [...isOpen];
            updatedIsOpen[index] = value;
            setIsOpen(updatedIsOpen);
          }}
        />
      ))}
    </section>
  );
};

/**
 * @name HeaderCard
 * @description Carte dans le header de la FAQ
 * @param title
 * @param image
 * @param index
 * @param selectSection
 * @param setSelectSection
 * @param setIsOpen
 * @constructor
 */
export const HeaderCard = ({
  title,
  image,
  index,
  selectSection,
  setSelectSection,
  setIsOpen,
}: {
  title: string;
  image: string;
  index: number;
  selectSection: number;
  setSelectSection: (args: number) => void;
  setIsOpen: (args: boolean[]) => void;
}) => {
  return (
    <AnchorLink
      href={"#response"}
      onClick={() => {
        setSelectSection(index);
        setIsOpen(Array(headerCardList.length).fill(false));
      }}
      className={`h-[400px] ${
        selectSection === index ? "bg-light-red scale-110" : "bg-slate-50"
      } hover:scale-110 relative overflow-hidden transition-all duration-300 rounded-xl w-[300px] flex items-center justify-evenly flex-col`}
    >
      <div
        className={`rounded-wingsBlob ${
          selectSection === index ? "" : "hidden"
        } bg-darkRedTitle transition-all duration-300 z-10 animate-fade-in-down absolute -rotate-45 -top-24 -right-24 h-[200px] w-[200px]`}
      />
      <div
        className={`rounded-wingsBlob ${
          selectSection === index ? "" : "hidden"
        } bg-dark-red absolute transition-all duration-300 animate-fade-in-down -top-24 -right-24 h-[200px] w-[200px]`}
      />
      <div
        className={`rounded-wingsBlob ${
          selectSection === index ? "" : "hidden"
        } bg-lightredRgba animate-fade-in-down absolute transition-all duration-300 rotate-45 -top-24 -right-24 h-[200px] w-[200px]`}
      />
      <div className={"w-[150px] h-[150px]"}>
        <img src={image} alt={title} className={"h-full w-full"} />
      </div>
      <p className={"font-bold"}>{title}</p>
    </AnchorLink>
  );
};

/**
 * @name FAQHeader
 * @description Header de la FAQ
 * @param selectSection
 * @param setSelectSection
 * @param setIsOpen
 * @constructor
 */
export const FAQHeader = ({
  selectSection,
  setSelectSection,
  setIsOpen,
}: {
  selectSection: number;
  setSelectSection: (args: number) => void;
  setIsOpen: (args: boolean[]) => void;
}) => {
  return (
    <header
      className={
        "w-full flex relative pl-2 justify-center bg-dark-red gap-16 items-center flex-col py-10"
      }
    >
      <div
        className={
          "animate-blob2 absolute h-[200px] w-[200px] top-10 left-16 bg-white-rgba"
        }
      />
      <div
        className={
          "animate-blob absolute h-[200px] w-[250px] top-24 right-36 bg-white-rgba"
        }
      />
      <div
        className={
          "animate-blob absolute h-[200px] w-[250px] -bottom-14 right-[700px] rotate-90 bg-white-rgba"
        }
      />
      <h1 className={"text-6xl font-bold z-10 text-white"}>
        Foire aux questions
      </h1>
      <div
        className={
          "overflow-x-auto w-full overflow-y-visible h-[450px] items-center flex justify-start lg:justify-center"
        }
      >
        <section className={"flex gap-10 z-10"}>
          {headerCardList.map((card, index) => (
            <HeaderCard
              key={index}
              title={card.title}
              image={card.image}
              index={index}
              selectSection={selectSection}
              setSelectSection={setSelectSection}
              setIsOpen={setIsOpen}
            />
          ))}
        </section>
      </div>
    </header>
  );
};
