import { Partner } from "../../interfaces/Annonce.interface";
import React from "react";
import { SAlogo } from "../../utils/utils.components";

/**
 * @name PartnerComponent
 * @description Composant de partenaire de la page d'annonce
 * @param Partner
 * @param accompanist
 * @constructor
 */
export const PartnerComponent = ({
  Partner,
  accompanist,
}: {
  Partner: Partner;
  accompanist: boolean;
}) => {
  return (
    <div className={"w-full h-auto"}>
      <h1 className={"text-base lg:text-2xl font-bold mb-5"}>Vendu par</h1>
      <section
        className={"h-fit w-full bg-slate-50 p-2 flex items-center gap-5 group"}
      >
        <div
          className={
            "md:h-[10vw] h-[20vw] w-[20vw] md:w-[10vw] overflow-hidden flex justify-center items-center"
          }
        >
          <img src={Partner.logo} alt={"Partner Logo"} />
        </div>
        <div className={"flex flex-col justify-center gap-2 h-full"}>
          <h2 className={"font-bold text-sm lg:text-xl"}>
            {Partner.commercialname}
          </h2>
          <h3 className={"lg:text-base text-xs"}>{Partner.fulladdress}</h3>
          {!accompanist ? (
            <div className={"flex items-center gap-1"}>
              <SAlogo />
              <span className={"text-dark-red text-xs lg:text-base"}>
                Vente sans accompagnateur
              </span>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
};
