import React from "react";
import { PreseText } from "../../../utils/FlatWay.utils";

/**
 * @name IntroBubble
 * @description Bubble d'introduction de la page Flatway Pro sur mobile
 * @constructor
 */
const IntroBubble = () => {
  const text = [
    "Agences immobilières",
    "Mandataires immobilliers",
    "Notaires",
    "Sydics de copropriété",
  ];
  const sellerText = [
    "Choisit dans la liste un partenaire pour la vente de son bien",
    "Sélectionne un type d’honoraires : taux réduits, assure lui-même les visites ou taux standards, confie les visites au partenaire choisi",
    "Enregistre par simples clics son annonce",
    "Signe électroniquement le mandat de vente en faveur du partenaire choisi",
  ];
  const buyerText = [
    "Consulte les annonces",
    "Visite les biens accompagné par le partenaire ou uniquement par le vendeur si ce dernier a choisi la vente sans accompagnateur.",
  ];
  return (
    <div className={"flex flex-col gap-5 text-[12px] font-semibold"}>
      <div
        className={
          "bg-dark-red p-2 text-white text-[12px] font-semibold rounded w-full"
        }
      >
        <p>
          Flatway est un ensemble de solutions technologiques de transformation
          digitale du métier de l’agent immobilier. La transformation digitale
          permet aux partenaire de l’immobilier d’optimiser leurs coûts de
          fonctionnement et de proposer des services moins onéreux aux
          utilisateurs.
        </p>
      </div>
      <p>
        Flatway Pro et l’application Flatway réservée aux particuliers,
        constituent la plateforme immobilière de mise en relation Vendeurs -
        Partenaires - Acheteurs.
      </p>
      <p>
        Flatway ne vend pas de l’immobilier, les honoraires sont à payer
        directement, par les vendeurs, aux partenaire le jour de la signature
        chez le notaire.
      </p>
      <p>
        L’application Flatway Pro est réservée exclusivement aux partenaire de
        l’immobilier :
      </p>
      <span className={"w-full flex justify-center sm:justify-center"}>
        <div className="bg-[#F4E5E5] w-full h-full flex flex-col items-center justify-center gap-[10px] text-wrap p-2">
          {text.map((items, index) => (
            <PreseText text={items} key={index} />
          ))}
        </div>
      </span>
      <p>
        L’application Flatway réservée aux particuliers offre aux vendeurs et
        aux acheteurs, une démarche avantageuse et simplifiée pour vendre ou
        acheter un bien immobilier :
      </p>
      <div className="h-[250px] w-full sm:px-[25%]">
        <h1 className="font-bold text-[20px] sm:text-[30px] text-[#980000]">
          Vendeur
        </h1>
        <div className={"w-full flex justify-end sm:justify-center"}>
          <div className="bg-[#F4E5E5] w-[90%] sm:w-full h-[70%] flex flex-col items-center justify-center gap-[10px] text-wrap p-2">
            {sellerText.map((items, index) => (
              <PreseText text={items} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="h-[180px] w-full sm:px-[25%] flex flex-col items-end">
        <h1 className="font-bold text-[20px] sm:text-[30px] text-[#980000]">
          Acheteur
        </h1>
        <span className={"w-full flex justify-start sm:justify-center"}>
          <div className="bg-[#F4E5E5] w-[90%] sm:w-full h-full flex flex-col items-center justify-center gap-[10px] text-wrap p-2">
            {buyerText.map((items, index) => (
              <PreseText text={items} key={index} />
            ))}
          </div>
        </span>
      </div>
    </div>
  );
};

/**
 * @name AdvantageBubble
 * @description Bubble d'avantages de la page Flatway Pro
 * @constructor
 */
const AdvantageBubble = () => {
  const PartnerTitle = ({ index, title }: { index: number; title: string }) => {
    return (
      <h1 className={"text-[14px] font-medium"}>
        <span className={"font-bold text-dark-red text-[28px]"}>{index}</span>
        {title}
      </h1>
    );
  };
  return (
    <div
      className={
        "h-full w-full flex flex-col sm:flex-row sm:items-center justify-center sm:justify-end"
      }
    >
      <span className={"text-[12px] font-semibold flex flex-col gap-5"}>
        <p>
          Tout partenaire immobilier détenant une carte de transaction ou de
          gestion peut s’inscrire sur l’application Flatway Pro lorsque le
          service est disponible dans sa commune.
        </p>
        <p>
          L’inscription sur l’application est soumise, conformément à la
          législation en cours, à la vérification de certains documents et
          informations ; le partenaire et Flatway signent ensuite un contrat de
          partenariat.
        </p>
      </span>
      <div className={"text-[12px] font-medium"}>
        <PartnerTitle index={1} title={"Inscription partenaire"} />
        <ol className={"list-decimal list-inside"}>
          <li>Enregistrer un mobile principal pour administrer le compte</li>
          <li>Renseigner, par simples clics :</li>
          <ol className={"list-disc list-inside"}>
            <li>Coordonnées de l’enseigne</li>
            <li>
              Nombre de collaborateurs à inscrire et délimiter le périmètre
              d’activité
            </li>
            <li>
              Barème d’honoraires à taux standards et/ou un barème d’honoraires
              à taux réduits
            </li>
          </ol>
          <li>
            Signer électroniquement le contrat de partenariat avec Flatway
          </li>
        </ol>
        <div className={"bg-light-red p-2 rounded"}>
          <ol>
            <li className="before:content-['*'] before:mr-2">
              Honoraires à taux réduits, visites assurées par le vendeur sans la
              présence du partenaire ; visite Sans accompagnateur
            </li>
            <li className="before:content-['*'] before:mr-2">
              Honoraires standards, visites assurées par le partenaire ; visite
              Avec accompagnateur
            </li>
          </ol>
          <p className={"text-dark-red"}>
            Dans les deux cas, le partenaire assure suivi et conseil, et permet
            aux acheteurs intéressés de consulter les documents relatifs au bien
          </p>
        </div>
        <span>
          <PartnerTitle index={2} title={"Les avantages avec Flatway Pro"} />
          <ol className={"list-decimal list-inside"}>
            <li>Gagner une nouvelle clientèle</li>
            <li>Optimiser les coûts de fonctionnement</li>
            <li>
              Signer électroniquement le mandat de vente sans exclusivité avec
              les vendeurs
            </li>
            <li>Gérer les rendez-vous et visites à partir de l’espace dédié</li>
            <li>
              Consulter la liste des biens vendus à une adresse donnée et à
              proximité
            </li>
            <li>Utiliser notre estimation des biens </li>
          </ol>
        </span>
        <span>
          <PartnerTitle index={3} title={"Les frais de services"} />
          <ol className={"list-decimal list-inside"}>
            <li>
              Les services Flatway sont gratuits pour les utilisateurs, vendeurs
              et acheteurs.
            </li>
            <li>
              Le partenaire verse à Flatway des frais de services seulement si
              la vente est signée chez le notaire
            </li>
          </ol>
        </span>
      </div>
    </div>
  );
};

/**
 * @name PartnerBubble
 * @description Bubble de partenaire de la page Flatway Pro sur mobile
 * @constructor
 */
const PartnerBubble = () => {
  return (
    <div className={"h-full w-full flex flex-col items-center justify-evenly"}>
      <div className="w-full flex justify-center">
        <div className="w-[970px] text-[12px] sm:text-[14px] font-medium">
          La plateforme offre aux parties,{" "}
          <span className="font-bold">acheteur, vendeur et partenaire</span>,
          <h2 className="text-[12px] sm:text-[14px] text-justify">
            une solution complète de gestion des visites, solution regroupée
            dans un espace dédié : ‘Mes échanges’.
          </h2>
          <br></br>
          <h2 className="text-[12px] sm:text-[14px] text-justify">
            De la demande de visite formulée par l’acheteur jusqu’au rendez-vous
            de signature chez le notaire, les services de la plateforme
            échangent avec les parties :
          </h2>
          <div className="bg-[#F4E5E5] p-2">
            <ul className="text-[12px] sm:text-[14px] list-disc list-inside">
              <li>
                Si le vendeur a opté pour une vente sans accompagnateur, il
                assure lui-même les visites, les services Flatway échangent avec
                <span className="font-bold">l’acheteur et le vendeur</span>. Le
                partenaire est tenu informé de chaque échange
              </li>
              <li>
                Si le vendeur a opté pour la solution avec accompagnateur, il
                confie la visite au partenaire, les services Flatway échangent
                avec{" "}
                <span className="font-bold">l’acheteur et le partenaire</span>.
                Le vendeur est tenu informé de chaque échange
              </li>
            </ul>
          </div>
          <h3 className="text-[12px] mt-8">
            A noter : Par mesure de sécurité, l'application ne fournit aux
            acquéreurs ni l'adresse du bien ni les codes d'accès de l'immeuble.
            Seul un point de rencontre, choisi par le vendeur (ou le
            professionnel), est communiqué à l’acheteur.
          </h3>
        </div>
      </div>
      <span className="flex flex-col w-full">
        <span className="bg-dark-red rounded-t-lg p-2">
          <h1 className="text-white font-bold text-[20px]">La visite</h1>
        </span>
        <div
          className={
            "h-[60%] border w-full sm:w-full flex flex-col sm:flex-row justify-between items-center px-[20px] sm:px-[200px] gap-3 sm:mt-[50px]"
          }
        >
          <div className={"w-full sm:w-auto flex items-center"}>
            <div
              className={
                "bg-white sm:drop-shadow-lg h-[250px] w-[450px] sm:p-2"
              }
            >
              <h1 className="text-[#980000] font-bold mb-5">
                La visite planifiée
              </h1>
              <ul className="flex flex-col gap-3 text-[14px] text-justify">
                <li>
                  La date de visite confirmée est placée en Planifier dans
                  l’espace ‘Mes Echanges’ des parties.
                </li>
                <li>
                  Si une des parties annule ou déplace le rendez-vous, les
                  autres parties sont aussitôt notifiés.
                </li>
                <li>
                  La veille de la visite, des notifications de rappels sont
                  envoyées aux parties.
                </li>
              </ul>
            </div>
          </div>
          <div
            className={
              "h-full w-full  sm:w-auto flex flex-col justify-evenly gap-5"
            }
          >
            <div
              className={
                "bg-white sm:drop-shadow-lg sm:p-2 h-[250px] w-full sm:w-[450px]"
              }
            >
              <h1 className="text-[#980000] font-bold mb-5">
                La demande de visite
              </h1>
              <ul className="flex flex-col gap-3 text-[14px] text-justify">
                <li>
                  L’acheteur consulte les annonces et propose des dates pour
                  visiter un bien.
                </li>
                <li>
                  La demande de visite est placée{" "}
                  <span className="text-dark-red font-bold">En attente</span>{" "}
                  dans l’espace « Mes Echanges » de l’acheteur, du vendeur et du
                  partenaire. Le vendeur et le partenaire sont alertés par
                  notifications.
                </li>
                <li>
                  La veille de la visite, des notifications de rappels sont
                  envoyées aux parties.
                </li>
              </ul>
            </div>
            <div
              className={
                "bg-white sm:drop-shadow-lg sm:p-2 h-[300px] w-full sm:w-[450px]"
              }
            >
              <h1 className="text-[#980000] font-bold mb-5">La visite</h1>
              <ul className="flex flex-col gap-3 text-[14px] text-justify">
                <li>
                  L’acheteur et le vendeur (ou le professionnel) doivent
                  confirmer leur présence
                </li>
                <li>
                  Après confirmation des parties, un point de rencontre, choisi
                  par le vendeur (ou le professionnel), est communiqué à
                  l’acheteur.
                </li>
                <li>
                  Pour confirmer la visite et contrôler l’identité, un QR code
                  sera utilisé.
                </li>
                <li>
                  Confirmer la visite et l’identité avec le QR code accessible
                  depuis l’espace de visite
                </li>
              </ul>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

/**
 * @name SellerBubble
 * @description Bubble de vendeur de la page Flatway Pro sur mobile
 * @constructor
 */
const SellerBubble = () => {
  return (
    <div className={"h-full w-full flex flex-col items-center justify-evenly"}>
      <p className="text-[12px] sm:text-[14px]">
        Acheteur et vendeur{" "}
        <span className="font-bold">
          négocient directement sans l’intermédiaire du partenaire
        </span>
        .
      </p>
      <span className={"text-[12px] bg-light-red p-2"}>
        <p>Après la visite l’acheteur intéressé formule une offre d’achat :</p>
        <ol className="list-disc list-inside">
          <li>Le vendeur et le partenaire sont alors informés.</li>
          <li>
            Le vendeur, accepte l’offre, refuse ou formule une contre-offre de
            vente.
          </li>
          <li>
            A son tour, l’acheteur accepte, refuse ou formule une nouvelle
            offre.
          </li>
        </ol>
        <span>
          In fine,
          <ol className={"list-disc list-inside"}>
            <li>
              L’offre d’achat ou de vente est acceptée, auquel cas, le
              partenaire prend le relais et transmet le dossier au notaire.
            </li>
            <li>
              Ou bien, la dernière offre est refusée ; la négociation est
              terminée.
            </li>
          </ol>
        </span>
      </span>
      <p className={"text-[12px]"}>
        Le partenaire aura un accès instantané aux offres et au mode de
        financement. Il intervient uniquement comme conseil pour les parties.
      </p>
    </div>
  );
};

/**
 * @name FlatWayProComponentPhone
 * @description Composant de la page Flatway Pro sur mobile
 * @param title
 * @param index
 * @constructor
 */
export const FlatWayProComponentPhone = ({
  title,
  index,
}: {
  title: string;
  index: number;
}) => {
  const bubbleList = [
    <IntroBubble />,
    <AdvantageBubble />,
    <PartnerBubble />,
    <SellerBubble />,
  ];

  return (
    <div
      key={index}
      className={"h-auto w-full flex sm:items-center flex-col sm:px-0 px-2"}
    >
      <h1
        className={
          "font-bold z-[10001] sticky top-5 transition-transform duration-500 ease-in-out sm:block hidden"
        }
      >
        {title}
      </h1>
      <h1
        className={"font-bold text-dark-red top-5 sm:hidden block text-[20px]"}
      >
        {title}
      </h1>
      {bubbleList[index]}
    </div>
  );
};
