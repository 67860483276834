import { AiOutlineClose } from "react-icons/ai";
import React from "react";
import { PropertyData } from "../../interfaces/Annonce.interface";
import { CaractIcon } from "../../interfaces/Caract.Icon";
import Modal from "../../utils/Modal";

/**
 * @name ImageModal
 * @description Composant de modal d'image de la page d'annonce
 * @param imageModal
 * @param setImageModal
 * @param annonceData
 * @constructor
 */
export const ImageModal = ({
  imageModal,
  setImageModal,
  annonceData,
}: {
  imageModal: boolean;
  setImageModal: (args: boolean) => void;
  annonceData: PropertyData;
}) => {
  return (
    <Modal isOpen={imageModal} setIsOpen={setImageModal}>
      <div
        className={
          "h-full w-full sm:w-[60%] bg-white p-3 flex flex-col rounded items-center"
        }
      >
        <header className={"w-full flex h-[30px]"}>
          <button onClick={() => setImageModal(false)}>
            <AiOutlineClose className={"text-[20px]"} />
          </button>
        </header>
        <div className={"h-full w-full overflow-y-scroll flex flex-col gap-2"}>
          {annonceData.picture.map((picture, index) => {
            console.log(picture.picturepath);
            return (
              <img
                key={index}
                src={picture.picturepath}
                alt={index.toString()}
                className={"h-auto w-full object-cover"}
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

/**
 * @name CaractModal
 * @description Composant de modal de caractéristique de la page d'annonce
 * @param annonceData
 * @param setCaractModal
 * @param caractModal
 * @constructor
 */
export const CaractModal = ({
  annonceData,
  setCaractModal,
  caractModal,
}: {
  annonceData: PropertyData;
  setCaractModal: React.Dispatch<React.SetStateAction<boolean>>;
  caractModal: boolean;
}) => {
  console.log(annonceData);
  return (
    <Modal isOpen={caractModal} setIsOpen={setCaractModal}>
      <div
        className={
          "bg-white w-full sm:w-[50%] max-h-[90%] overflow-y-scroll p-2 px-7 rounded-lg"
        }
      >
        <div className={"w-full flex flex-row justify-between mb-5"}>
          <div></div>
          <h1 className={"font-bold text-[20px]"}>Détail du bien</h1>
          <button onClick={() => setCaractModal(false)}>
            <AiOutlineClose className={"text-[20px]"} />
          </button>
        </div>
        <div className={"flex flex-col justify-between gap-5"}>
          <div>
            <h1 className={"font-bold text-start"}>Composition</h1>
            <div className={"grid grid-cols-2 grid-flow-row"}>
              {annonceData.compo.map(({ libelle_fr, quantité }) => {
                return (
                  <h2 key={libelle_fr} className={"text-start m-1"}>
                    {quantité > 1 && quantité} {libelle_fr}
                  </h2>
                );
              })}
              {annonceData.compod.map(({ libelle_fr, taille }) => {
                let icon = CaractIcon.find((icon) => icon.title === libelle_fr);
                return (
                  <span
                    key={libelle_fr}
                    className={"text-start flex items-center"}
                  >
                    {icon?.icon}
                    {libelle_fr} {taille ? `${taille}m²` : ""}
                  </span>
                );
              })}
            </div>
          </div>
          <div>
            <h1 className={"font-bold text-start"}>Construction</h1>
            {annonceData.constru.map(({ libelle_fr }) => {
              return (
                <h2 key={libelle_fr} className={"text-start m-1"}>
                  {libelle_fr}
                </h2>
              );
            })}
          </div>
          <div>
            <h1 className={"font-bold text-start"}>Exposition</h1>
            {annonceData.exposure.map(({ libelle_fr }) => {
              return (
                <h2 key={libelle_fr} className={"text-start m-1"}>
                  {libelle_fr}
                </h2>
              );
            })}
          </div>
          <div>
            <h1 className={"font-bold text-start"}>Chauffage</h1>
            {annonceData.heating.map(({ libelle_fr }) => {
              return (
                <h2 key={libelle_fr} className={"text-start m-1"}>
                  {libelle_fr}
                </h2>
              );
            })}
          </div>
          <div>
            <h1 className={"font-bold text-start"}>Cuisine</h1>
            {annonceData.kitchen.map(({ libelle_fr }) => {
              return (
                <h2 key={libelle_fr} className={"text-start m-1"}>
                  {libelle_fr}
                </h2>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
