import annonce from "../assets/aboutus/annonce.png";
import estimation from "../assets/aboutus/estimation.png";
import { FaPlay } from "react-icons/fa";
import phone from "../assets/phone_esti.png";
import Geo4 from "../assets/geometry/blob (3).svg";
import React from "react";
import Tmp from "../assets/Paris.jpg";
import tmp2 from "../assets/Paris_IA.jpg";
import teamImg from "../assets/shutterstock_1903260772.jpg";

/**
 * @name AboutUsHeader
 * @description Header de la page à propos de Flatway
 * @param setIsOpen
 * @constructor
 */
export const AboutUsHeader = ({
  setIsOpen,
}: {
  setIsOpen: (args: boolean) => void;
}) => {
  return (
    <main
      className={
        "w-full flex flex-col items-center justify-end gap-10 relative h-[93vh]"
      }
    >
      <header
        className={
          "w-full flex-col flex items-center justify-center relative gap-5"
        }
      >
        <h1 className={"text-2xl font-semibold text-dark-red"}>
          À propos de Flatway
        </h1>
        <h2 className={"text-3xl font-bold"}>
          Nous nous employons à réduire les coûts, à simplifier et sécuriser{" "}
          <br />
          la transaction immobilière.
        </h2>
      </header>
      <div
        className={
          "h-[60%] w-full relative flex items-start overflow-hidden justify-center"
        }
      >
        <div
          className={
            "absolute w-full bottom-0 -z-10 rounded-full blur-3xl h-10 bg-dark-red"
          }
        ></div>
        <img
          alt={"annonce"}
          src={annonce}
          className={
            "absolute h-[300px] drop-shadow-xl rounded-xl object-cover left-1/4 opacity-80 bottom-1/4"
          }
        />
        <img
          alt={"estimation"}
          src={estimation}
          className={
            "absolute h-[300px] drop-shadow-xl rounded-xl object-cover right-96 opacity-80 bottom-8"
          }
        />
        <div
          className={
            "absolute h-full w-[380px] bg-white px-10 flex items-center justify-center"
          }
        >
          <button
            onClick={() => setIsOpen(true)}
            className={
              "w-full h-20 hover:scale-105 font-medium text-dark-red transition-all duration-300 hover:grayscale-0 grayscale bg-light-red flex items-center rounded-full gap-5 px-2"
            }
          >
            <div
              className={
                "h-16 w-16 rounded-full bg-white flex items-center justify-center"
              }
            >
              <FaPlay />
            </div>
            <p>Flatway en vidéo</p>
          </button>
        </div>
        <img
          alt={"phone"}
          src={phone}
          className={"w-[400px] z-20 pointer-events-none object-cover"}
        />
      </div>
      <img
        src={Geo4}
        className={"absolute h-[1000px] -z-10 -right-40 bottom-0 sm:-right-96"}
        alt={"geo header"}
      />
    </main>
  );
};

/**
 * @name AboutTitle
 * @description Titre de la section à propos de Flatway
 * @param text
 * @constructor
 */
export const AboutTitle = ({ text }: { text: string }) => {
  return (
    <header className={"w-full"}>
      <h1 className={"font-bold text-xl lg:text-5xl text-black"}>{text}</h1>
    </header>
  );
};

/**
 * @name HistorySection
 * @description Section de l'historique de Flatway
 * @constructor
 */
export const HistorySection = () => {
  return (
    <div className={"h-auto pt-10 w-full flex items-end gap-10"}>
      <main className={"w-[50vw] h-[50vh] rounded-r-xl overflow-hidden"}>
        <img alt={"prese"} src={Tmp} className={"w-full h-full object-cover"} />
      </main>
      <aside
        className={
          "h-auto min-w-[300px] text-darkRedTitle w-[30vw] bg-[#ffe6e6] rounded-xl flex flex-col gap-1 p-7"
        }
      >
        <h1 className={"text-6xl font-bold"}>Flatway</h1>
        <ul className={"flex flex-col gap-10"}>
          <li> est une startup Française créée en 2020.</li>
          <li>
            Elle propose une plateforme 100% digitalisée à destination des
            vendeurs et acheteurs de biens immobiliers en collaboration avec les
            professionnels du secteur.
          </li>
        </ul>
      </aside>
    </div>
  );
};

/**
 * @name ObjectifSection
 * @description Section de l'objectif de Flatway
 * @constructor
 */
export const ObjectifSection = () => {
  return (
    <div className={"h-auto w-full flex items-start justify-end gap-10"}>
      <aside
        className={"h-auto w-[30vw] min-w-[300px] bg-light-red rounded-xl p-7"}
      >
        <AboutTitle text={"Notre objectif"} />
        <p>
          Notre objectif est de garantir la réussite de vos projets immobiliers
          en vous offrant une solution complète s’appuyant sur le professionnel
          de votre choix.
        </p>
      </aside>
      <main className={"w-[50vw] h-[50vh] rounded-l-xl overflow-hidden"}>
        <img
          src={tmp2}
          className={"h-full w-full object-cover"}
          alt={"objectif"}
        />
      </main>
    </div>
  );
};

/**
 * @name PointSection
 * @description Section des 3 fondamentaux de Flatway
 * @constructor
 */
export const PointSection = () => {
  return (
    <div
      className={
        "h-auto flex justify-center xl:flex-row flex-col items-center py-10 bg-bg-red w-full"
      }
    >
      <header>
        <h1 className={"font-bold text-xl lg:text-5xl text-darkRedTitle"}>
          Nos 3 fondamentaux
        </h1>
      </header>
      <main
        className={
          "gap-5 flex flex-wrap p-10 text-light-red rounded-xl w-[60vw]"
        }
      >
        <section className={"lg:w-[350px]"}>
          <span className={"flex items-center gap-2"}>
            <span className={"relative"}>
              <div
                className={
                  "h-[1rem] w-[1rem] bg-dark-red absolute rounded-full animate-pingSlow opacity-70"
                }
              />
              <div
                className={
                  "h-[1rem] w-[1rem] bg-dark-red relative rounded-full"
                }
              />
            </span>
            <p className={"font-semibold lg:text-base text-sm text-dark-red"}>
              La Transparence
            </p>
          </span>
          <p className={"lg:text-sm text-xs "}>
            Nous œuvrons a établir un environnement de transparence entre les
            utilisateurs et les professionnels de l’immobilier
          </p>
        </section>
        <section className={"lg:w-[350px]"}>
          <span className={"flex items-center gap-2"}>
            <span className={"relative"}>
              <div
                className={
                  "h-[1rem] w-[1rem] bg-[#c16666] absolute rounded-full animate-pingSlow opacity-70"
                }
              />
              <div
                className={
                  "h-[1rem] w-[1rem] bg-[#c16666] relative rounded-full"
                }
              />
            </span>
            <p className={"font-semibold text-dark-red lg:text-base text-sm"}>
              Le professionnalisme
            </p>
          </span>
          <p className={"lg:text-sm text-xs "}>
            Nous mettons à disposition des services faits par et pour des
            professionnels.
          </p>
        </section>
        <section className={"lg:w-[350px]"}>
          <span className={"flex items-center gap-2"}>
            <span className={"relative"}>
              <div
                className={
                  "h-[1rem] w-[1rem] bg-light-red absolute rounded-full animate-pingSlow opacity-70"
                }
              />
              <div
                className={
                  "h-[1rem] w-[1rem] bg-light-red relative rounded-full"
                }
              />
            </span>
            <p className={"font-semibold text-dark-red lg:text-base text-sm"}>
              La simplicité
            </p>
          </span>
          <p className={"lg:text-sm text-xs "}>
            Nous proposons une solution simplifiée et simplifiante de
            l’immobilier.{" "}
          </p>
        </section>
        <p className={"lg:w-[400px] lg: text-xs lg:text-sm"}>
          Tout en répondant à la nécessité de la modernisation et de la
          digitalisation de ce secteur, nous vous proposons une approche
          novatrice de l'immobilier en vous offrant la possibilité d’opter pour
          le choix de visites assurées par vos soins.
        </p>
      </main>
    </div>
  );
};

/**
 * @name TeamSection
 * @description Section de l'équipe de Flatway
 * @constructor
 */
export const TeamSection = () => {
  return (
    <div
      className={
        "h-auto py-10 flex lg:flex-row flex-col gap-28 items-center  px-2 xl:px-[10%]"
      }
    >
      <span>
        <AboutTitle text={"Notre équipe"} />
        <div className={"w-[40px] h-[5px] bg-dark-red"} />
        <section className={"flex gap-5 justify-center mt-14 w-full xl:w-auto"}>
          <p className={"lg:w-[30vw]"}>
            Flatway a été développée et animée par une équipe d’ingénieurs
            formés au sein d’écoles françaises de référence dans le monde de la
            Tech et porte les valeurs de l'éco responsabilité.
          </p>
        </section>
      </span>
      <aside>
        <img
          src={teamImg}
          alt={"team"}
          className={"w-[600px] rounded-customBlobImage"}
        />
      </aside>
    </div>
  );
};

/**
 * @name NumbersSection
 * @description Section des chiffres de Flatway (pour le moment cacher)
 * @constructor
 */
export const NumbersSection = () => {
  return (
    <div className={"h-auto py-10 flex-col px-[20%] hidden"}>
      <AboutTitle text={"Quelques chiffre"} />
      <div
        className={
          "flex lg:flex-row flex-col justify-between sm:gap-0 gap-5 lg:h-[100px] lg:items-center"
        }
      >
        <section>
          <p className={"font-bold text-lg lg:text-2xl"}>Ventes réaliser</p>
          <p className={"font-semibold text-lg"}>158 152</p>
        </section>
        <div className={"h-[1px] lg:h-full w-full lg:w-[1px] bg-black"}></div>
        <section>
          <p className={"font-bold text-lg lg:text-2xl"}>
            Nombres de partenaires
          </p>
          <p className={"font-semibold text-lg"}>1 502</p>
        </section>
        <div className={"h-[1px] lg:h-full w-full lg:w-[1px] bg-black"}></div>
        <section>
          <p className={"font-bold text-lg lg:text-2xl"}>Nombre d'employer</p>
          <p className={"font-semibold text-lg"}>72</p>
        </section>
      </div>
    </div>
  );
};
