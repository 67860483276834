const ConsultationAPI = async (searchUrl: string, mod: boolean) => {
  try {
    const response = await fetch(searchUrl, {
      method: "GET",
    });
    console.log(response);
    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return mod ? data.annonces : data.result;
    } else {
      console.log("API request failed with status:", response.status);
    }
  } catch (error) {
    console.log("Error Consult:", error);
    return [];
  }
};

export const AnnonceAPI = async (searchUrl: string) => {
  try {
    const response = await fetch(searchUrl, {
      method: "GET",
    });
    console.log(response);
    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data;
    } else {
      console.log("API request failed with status:", response.status);
    }
  } catch (error) {
    console.log("Error Consult 2:", error);
  }
};

export default ConsultationAPI;
