import { useEffect, useRef, useState } from "react";
import styles from "./select.module.css";

/**
 * @name SelectOption
 * @description Type de bien
 */
export type SelectOption = {
  label: string;
  value: string | number;
};

type MultipleSelectProps = {
  multiple: true;
  value: SelectOption[];
  onChange: (value: SelectOption[]) => void;
};

type SingleSelectProps = {
  multiple?: false;
  value?: SelectOption;
  onChange: (value: SelectOption | undefined) => void;
};

type SelectProps = {
  options: SelectOption[];
  isOpen: boolean;
  setIsOpen: () => void;
  focus: boolean;
  mod: boolean;
} & (SingleSelectProps | MultipleSelectProps);

/**
 * @name Select
 * @description Select component, choix multiple ou non du type de bien
 * @param multiple boolean
 * @param value list d'options selectionnée
 * @param onChange fonction qui change la valeur du type de bien
 * @param options type de bien (pour le moment il n'y a que maison et appartement)
 * @param isOpen boolean qui gere l'ouverture de la liste
 * @param setIsOpen fonction qui change la valeur de isOpen
 * @param mod boolean qui gere le style
 * @constructor
 */
export function Select({
  multiple,
  value,
  onChange,
  options,
  isOpen,
  setIsOpen,
  mod,
}: SelectProps) {
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const isValueEmpty = multiple ? value.length === 0 : value === undefined;

  function clearOptions() {
    multiple ? onChange([]) : onChange(undefined);
  }

  function selectOption(option: SelectOption) {
    if (multiple) {
      if (value.includes(option)) {
        onChange(value.filter((o) => o !== option));
      } else {
        onChange([...value, option]);
      }
    } else {
      if (option !== value) onChange(option);
    }
  }

  function isOptionSelected(option: SelectOption) {
    return multiple ? value.includes(option) : option === value;
  }

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0);
  }, [isOpen]);

  useEffect(() => {
    const tmp = containerRef.current;
    const handler = (e: KeyboardEvent) => {
      if (e.target !== containerRef.current) return;
      switch (e.code) {
        case "Enter":
        case "Space":
          setIsOpen();
          if (isOpen) selectOption(options[highlightedIndex]);
          break;
        case "ArrowUp":
        case "ArrowDown": {
          if (!isOpen) {
            setIsOpen();
            break;
          }

          const newValue = highlightedIndex + (e.code === "ArrowDown" ? 1 : -1);
          if (newValue >= 0 && newValue < options.length) {
            setHighlightedIndex(newValue);
          }
          break;
        }
        case "Escape":
          setIsOpen();
          break;
      }
    };
    tmp?.addEventListener("keydown", handler);

    return () => {
      tmp?.removeEventListener("keydown", handler);
    };
  }, [isOpen, highlightedIndex, options]);

  return (
    <div
      ref={containerRef}
      onClick={() => setIsOpen()}
      tabIndex={0}
      className={`${
        mod
          ? "min-h-[30px] h-[40px] w-full"
          : `w-[90%] md:w-full h-full ${isOpen ? "flex-col" : ""} sm:block`
      } relative cursor-pointer select-none`}
    >
      <div
        className={`h-full w-full flex justify-center relative items-center space-x-2 rounded-lg bg-white p-2 ${
          mod ? "rounded-2xl z-[2] border border-[#BABABA]" : ""
        }`}
      >
        <span className={styles.value}>
          {isValueEmpty ? (
            <div className="text-[#BABABA]">Type de bien</div>
          ) : multiple ? (
            value.map((v) => (
              <button
                key={v.value}
                onClick={(e) => {
                  e.stopPropagation();
                  selectOption(v);
                }}
                className={`${
                  mod
                    ? "flex items-center border border-[#BABABA] h-[30px] rounded cursor-pointer outline-none bg-none gap-[.25em] px-[10px] text-[13px]"
                    : "text-sm bg-slate-50 rounded-lg hover:bg-light-red transition-all duration-300 px-2 py-1.5 flex gap-2 items-center"
                }`}
              >
                {v.label}
                <span className={styles["remove-btn"]}>&times;</span>
              </button>
            ))
          ) : (
            value?.label
          )}
        </span>
        <button
          onClick={(e) => {
            e.stopPropagation();
            clearOptions();
          }}
          className={styles["clear-btn"]}
        >
          &times;
        </button>
      </div>
      <ul
        className={`${
          mod
            ? `m-h-[15em] overflow-y-auto border border-[#BABABA] rounded-[5px] w-full bg-white text-[15px] dis ${
                isOpen ? "block" : "hidden"
              }`
            : `${styles.options}`
        } ${isOpen ? styles.show : ""} `}
      >
        {options.map((option, index) => (
          <li
            onClick={(e) => {
              e.stopPropagation();
              selectOption(option);
              setIsOpen();
            }}
            onMouseEnter={() => setHighlightedIndex(index)}
            key={option.value}
            className={`${styles.option} ${
              isOptionSelected(option) ? styles.selected : ""
            } ${index === highlightedIndex ? styles.highlighted : ""}`}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
}
