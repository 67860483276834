import { estimationInfo } from "../Home-component/Estimation";
import { BienImmo } from "../../interfaces/interface";
import React, { useRef, useState } from "react";
import { formatDate, price_format } from "../../utils/format";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FaHouse, FaTriangleExclamation } from "react-icons/fa6";
import { DisplayIndice } from "./DisplayResult";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SectionIdEnum } from "../Home-component/types/section-id";
import Logo from "../../assets/appIcon.png";
import { EstimationData } from "../../interfaces/Estimer.interface";

/**
 * @name BVScreen
 * @description Ecran de biens vendus
 * @param bienVendu
 * @constructor
 */
export const BVScreen = ({ bienVendu }: { bienVendu: BienImmo[] | null }) => {
  const [distanceInput, setDistance] = useState(300);

  return (
    <div className={"h-[75%] w-full"}>
      <div className={"h-full w-auto px-2"}>
        <header className={"h-[20%] w-full flex flex-col items-center gap-2"}>
          <h1 className={"cursor-default text-start text-clampPhone"}>
            Rayon autour de cette adresse
          </h1>
          <input
            onChange={(e) => {
              setDistance(parseInt(e.target.value));
            }}
            value={distanceInput}
            type="range"
            className="slider"
            min={0}
            max={300}
          />
          <span className="text-[#980000] ml-2 text-clampPhone rounded shadow p-1">
            {distanceInput}m
          </span>
        </header>
        <div className={"h-[85%] w-full py-2 bg-slate-50"}>
          {bienVendu ? (
            bienVendu.length > 0 ? (
              <div className="h-[100%] w-full shadow-inner overflow-y-auto scroll-smooth">
                {bienVendu.map((item, index) => {
                  const ville = item.nom_commune.split(" ")[0];
                  if (item.distance < distanceInput / 1000) {
                    return (
                      <DisplayInfo item={item} index={index} ville={ville} />
                    );
                  }
                  return null;
                })}
              </div>
            ) : (
              <DiplsayNoData />
            )
          ) : (
            <DisplayLoading />
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * @name EstimationScreen
 * @description Ecran d'estimation
 * @param estimationValue
 * @param data
 * @param setScreen
 * @param handleBV
 * @param clicked
 * @param setClicked
 * @param PhoneFocus
 * @param setPhoneFocus
 * @constructor
 */
export const EstimationScreen = ({
  estimationValue,
  data,
  setScreen,
  handleBV,
  clicked,
  setClicked,
  PhoneFocus,
  setPhoneFocus,
}: {
  estimationValue: EstimationData;
  data: estimationInfo;
  setScreen: (args: number) => void;
  handleBV: (args: string) => void;
  clicked: boolean;
  setClicked: (args: boolean) => void;
  PhoneFocus: boolean;
  setPhoneFocus: (args: boolean) => void;
}) => {
  const addr = data.adresse_complete.split(",")[0];
  const ville = data.adresse_complete.split(",")[1].split(" ")[1];

  const format = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  const DisplayApp = ({ type }: { type: string }) => {
    const avg =
      type === "app"
        ? estimationValue.esti_app.avg
        : estimationValue.esti_mai.avg;
    const avgValue = avg ? avg : 0;

    const handleClick = () => {
      setPhoneFocus(true);
      setClicked(true);
      setScreen(1);
      handleBV(type === "app" ? "0" : "1");
    };

    return (
      <div className={"h-full w-full px-2"}>
        <span
          className={
            "h-full border-[#ededed] border-[7px] w-full pt-3 flex flex-col items-center"
          }
        >
          <p className={"flex items-end"}>
            {type === "app" ? (
              <HiOutlineBuildingOffice2 className={"text-3xl"} />
            ) : (
              <FaHouse className={"text-3xl"} />
            )}
            <span className={"font-semibold text-sm"}>
              {type === "mai" ? "Maison" : "Appartement"}
            </span>
          </p>
          <h2 className={"text-[10px]"}>Prix moyen m²</h2>
          <div className="flex flex-row w-full justify-center sm:justify-evenly items-center mb-5">
            <h2 className={"text-clampPhone font-semibold"}>
              {format
                .format(
                  type === "app"
                    ? estimationValue.esti_app.min
                    : estimationValue.esti_mai.min,
                )
                .replaceAll(".", " ")}
            </h2>
            <h1 className="text-[#980000] font-bold text-lg">
              {format.format(avgValue).replaceAll(".", " ")}
            </h1>
            <h2 className={"text-clampPhone font-semibold"}>
              {format
                .format(
                  type === "app"
                    ? estimationValue.esti_app.max
                    : estimationValue.esti_mai.max,
                )
                .replaceAll(".", " ")}
            </h2>
          </div>
          <p className={"w-[95%] h-[1px] bg-[#ededed]"} />
          <span
            className={"flex justify-between px-2 items-center w-full h-[40px]"}
          >
            <DisplayIndice
              indice={
                type === "app"
                  ? estimationValue.esti_app.indice
                  : estimationValue.esti_mai.indice
              }
            />
            <button
              id={"btn-biens-vendus"}
              onTouchStart={handleClick}
              onClick={handleClick}
              className="btn-biens-vendus border border-dark-red text-dark-red px-1 text-clampPhoneLittle rounded-lg"
            >
              Biens vendus
            </button>
          </span>
        </span>
      </div>
    );
  };

  return (
    <div className={"h-full w-full"}>
      <div className={"h-[80%] overflow-y-auto w-auto"}>
        <div
          className={
            "h-fit py-[2vh] w-full text-[15px] flex items-center justify-center"
          }
        >
          <p className={"text-dark-red text-clampPhone"}>
            {addr}, {ville}
          </p>
        </div>
        {estimationValue.esti_mai.avg === -1 &&
        estimationValue.esti_app.avg === -1 ? (
          <DisplayError />
        ) : (
          <div>
            {estimationValue.esti_app.avg !== -1 &&
              estimationValue.esti_app.avg !== 0 && <DisplayApp type={"app"} />}
            {estimationValue.esti_mai.avg !== -1 &&
              estimationValue.esti_mai.avg !== 0 && <DisplayApp type={"mai"} />}
            <PhoneFooter setPhoneFocus={setPhoneFocus} />
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * @name PhoneFooter
 * @description Footer de l'écran d'estimation (bouton d'estimation avancée et de consultation qui ramene au footer de la page)
 * @param setPhoneFocus
 * @constructor
 */
const PhoneFooter = ({
  setPhoneFocus,
}: {
  setPhoneFocus: (args: boolean) => void;
}) => {
  return (
    <div
      className={
        "w-full h-full flex flex-col justify-center items-center mt-5 gap-5"
      }
    >
      <span className={"flex items-center flex-col gap-5"}>
        <p className={"text-clampPhoneLittle text-center"}>
          Estimez en fonction des caractéristiques du bien
        </p>
        <AnchorLink
          onClick={() => setPhoneFocus(false)}
          href={`#${SectionIdEnum.telecharger}`}
          title={
            "Télécharger notre application pour accéder à cette fonctionnalité"
          }
          className={
            "bg-dark-red text-white font-semibold px-2 py-1 text-clampPhoneLittle rounded-lg"
          }
        >
          Estimation avancée
        </AnchorLink>
      </span>
      <span
        className={
          "flex items-center text-clampPhoneLittle flex-col gap-5 px-2"
        }
      >
        <p className={"text-center"}>
          Pour vendre, consultez les professionels du secteur et leurs
          honoraires
        </p>
        <AnchorLink
          onClick={() => setPhoneFocus(false)}
          href={`#${SectionIdEnum.telecharger}`}
          title={
            "Télécharger notre application pour accéder à cette fonctionnalité"
          }
          className={
            "bg-dark-red text-white font-semibold px-2 py-1 rounded-lg"
          }
        >
          Consulter
        </AnchorLink>
      </span>
    </div>
  );
};

/**
 * @name DiplsayNoData
 * @description Affichage lorsqu'aucune donnée n'est disponible
 * @constructor
 */
const DiplsayNoData = () => {
  return (
    <div
      className={
        "text-dark-red text-sm border border-dark-red text-center py-10"
      }
    >
      Aucun résultat ne correspond à votre recherche
    </div>
  );
};

/**
 * @name DisplayError
 * @description Affichage lorsqu'une erreur survient
 * @constructor
 */
const DisplayError = () => {
  return (
    <div className={"h-[30%] w-full px-1"}>
      <span
        className={
          "h-full border-[#ededed] border-[10px] w-full flex flex-col items-center justify-center text-center"
        }
      >
        Nous n'avons pas suffisamment de données pour établir une estimation à
        cette adresse
      </span>
    </div>
  );
};

/**
 * @name DisplayLoading
 * @description Affichage de chargement
 * @constructor
 */
const DisplayLoading = () => {
  return (
    <div className="h-full w-full flex items-center flex-col justify-center border mb-2 p-2 rounded shadow">
      <img
        src={Logo}
        alt={"Loading logo"}
        className={"object-scale-down h-[10vh]"}
      />
      <svg
        width="20"
        height="20"
        fill="currentColor"
        className="mr-2 animate-spin"
        viewBox="0 0 1792 1792"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
      </svg>
      <p>Chargement en cours</p>
    </div>
  );
};

/**
 * @name DisplayInfo
 * @description Affichage des informations des biens vendus
 * @param index
 * @param item
 * @param ville
 * @constructor
 */
const DisplayInfo = ({
  index,
  item,
  ville,
}: {
  item: BienImmo;
  index: number;
  ville: string;
}) => {
  return (
    <div
      key={index}
      className="w-full flex relative bg-white text-[0.55rem] items-center flex-col text-center gap-1 border mb-2 p-1 rounded shadow"
    >
      {(item.type_local === "Maison" &&
        ((item.crete >= 0.35 && item.crete <= 0.5) ||
          (item.crete >= -0.5 && item.crete <= -0.35))) ||
      (item.type_local === "Appartement" &&
        ((item.crete >= 0.35 && item.crete <= 0.5) ||
          (item.crete >= -0.5 && item.crete <= -0.25))) ? (
        <FaTriangleExclamation
          className={"absolute text-dark-red text-xs right-2 bottom-[0.3rem]"}
        />
      ) : (
        <div></div>
      )}
      <h1 className="text-[#980000] ">
        {item.adresse_numero} {item.adresse_nom_voie.toLocaleLowerCase()} -{" "}
        {item.code_postal} {ville}
      </h1>
      <h2>
        {item.type_local} - {item.nombre_pieces_principales}P -{" "}
        {item.surface_carrez_reconstituee === "0.00" ||
        item.surface_carrez_reconstituee === null
          ? item.surface_reelle_bati
          : item.surface_carrez_reconstituee}
        m²{" "}
        {item.surface_carrez_reconstituee === "0.00" ||
        item.surface_carrez_reconstituee === null
          ? "Bati"
          : "Carrez"}
      </h2>
      <h2 className={`${item.nb_dependance || item.locaux ? "" : "hidden"}`}>
        {item.nb_dependance ? `${item.nb_dependance} Dépendances` : ""}
        {item.locaux ? item.locaux : ""}
      </h2>
      <h2 className={"text-center font-bold"}>
        {price_format(parseInt(item.valeur_fonciere))} -{" "}
        {item.date_mutation.split("T")[0]} -{" "}
        {price_format(item.prix_m2)}
      </h2>
    </div>
  );
};
