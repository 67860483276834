import { DefaultPageTemplate } from "../../utils/utils.components";
import React from "react";
import {
  HowToBuyHeader,
  OriginSection,
  PreparationSection,
} from "../../components/SubComponent/HowToBuy.component";

/**
 * @name BuyPage
 * @description Page explicative Comment acheter
 * @constructor
 */
const BuyPage = () => {
  return (
    <DefaultPageTemplate>
      <HowToBuyHeader />
      <main className={"flex flex-col items-center gap-10 justify-center"}>
        <OriginSection />
        <PreparationSection />
      </main>
    </DefaultPageTemplate>
  );
};

export default BuyPage;
