import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { PropertyData } from "../interfaces/Annonce.interface";
import { AnnonceAPI } from "../API/Consultation.API";

/**
 * @name useAnnonce
 * @description Custom hook pour l'annonce
 */
export function useAnnonce() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const property_id = params.get("property_id") || "null";
  const partner_id = params.get("partneragent_id");
  const [caractModal, setCaractModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [annonceData, setAnnonceData] = useState<PropertyData>();

  const handleGetInfo = useCallback(async () => {
    const url = `https://${process.env.REACT_APP_API_URL}/api/registration/propertyC?property_id=${property_id}&partneragent_id=${partner_id}`;
    const data = await AnnonceAPI(url);
    setAnnonceData(data);
  }, [property_id, partner_id]);

  useEffect(() => {
    handleGetInfo();
  }, [handleGetInfo]);

  return {
    caractModal,
    setCaractModal,
    imageModal,
    setImageModal,
    annonceData,
    property_id,
  };
}
