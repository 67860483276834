import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footers from "../utils/Footers";
import { FlatWayIntroComponent } from "../components/FlatWay/FlatWay.Intro.Component";
import { FlatWaySellComponent } from "../components/FlatWay/FlatWay.Sell.Component";
import { FlatWayVisiteComponent } from "../components/FlatWay/FlatWay.Visite.Component";
import FlatWayBuyComponent from "../components/FlatWay/FlatWay.Buy.Component";
import qrCode from "../assets/qr-code.png";
import FlatwayEstimation from "../components/FlatWay/FlatwayEstimation.Component";
import Logo from "../assets/appIcon.png";
import "../utils/Scrolldown.css";

/**
 * @name FlatWay
 * @description Page Flatway
 * @constructor
 */
function FlatWay() {
  const navigate = useNavigate();

  /**
   * @description Differents composants de la page Flatway
   */
  const components = [
    <FlatWayBuyComponent />,
    <FlatWaySellComponent />,
    <FlatWayVisiteComponent />,
    <FlatwayEstimation />,
    // <FlatWayPartnerComponent />,
  ];

  return (
    <div className={"sm:h-auto w-full overflow-hidden"}>
      <nav
        className={`
        fixed h-[70px] w-full z-10000 backdrop-blur-[2px] bg-white shadow xl:px-2`}
        style={{ zIndex: 10000 }}
      >
        <div className="h-full w-full flex justify-between items-center px-[30px]">
          <img
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/");
            }}
            src={Logo}
            alt="logo"
            className="h-[50px] cursor-pointer drop-shadow-logo"
          />
          <button
            onClick={() => navigate("/flatwaypro")}
            className={
              "px-2 py-2 bg-dark-red text-sm rounded-lg text-white font-medium"
            }
          >
            Vous êtes professionnel ?
          </button>
        </div>
      </nav>
      <div className={"h-[70px]"} />
      <div className={"w-full flex flex-col gap-[10vh]"}>
        <FlatWayIntroComponent />
        <div className={"relative h-full w-full flex flex-col gap-[10vh]"}>
          {components.map((items, index) => (
            <div
              key={index}
              className={
                "h-auto w-full flex sm:items-center flex-col font-medium"
              }
            >
              {components[index]}
            </div>
          ))}
        </div>
      </div>
      <div className={"h-[10%] py-5 w-full flex justify-center items-center"}>
        <div className={"h-full flex flex-col items-center"}>
          <div
            className={
              "w-fit h-auto hover:text-dark-red bg-slate-50 select-none rounded-lg drop-shadow p-5 gap-3 flex items-center justify-center"
            }
          >
            <div
              className={"h-[100px] w-[100px] flex justify-center items-center"}
            >
              <img
                src={qrCode}
                alt={"qrcode"}
                className={"object-cover h-full w-full"}
              />
            </div>
            <div className={""}>
              <p
                className={
                  "font-bold text-xs sm:text-sm transition-all duration-300"
                }
              >
              <span className={"text-dark-red"}>
                Bientot disponible
              </span>
              <br />

                Vous avez un projet immobilier ?
                <br />
                <br />
                Scannez pour télécharger Flatway
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footers />
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default FlatWay;
