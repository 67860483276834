import React, { useEffect, useState } from "react";
import ReactMapGL, { Source, Layer } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import axios from "axios"; // Import Axios for making API requests

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3RldmVuZmxhdDUiLCJhIjoiY2xwMTVobmphMGlnaTJrczQ2OWlsMGVpbCJ9.w2JtUyw5T5fKEjLy0Sdh0g";

/**
 * @name DiscoverMap
 * @description Composant de la carte sur la page d'annonce
 * @param longitude
 * @param latitude
 * @param city
 * @constructor
 */
export const DiscoverMap = ({
  longitude,
  latitude,
  city,
}: {
  city: string;
  longitude: number;
  latitude: number;
}) => {
  const [viewport, setViewport] = useState({
    latitude: latitude != null ? latitude : 0,
    longitude: longitude != null ? longitude : 0,
    zoom: 12,
  });

  const [isochroneData, setIsochroneData] = useState(null);

  useEffect(() => {
    const fetchIsochroneData = async () => {
      try {
        const response = await axios.get(
          `https://api.mapbox.com/isochrone/v1/mapbox/walking/${longitude},${latitude}?contours_minutes=10&polygons=true&access_token=${mapboxgl.accessToken}`,
        );
        setIsochroneData(response.data);
      } catch (error) {
        console.error("Error fetching isochrone data:", error);
      }
    };

    fetchIsochroneData();
  }, [longitude, latitude]);

  useEffect(() => {
    // Update viewport zoom based on screen width
    const handleResize = () => {
      const newZoom = window.innerWidth < 600 ? 10 : 12; // Adjust the threshold and zoom level as needed
      setViewport((prevViewport) => ({ ...prevViewport, zoom: newZoom }));
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Call the function on mount
    handleResize();

    // Remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={"w-full h-[35vh]"}>
      <h1 className={"text-base lg:text-2xl font-bold mb-5"}>
        Découvrir le quartier
      </h1>
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={mapboxgl.accessToken}
        style={{ width: "100%", height: "100%" }}
        mapStyle={"mapbox://styles/stevenflatmap/cls90mytj011w01qs2pvz3pgs"}
        onMove={(evt) => setViewport(evt.viewState)}
      >
        {isochroneData && (
          <Source type="geojson" data={isochroneData}>
            <Layer
              id="isochrone-layer"
              type="fill"
              paint={{
                "fill-color": "rgba(152, 0, 0, 0.3)",
                "fill-outline-color": "rgba(152, 0, 0, 1)",
              }}
            />
          </Source>
        )}
      </ReactMapGL>
      <h2 className={"font-semibold"}>{city}</h2>
    </div>
  );
};
