import { DefaultPageTemplate } from "../utils/utils.components";
import React, { useState } from "react";
import { ScrollTemplate } from "../components/FlatWay/FlatWay.Other.Component";
import { headerCardList } from "../interfaces/FAQ.interface";
import { EstimationFirstSection, FAQHeader } from "../components/FAQ.component";

/**
 * @name FAQPage
 * @description Page FAQ (si modif a faire aller dans l'interface dedier)
 * @constructor
 */
const FAQPage = () => {
  const [selectSection, setSelectSection] = useState(0);
  const [isOpen, setIsOpen] = useState(
    Array(headerCardList.length).fill(false),
  );

  return (
    <DefaultPageTemplate>
      <FAQHeader
        selectSection={selectSection}
        setSelectSection={setSelectSection}
        setIsOpen={setIsOpen}
      />
      <main id={"response"} className="px-2 md:px-[20%] py-20">
        {selectSection === 0 ? (
          <section
            className={"flex flex-col w-full items-center gap-5 justify-center"}
          >
            <h1 className={"font-bold text-dark-red text-lg"}>Estimation</h1>
            <span className={"flex flex-col gap-5 w-full"}>
              <h2 className={"font-bold text-dark-red"}>
                À partir d’une adresse renseignée
              </h2>
              {headerCardList[selectSection].content.map((item, index) => {
                if (index > headerCardList[selectSection].content.length - 3)
                  return null;

                return (
                  <ScrollTemplate
                    title={item.question}
                    desc={item.response}
                    isOpen={isOpen[index]}
                    setIsOpen={(value) => {
                      const updatedIsOpen = [...isOpen];
                      updatedIsOpen[index] = value;
                      setIsOpen(updatedIsOpen);
                    }}
                  />
                );
              })}
              <h2 className={"font-bold text-dark-red"}>
                Estimation des biens en vente
              </h2>
              {headerCardList[selectSection].content.map((item, index) => {
                if (index < headerCardList[selectSection].content.length - 2)
                  return null;

                return (
                  <ScrollTemplate
                    title={item.question}
                    desc={item.response}
                    isOpen={isOpen[index]}
                    setIsOpen={(value) => {
                      const updatedIsOpen = [...isOpen];
                      updatedIsOpen[index] = value;
                      setIsOpen(updatedIsOpen);
                    }}
                  />
                );
              })}
            </span>
          </section>
        ) : (
          <EstimationFirstSection
            title={headerCardList[selectSection].title}
            content={headerCardList[selectSection].content}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </main>
    </DefaultPageTemplate>
  );
};

export default FAQPage;
