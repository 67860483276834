import Geometry2 from "../../assets/geometry/blob2.svg";
import Visiter from "../../assets/visiter 1.jpg";
import React from "react";

/**
 * @name VisitAndOfferHeader
 * @description Header de la page de visite et d'offre
 * @constructor
 */
export const VisitAndOfferHeader = () => {
  return (
    <header
      className={
        "bg-gradient-to-tr overflow-hidden relative via-dark-red to-dark-red from-[#d73e3e] py-10 h-auto flex flex-col items-center w-full"
      }
    >
      <img
        src={Geometry2}
        alt={"Blob"}
        className={
          "w-[400px] select-none absolute z-0 bottom-24 left-[45%] drop-shadow-lg"
        }
      />
      <div
        className={
          "h-auto xl:px-0 px-4 xl:grid xl:grid-cols-flatwaySection z-10 text-white xl:w-[1200px] items-center font-bold gap-10 justify-center"
        }
      >
        <span
          className={"col-span-6 w-full flex h-full justify-between flex-col"}
        >
          <div></div>
          <h1 className={"text-2xl md:text-4xl"}>
            Depuis votre mobile organisez vos visites et formulez vos offres,
            <br />
            Flatway vous accompagne{" "}
          </h1>
          <span className={"font-medium flex flex-col gap-2 mt-2"}>
            <label className={"font-bold"}>La visite des bien</label>
            <ul className={"list-disc ml-6 text-sm"}>
              <li>
                Si le vendeur organise les visites, Flatway accompagne
                l'acheteur et le vendeur ; le professionnel est tenu informé
              </li>
              <li>
                Si le professionnel organise les visites, Flatway accompagne
                l'acheteur et le professionnel ; le vendeur est tenu informé
              </li>
            </ul>
            <label className={"font-bold"}>L’offre d’achat</label>
            <p className={"text-sm"}>
              A partir de leurs espaces dédiés, nos utilisateurs émettent leurs
              propositions d'achat, dans un processus fluide, transparent et
              moderne.
            </p>
          </span>
        </span>
        <aside
          className={"col-span-6 xl:mt-0 mt-10 relative h-[300px] sm:h-[500px]"}
        >
          <img
            alt={"Visiter"}
            src={Visiter}
            className={
              "w-full h-full object-cover absolute z-20 rounded-customBlobImage"
            }
          />
          <div
            className={
              "rounded-customBlobTriangle3 top-0 z-10 h-[200px] absolute w-[200px] bg-light-red"
            }
          />
        </aside>
      </div>
    </header>
  );
};

/**
 * @name VisitStep
 * @description Les étapes de la visite
 * @constructor
 */
export const VisitStep = () => {
  const stepList: string[] = [
    "La veille de la visite, des notifications de rappels sont envoyées aux parties",
    "Jusqu’à 2h avant le rendez-vous les parties peuvent déplacer RDV ou annuler RDV",
    "2h avant le rendez-vous l’option Déplacer RDV est remplacée par Signaler un Retard ; le temps de retard pris en compte est de 5 à 20 min",
    "1h15 avant le rendez-vous l'acheteur est notifié pour confirmer sa présence. Sans confirmation dans les 30 min, le rendez-vous est annulé et les parties sont notifiées",
    "45 min avant le rendez-vous Flatway communique à l’acheteur un secteur¹ proche de l’adresse et demande au vendeur, ou au professionnel, de choisir un point de rendez-vous dans ce secteur",
    "Le point de rendez-vous est communiqué ensuite à l’acheteur après vérification de sa géolocalisation. Le vendeur ou le professionnel scanne le QR code fourni par Flatway à l’acheteur avant de l’accompagner pour la visite",
  ];

  return (
    <div className={"lg:px-0 px-2 z-10"}>
      <h1 className={"text-xl md:text-3xl font-bold mb-5"}>
        Le déroulé de la visite
      </h1>
      <ul
        className={
          "grid-cols-2 md:grid-cols-3 xl:grid-cols-4 xl:w-[1200px] font-medium grid"
        }
      >
        {stepList.map((item, index) => (
          <li
            className={`rounded-xl flex flex-col items-center py-3 w-full h-full`}
          >
            <span
              className={"relative flex w-full justify-center items-center"}
            >
              <div
                className={`font-medium bg-dark-red text-lg md:text-3xl text-white w-10 h-10 rounded-full flex justify-center items-center`}
              >
                {index + 1}
              </div>
              <div
                className={`${
                  index === 0
                    ? "w-[50%] h-1 bg-light-red right-0"
                    : "w-full h-1 bg-light-red"
                } absolute rounded-xl -z-10`}
              />
              <div
                className={`hidden xl:${
                  index + 1 === 4 || index + 1 === 5
                    ? `block ${index + 1 === 4 ? "-right-3" : "-left-3"}`
                    : "hidden"
                } absolute  text-3xl mb-5 bg-slate-50 text-light-red`}
              >
                . . .
              </div>
              <div
                className={`${
                  index + 1 === stepList.length ? "-right-3" : "hidden"
                } absolute  text-3xl mb-5 bg-slate-50 text-light-red`}
              >
                . . .
              </div>
            </span>
            <p className={"px-2 md:text-base text-sm"}>{item}</p>
          </li>
        ))}
      </ul>
      <p className={"text-[0.6rem]"}>
        <span className={"text-dark-red"}>¹</span> Ex. [17, 29] si le bien est
        situé au N°19 de la voie
      </p>
    </div>
  );
};
