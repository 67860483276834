import { DefaultPageTemplate } from "../../utils/utils.components";
import { VisiteCardSection } from "../../components/FlatWay/FlatWay.Visite.Component";
import React from "react";
import FlatWayOfferComponent from "../../components/FlatWay/FlatWayOffer.Component";
import {
  VisitAndOfferHeader,
  VisitStep,
} from "../../components/SubComponent/VisitAndOffer.component";

/**
 * @name VisitAndOfferPage
 * @description Page explicative Visite et offre
 * @constructor
 */
const VisitAndOfferPage = () => {
  return (
    <DefaultPageTemplate>
      <VisitAndOfferHeader />
      <main className={"flex flex-col items-center gap-10 justify-center"}>
        <section
          className={"w-full flex flex-col py-10 justify-center items-center"}
        >
          <VisiteCardSection />
        </section>
        <section
          className={
            "w-full flex flex-col bg-slate-50 flex-wrap py-10 justify-center items-center"
          }
        >
          <VisitStep />
        </section>
        <section
          className={"w-full flex flex-col py-10 justify-center items-center"}
        >
          <FlatWayOfferComponent />
        </section>
      </main>
    </DefaultPageTemplate>
  );
};

export default VisitAndOfferPage;
