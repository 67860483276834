import React, { lazy, Suspense, useState } from "react";
import { sellType } from "../../interfaces/interface";
import "./checkbox.css";
import HeaderCard from "./SellList-component/HeaderCard";
const LazySLCard = lazy(() => import("./SellList-component/SellListCard"));

/**
 * @name SellList
 * @description Liste des biens à vendre (Lazyloading de SellListCard, gestion en react du chargement d'un composant)
 * @param data
 * @param cityName
 * @param filter
 * @param setFilter
 * @param setHover
 * @param listOpen
 * @constructor
 */
function SellList({
  data,
  cityName,
  filter,
  setFilter,
  setHover,
  listOpen,
}: {
  cityName: string[];
  data: sellType[];
  filter: { index: number; value: string };
  setFilter: React.Dispatch<
    React.SetStateAction<{
      index: number;
      value: string;
    }>
  >;
  setHover: React.Dispatch<React.SetStateAction<number>>;
  listOpen: boolean;
}) {
  const [acc, setAcc] = useState({ with: true, without: true });

  return (
    <div
      className={`h-[81.5vh] ${
        listOpen
          ? "w-full sm:w-[120%]  flex flex-col items-center px-5 py-2"
          : "w-0 hidden"
      } transition-all duration-300 bg-white`}
    >
      <div className={`${listOpen ? "block" : "hidden"} w-full h-full`}>
        <HeaderCard
          filter={filter}
          setFilter={setFilter}
          cityName={cityName}
          data={data}
          acc={acc}
          setAcc={setAcc}
        />
        <Suspense
          fallback={
            <div className="h-[50%] sm:h-[30%] w-full border rounded my-5 sm:p-2 flex flex-col sm:flex-row sm:items-center">
              <div className="h-full w-full animate-pulse flex space-x-4">
                <div className="bg-slate-200 h-full w-[45%]"></div>
                <div className={"flex h-full w-[50%] flex-col space-y-10"}>
                  <div className={"bg-slate-200 h-10 rounded w-[30%]"}></div>
                  <div className={"h-full w-full flex gap-2 flex-col"}>
                    <div className={"bg-slate-200 h-2 rounded w-[50%]"}></div>
                    <div className={"bg-slate-200 h-2 rounded w-[60%]"}></div>
                  </div>
                  <div className={"h-full w-full flex gap-2 flex-col"}>
                    <div className={"bg-slate-200 h-2 rounded w-[40%]"}></div>
                    <div className={"bg-slate-200 h-2 rounded w-[30%]"}></div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <LazySLCard
            data={data}
            acc={acc}
            filter={filter}
            setHover={setHover}
          />
        </Suspense>
      </div>
    </div>
  );
}

export default SellList;
